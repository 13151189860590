// Core
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

//Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Badge from "components/Badge/Badge.js";
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import GSearchActions from "components/GSearchActions";

import { Label } from "semantic-ui-react";

// Icons

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

// Libs
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";
import moment from "moment";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = (theme) => ({
  ...modalStyle(theme),
  ...style,
  ...tooltipsStyle,
  buttons: {
    marginTop: "25px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class TicketHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //user : secureStorage.getItem('user'),

      // paging states >>
      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      keyword: "",

      modalMode: "",
      modalShow: false,
      modalTitle: "",

      modalName: "",
      modalAmount: "",

      ticket: "",
    };
  }
  componentDidMount() {
    this.loadTicket();
  }

  loadTicket = (params) => {
    axios
      .post(Config.ApiUrl + "/ticket/history", {
        startDate:
          params && params.startDate ? params.startDate : this.state.startDate,
        endDate: params && params.endDate ? params.endDate : this.state.endDate,

        currentPage: params && params.currentPage ? params.currentPage : 1,
        rowsPerPage:
          params && params.rowsPerPage
            ? params.rowsPerPage
            : this.state.rowsPerPage,
      })
      .then((res) => {
        //console.log(params &&  params.partnerDropdown?'true':'false')
        console.log(res.data);
        this.setState({
          // rows : _.sortBy(res.data.tickets,'createdAt').reverse(),
          rows: res.data.tickets,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadCoA() {
    axios
      .post(Config.ApiUrl + "/bank/importCoA")
      .then((res) => {
        this.setState({ CoA: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAdd = (e) => {
    this.loadCoA();
    this.setState({
      modalShow: true,
      modalTitle: "Add Bank",
      modalMode: "add",
      modalBankName: "",
      modalAccountName: "",
      modalAccountNumber: "",
    });
  };

  handleSubmit = () => {
    this.loadTicket();
  };

  handleDropDownChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClear = (e) => {
    this.setState({
      keyword: "",
    });
    this.loadTicket({ keyword: "" });
  };

  handleCheckBox = (bankId, show) => {
    axios
      .post(Config.ApiUrl + "/bank/show", {
        bankId: bankId,
        show: show,
      })
      .then((res) => {
        //console.log(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleShowModal = () => {
    this.setState({ modalShow: true });
  };
  handleModal = (target) => {
    if (target !== "back") {
      if (this.state.modalAmount && this.state.ticket) {
        const payload = {
          amount: this.state.modalAmount,
          ticket: this.state.ticket,
        };
        const url =
          target === "confirm"
            ? "/ticket/confirm"
            : target === "pending"
            ? "/ticket/pending"
            : "/ticket/cancel";

        axios
          .post(Config.ApiUrl + url, payload)
          .then((res) => {
            this.loadTicket();
            this.setState({ modalShow: false, ticket: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else this.setState({ modalShow: false, selectedCoA: "" });
  };

  getColor(statusId) {
    switch (statusId) {
      case 1:
        return "primary";
      case 2:
        return "warning";
      case 3:
        return "success";
      case 4:
      case 5:
        return "instagram";
      case 6:
        return "danger";
      default:
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="big">Ticket History</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GridContainer justify="center">
                      <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                        <GSearchActions
                          onChange={({ startDate, endDate }) =>
                            this.setState({ startDate, endDate })
                          }
                          onSubmit={this.loadTicket}
                        />
                      </GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}></GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
                        {/* <Input
													labelText="Search"
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder : 'Bank Name',
														value : this.state.keyword,
														onChange : (e)=>{this.setState({keyword:e.target.value})},
														onKeyPress : (e)=>{if(e.key === 'Enter'){this.setState({keyword:e.target.value});this.loadTicket({keyword:e.target.value})}}
													}}
												/> */}
                      </GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
                        {/* <span>
												<div className={classNames(classes.buttonGroup,classes.buttons)}>
													<Tooltip id="tooltip-search" title="Search" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="info" size="sm" round onClick={()=>this.loadTicket()} className={classes.firstButton} ><SearchIcon /></Button>
													</Tooltip>
													<Tooltip id="tooltip-clear" title="Clear" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="info" size="sm" round onClick={()=>this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
													</Tooltip>
												</div>
												</span> */}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Table
                  tableHead={[
                    "#",
                    "TID",
                    "UID",
                    "Name",
                    "Date",
                    "Bank",
                    "Amount",
                    "Status",
                    "PIC",
                  ]}
                  tableData={
                    this.state.rows && this.state.rows.length > 0
                      ? this.state.rows.map((ticket, index) => [
                          index +
                            1 +
                            (isNaN(this.state.rowsPerPage)
                              ? 0
                              : (this.state.currentPage - 1) *
                                this.state.rowsPerPage),
                          ticket.id,
                          ticket.User.id,
                          ticket.User.name,
                          moment(ticket.createdAt).format(
                            "dddd, DD/MM/YYYY HH:mm"
                          ),
                          ticket.Bank.name,
                          fnumber(ticket.finalAmount),
                          <Badge color={this.getColor(ticket.statusId)}>
                            {ticket.TicketStatus.name}
                          </Badge>,
                          ticket.LoginUser ? ticket.LoginUser.name : "-",
                        ])
                      : []
                  }
                  textAlignCells={["right", "center"]}
                  textAlignForCells={[6, 7]}
                  textAlignHeaders={["right", "center"]}
                  textAlignForHeaders={[6, 7]}
                  customCellClasses={[classes.textRight, classes.textCenter]}
                  customClassesForCells={[6, 7]}
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                  ]}
                  customHeadClassesForCells={[6, 7]}
                />
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTicket({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTicket({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalMode === "pending" ? (
                  <GridContainer justify="center">
                    <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                      Are you sure want to {this.state.modalTitle} ?
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer justify="center">
                    <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Input
                        labelText="Name"
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Name",
                          value: this.state.modalName,
                          readOnly: true,
                        }}
                      />
                      <Input
                        labelText="Top Up Amount"
                        id="amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Amount",
                          value: fnumber(this.state.modalAmount),
                          onChange: (e) =>
                            this.setState({
                              modalAmount: e.target.value.replace(/\./g, ""),
                            }),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.handleModal("back")}
                  color="warning"
                >
                  Back
                </Button>
                {this.state.modalMode === "confirm" ? (
                  <Button
                    onClick={() => this.handleModal("confirm")}
                    color="success"
                  >
                    Top Up
                  </Button>
                ) : null}
                {this.state.modalMode === "cancel" ? (
                  <Button
                    onClick={() => this.handleModal("cancel")}
                    color="danger"
                  >
                    Cancel
                  </Button>
                ) : null}
                {this.state.modalMode === "pending" ? (
                  <Button
                    onClick={() => this.handleModal("pending")}
                    color="danger"
                  >
                    Delete
                  </Button>
                ) : null}
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
TicketHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TicketHistory);
