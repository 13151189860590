/*eslint-disable*/
import React from "react";
// @material-ui/core components

import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/PhoneAndroid";
import Address from "@material-ui/icons/ContactMail";
import ProvinceIcon from "@material-ui/icons/Map";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Input from "components/CustomInput/CustomInput.js";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
/**
 * Alert Snackbar
 */
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import { Redirect } from "react-router-dom";
import axios from "axios";
import Config from "config";
import _ from "lodash";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { Dropdown, Dimmer, Loader } from "semantic-ui-react";
import { secureStorage } from "libs";
import Provinces from "libs/Provinces";
import * as EmailValidator from "email-validator";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

import { DirectUserType, EndUsers } from "content/User/libs";
const SalesType = [
  { id: 0, label: "DM 2 Levels" },
  { id: 1, label: "TL 3 Levels" },
  { id: 4, label: "DP 3 Levels" },
  { id: 6, label: "FD 5 Levels" },
  { id: 7, label: "FT 5 Levels" },
  { id: 9, label: "GT 4 Levels" },
];

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoading: false,

      username: "",
      fullname: "",
      email: "",
      phone: "",
      address: "",
      province: "",
      password: "",
      repassword: "",
      userType: "",
      error: [],
      success: [],

      usernameTimeout: "",
      emailTimeout: "",
      phoneTimeout: "",

      usernameErr: "",
      emailErr: "",
      phoneErr: "",

      alert: false,
      alertMessage: "All ( * ) field must be filled",

      salesType: -1,
      userTypes: [],

      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const salesType = this.initSalesType();
    this.initUserTypes({ salesType });
  }

  initSalesType() {
    const user = secureStorage.getItem("user");
    let salesType = 0;
    if ([1, 6].includes(user.userTypeId)) salesType = 0;
    else if ([2, 3].includes(user.userTypeId)) salesType = 1;
    else if ([5].includes(user.userTypeId)) salesType = 2;
    else if ([8].includes(user.userTypeId)) salesType = 2;
    else if ([9, 10].includes(user.userTypeId)) salesType = 4;
    else if ([12].includes(user.userTypeId)) salesType = 5;
    else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6;
    else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7;
    else if ([24, 25].includes(user.userTypeId)) salesType = 8;
    else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9;
    this.setState({ salesType });

    console.log({ salesType, user });
    return salesType;
  }

  initUserTypes({ salesType }) {
    const user = secureStorage.getItem("user");
    axios
      .post(Config.ApiUrl + "/transaction/usertypes")
      .then((res) => {
        const userTypes = res.data;
        const payloadDropdown = {
          salesType,
          parents: [user],
          userTypes,
          parentTypeId: user.userTypeId,
          init: true,
        };
        console.log({ payloadDropdown });
        this.setState({ userTypes });
        this.loadUserDropdown(payloadDropdown);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleAlertExit = () => {
    this.setState({ alert: false });
  };

  handleSelect = (event) => {
    this.setState({ province: event.target.value });
  };

  handleInput = (e) => {
    switch (e.target.id) {
      case "username":
        const username = e.target.value;
        this.state.usernameTimeout && clearTimeout(this.state.usernameTimeout);
        this.state.usernameTimeout = setTimeout(() => {
          username && this.checkAvailability({ username });
        }, 2000);
        this.setState({
          username,
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
        });
        break;
      case "fullname":
        this.setState({ fullname: e.target.value });
        break;
      case "phone":
        const phone = e.target.value;
        this.state.phoneTimeout && clearTimeout(this.state.phoneTimeout);
        this.state.phoneTimeout = setTimeout(() => {
          phone && this.checkAvailability({ phone });
        }, 2000);
        this.setState({
          phone: e.target.value,
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
        });
        break;
      case "email":
        const email = e.target.value;
        this.state.emailTimeout && clearTimeout(this.state.emailTimeout);
        this.state.emailTimeout = setTimeout(() => {
          email && this.checkAvailability({ email });
        }, 2000);
        this.setState({
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
          email: e.target.value,
        });
        break;
      case "address":
        this.setState({ address: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "repassword":
        this.setState({ repassword: e.target.value });
        if (e.target.value === this.state.password) {
          this.setState({
            success: { ...this.state.success, [e.target.id]: true },
            error: { ...this.state.error, [e.target.id]: false },
          });
        } else if (e.target.value !== this.state.password)
          this.setState({
            error: { ...this.state.error, [e.target.id]: true },
          });
        else
          this.setState({
            success: { ...this.state.success, [e.target.id]: false },
          });
        break;
      default:
    }
  };

  handleBlur = (e) => {
    const { id, value } = e.target;

    if (value) {
      if (
        (id === "username" &&
          (value.indexOf(" ") !== -1 || this.state.usernameErr)) ||
        (id === "email" &&
          (!EmailValidator.validate(e.target.value) || this.state.emailErr)) ||
        (id === "phone" && (isNaN(e.target.value) || this.state.phoneErr))
      ) {
        this.setState({
          error: { ...this.state.error, [e.target.id]: true },
          success: { ...this.state.success, [e.target.id]: false },
        });
      } else {
        this.setState({
          success: { ...this.state.success, [e.target.id]: true },
          error: { ...this.state.error, [e.target.id]: false },
        });
      }
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  checkAvailability = (params) => {
    const payload = {
      username: params.username && params.username,
      email: params.email && params.email,
      phone: params.phone && params.phone,
    };
    axios
      .post(Config.ApiUrl + "/user/availability", payload)
      .then((res) => {
        const data = res.data;
        const target = Object.keys(data)[0];
        this.setState({
          [target + "Err"]: "",
          success: { ...this.state.success, [target]: true },
          error: { ...this.state.error, [target]: false },
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response.data;
          const target = Object.keys(data)[0];
          this.setState({
            [target + "Err"]: data[target],
            success: { ...this.state.success, [target]: false },
            error: { ...this.state.error, [target]: true },
          });
        }
      });
  };

  handleSubmit = () => {
    let error = [];
    const user = secureStorage.getItem("user");
    this.setState({ alert: false, alertMessage: "" });

    if (!this.state.fullname) error.fullname = true;
    if (!this.state.username) error.username = true;
    if (!this.state.email) error.email = true;

    const userTypes = this.state.userTypes;
    const users = _.flattenDeep(
      userTypes
        .filter((item) => item.id !== 1 && this.state[item.id])
        .map((item) => this.state[item.id])
    );
    // const parents = users.length>0?users:user.id!==1?[user]:[]
    const parents = users.length > 0 ? users : [user];
    this.setState({ error: { ...error } });

    if (
      this.state.fullname === "" ||
      this.state.email === "" ||
      this.state.username === ""
    ) {
      this.setState({
        alert: true,
        alertMessage: "All ( * ) field must be filled",
      });
    }
    // else if (parents.length===0) {
    // 	this.setState({alert:true, alertMessage:'Field group tidak boleh kosong'})
    // }
    else {
      this.create(parents);
    }
  };

  create = (parents) => {
    const userTypes = this.state.userTypes;
    const payload = {
      fullname: this.state.fullname,
      username: this.state.username,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      userTypeId: DirectUserType[this.state.salesType],
      password: this.state.password,
      province: Provinces[this.state.province],
      salesType: this.state.salesType,
      parents,
    };

    console.log({ payload });
    this.setState({ isLoading: true });
    axios
      .post(Config.ApiUrl + "/user/create", payload)
      .then((res) => {
        console.log(res.data);
        this.clearForm();
        this.setState({
          isLoading: false,
          showModal: true,
          modalTitle: "Berhasil",
          modalMessage: res.data,
        });

        userTypes.forEach((item) => this.setState({ [item.id]: undefined }));
      })
      .catch((err) => {
        console.log("ERR : ", err);
        // this.clearForm()
        if (err.response) {
          this.setState({
            isLoading: false,
            showModal: true,
            modalTitle: "Failed",
            modalMessage: err.response.data,
          });
        } else {
          this.setState({
            isLoading: false,
            showModal: true,
            modalTitle: "Failed",
            modalMessage: "Network failed",
          });
        }
      });
  };

  clearForm = () => {
    this.setState({
      fullname: "",
      username: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      repassword: "",
      province: "",
    });
  };

  clearUserDropDown = () => {
    this.state.userTypes.map((userType) => {
      this.state[userType.id] && this.setState({ [userType.id]: undefined });
      return userType;
    });
  };

  clearRecursive = (userTypeId) => {
    this.setState({
      [this.state.userTypes[userTypeId].name]: undefined,
      [this.state.userTypes[userTypeId].id]: undefined,
    });

    if (this.state[this.state.userTypes[userTypeId + 1].name]) {
      this.clearRecursive(userTypeId + 1);
    }
  };

  getUserDropDown = (userTypeId) => {
    const user = secureStorage.getItem("user");
    if (
      this.state.userTypes.length > 0 &&
      this.state[this.state.userTypes[userTypeId - 1].name]
    ) {
      const options = this.state[this.state.userTypes[userTypeId - 1].name].map(
        (item, index) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        })
      );
      return (
        <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
          <Dropdown
            style={{ marginTop: 25 }}
            placeholder={this.state.userTypes[userTypeId - 1].name}
            fluid
            search
            selection
            onChange={(e, { value }) => {
              if (value === "" && this.state.userTypes[userTypeId]) {
                this.clearRecursive(userTypeId);
              }
              if (EndUsers.indexOf(userTypeId) === -1 && value !== "")
                this.loadUserDropdown({
                  parents: this.state[
                    this.state.userTypes[userTypeId - 1].name
                  ].filter((item) => value === item.id),
                  parentTypeId: userTypeId,
                });

              const val = this.state[this.state.userTypes[userTypeId - 1].name]
                .filter((item) => value === item.id)
                .map((item) => item);
              this.setState({
                [this.state.userTypes[userTypeId - 1].id]: val,
              });
            }}
            options={[
              {
                key: 0,
                value: "",
                text: `Pilih ${this.state.userTypes[userTypeId - 1].name}`,
              },
              ...options,
            ]}
          />
        </GridItem>
      );
    } else if (
      this.state.userTypes.length > 0 &&
      user.userTypeId !== userTypeId &&
      user.id !== 1
    ) {
      return (
        <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
          <Dropdown
            style={{ marginTop: 25 }}
            placeholder={this.state.userTypes[userTypeId - 1].name}
            fluid
            search
            selection
            onChange={(e, { value }) => {
              if (value === "" && this.state.userTypes[userTypeId])
                this.setState({
                  [this.state.userTypes[userTypeId].name]: undefined,
                  [this.state.userTypes[userTypeId].id]: undefined,
                });
            }}
            options={[
              {
                key: 0,
                value: "",
                text: `Pilih ${this.state.userTypes[userTypeId - 1].name}`,
              },
            ]}
          />
        </GridItem>
      );
    }
  };

  loadUserDropdown(params) {
    const user = secureStorage.getItem("user");
    const userTypes = params.userTypes
      ? params.userTypes
      : this.state.userTypes;
    const salesType = params.salesType
      ? params.salesType
      : this.state.salesType;
    const payload = {
      salesType,
      parents: params.parents && params.parents,
    };
    axios
      .post(Config.ApiUrl + "/transaction/user", payload)
      .then((res) => {
        let userTypeIdx = params.parentTypeId;
        if (params.init && user.userTypeId === 1)
          userTypeIdx = DirectUserType[salesType] - 1;
        this.setState({ [userTypes[userTypeIdx].name]: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { classes } = this.props;
    const { ...rest } = this.props;
    const user = secureStorage.getItem("user");

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xl={2} lg={2} md={2} sm={12} xs={12}>
                <h4 className={classes.title}>Tambah Akun</h4>
              </GridItem>
              <GridItem xs={12} sm={10} md={10}>
                <Dimmer active={this.state.isLoading} inverted>
                  <Loader />
                </Dimmer>
                <Card className={classes.cardSignup}>
                  <CardBody>
                    <GridContainer justifyContent="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <form className={classes.form}>
                          <GridContainer justifyContent="flex-start">
                            {user.userTypeId === 1 && (
                              <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="salesType-select"
                                    className={classes.selectLabel}
                                  >
                                    Type User
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{ select: classes.select }}
                                    value={this.state.salesType}
                                    onChange={(e) => {
                                      this.setState({
                                        salesType: e.target.value,
                                      });
                                      this.clearUserDropDown();
                                      this.loadUserDropdown({
                                        salesType: e.target.value,
                                        parents: [user],
                                        parentTypeId: user.userTypeId,
                                        init: true,
                                      });
                                    }}
                                    inputProps={{
                                      name: "salesType",
                                      id: "salesType",
                                    }}
                                  >
                                    <MenuItem
                                      disabled
                                      classes={{ root: classes.selectMenuItem }}
                                      value={""}
                                    >
                                      Type User
                                    </MenuItem>
                                    {SalesType.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value={item.id}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            )}

                            {[1, 6].includes(user.userTypeId) &&
                              this.state.salesType === 0 &&
                              this.getUserDropDown(DirectUserType[0])}

                            {[1, 2].includes(user.userTypeId) &&
                              this.state.salesType === 1 &&
                              this.getUserDropDown(DirectUserType[1])}
                            {[1, 2].includes(user.userTypeId) &&
                              [1].includes(this.state.salesType) &&
                              this.getUserDropDown(3)}

                            {[1, 9].includes(user.userTypeId) &&
                              this.state.salesType === 4 &&
                              this.getUserDropDown(DirectUserType[4])}
                            {[1, 9].includes(user.userTypeId) &&
                              [4].includes(this.state.salesType) &&
                              this.getUserDropDown(10)}

                            {[1, 14].includes(user.userTypeId) &&
                              this.state.salesType === 6 &&
                              this.getUserDropDown(DirectUserType[6])}
                            {[1, 14].includes(user.userTypeId) &&
                              [6].includes(this.state.salesType) &&
                              this.getUserDropDown(15)}
                            {[1, 14, 15].includes(user.userTypeId) &&
                              [6].includes(this.state.salesType) &&
                              this.getUserDropDown(16)}
                            {[1, 14, 15, 16].includes(user.userTypeId) &&
                              [6].includes(this.state.salesType) &&
                              this.getUserDropDown(17)}

                            {[1, 19].includes(user.userTypeId) &&
                              this.state.salesType === 7 &&
                              this.getUserDropDown(DirectUserType[7])}
                            {[1, 19].includes(user.userTypeId) &&
                              [7].includes(this.state.salesType) &&
                              this.getUserDropDown(20)}
                            {[1, 19, 20].includes(user.userTypeId) &&
                              [7].includes(this.state.salesType) &&
                              this.getUserDropDown(21)}
                            {[1, 19, 20, 21].includes(user.userTypeId) &&
                              [7].includes(this.state.salesType) &&
                              this.getUserDropDown(22)}

                            {[1, 26].includes(user.userTypeId) &&
                              this.state.salesType === 9 &&
                              this.getUserDropDown(DirectUserType[9])}
                            {[1, 26].includes(user.userTypeId) &&
                              [9].includes(this.state.salesType) &&
                              this.getUserDropDown(27)}
                            {[1, 26, 27].includes(user.userTypeId) &&
                              [9].includes(this.state.salesType) &&
                              this.getUserDropDown(28)}
                          </GridContainer>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.username
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.username
                                : false
                            }
                            id="username"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "e.g : johnsnow",
                              value: this.state.username,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Username *"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.usernameErr}
                          </div>
                          <Input
                            error={
                              this.state.error ? this.state.error.email : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.email
                                : false
                            }
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "e.g : gateway@abbasyonline.com ",
                              value: this.state.email,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Email *"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.emailErr}
                          </div>
                          <Input
                            error={
                              this.state.error ? this.state.error.phone : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.phone
                                : false
                            }
                            id="phone"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Phone
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "contoh : 081123321456",
                              value: this.state.phone,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="No Handphone *"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.phoneErr}
                          </div>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.fullname
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.fullname
                                : false
                            }
                            id="fullname"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "e.g : Eren Jaeger",
                              value: this.state.fullname,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Nama Lengkap"
                          />
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.address
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.address
                                : false
                            }
                            id="address"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Address
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder:
                                "e.g : Jl. Jenderal Sudirman No. 99 Jagakarsa",
                              value: this.state.address,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Alamat Lengkap"
                          />
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="province-select"
                              className={classes.selectLabel}
                            >
                              Provinsi
                            </InputLabel>
                            <Select
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <ProvinceIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              }
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.province}
                              onChange={this.handleSelect}
                              inputProps={{
                                name: "province",
                                id: "province",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                value={-1}
                              >
                                Provinsi
                              </MenuItem>
                              {Provinces.map((prov, index) => (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={index}
                                >
                                  {prov.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.password
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.password
                                : false
                            }
                            id="password"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Password...",
                              type: "password",
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Password"
                          />
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.repassword
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.repassword
                                : false
                            }
                            id="repassword"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Re-type Password...",
                              type: "password",
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Re-Password"
                          />
                          {this.state.alert ? (
                            <SnackbarContent
                              message={<span>{this.state.alertMessage}</span>}
                              onExited={this.handleAlertExit}
                              close
                              color="danger"
                              icon="info_outline"
                            />
                          ) : null}
                          <div>
                            <div className={classes.textCenter}>
                              <Button
                                round
                                color="instagram"
                                onClick={this.handleSubmit}
                                disabled={
                                  !this.state.error.usernameErr &&
                                  !this.state.error.phoneErr &&
                                  !this.state.error.emailErr &&
                                  !this.state.error.fullname &&
                                  !this.state.error.address &&
                                  !this.state.error.password &&
                                  !this.state.error.repassword &&
                                  this.state.province
                                    ? false
                                    : true
                                }
                              >
                                create
                              </Button>
                            </div>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.showModal}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p>{this.state.modalMessage}</p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              round
              onClick={() => this.setState({ showModal: false })}
              color="success"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CreateUser.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(CreateUser));
