import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// UI Components
// import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Tooltip from "@material-ui/core/Tooltip";

import { Dimmer, Loader, Segment,Label,Table,Button as ButtonSemantic } from 'semantic-ui-react'

//Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Check from "@material-ui/icons/Check";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

//styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";


// Libs
import Config from 'config'
import axios from 'axios';
import {fnumber} from 'components/Helper'
import secureStorage from 'libs/secureStorage';
import _ from 'lodash'
import { EndUsers } from 'content/User/libs';

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Save } from '@material-ui/icons';


const CustomTransition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const style = theme => ({
	...contactUsStyle,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
  
})
const resetState = {remark:'',selectedRow:'',selectedIndex:''}
class CaTopUp extends React.Component{
	constructor(props){
		super(props);
		this.state = {
				
			// paging states >>
			rowsPerPage : 15,
			currentPage : 1,
			totalPages : 0,
			totalData : 0,
			rows : [],
			//<<pagin states
			keyword : '',
			parent : '',

			backPayload : '',

			parentTree : [secureStorage.getItem('user')],

			isLoading:false,

      modalShow:false,
      modalTitle:'',
      remark:'',
      inputAmount:0,
      selectAll:false,
      selectedRow:'',
      selectedIndex:'',

      selectedUsers:[],
		}
	}
	componentDidMount(){
		this.loadUsers();
	}

	loadUsers = (params) =>{
		this.setState({isLoading:true})
		let payload = {

			keyword : params && params.keyword ? params.keyword : this.state.keyword,
			currentPage : params && params.currentPage ? params.currentPage : this.state.currentPage,
			rowsPerPage : params && params.rowsPerPage? params.rowsPerPage:this.state.rowsPerPage
		}
		if(secureStorage.getItem('backPayload')){
			payload = secureStorage.getItem('backPayload')
			this.setState({
				keyword : payload.keyword,
				currentPage : payload.currentPage,
				rowsPerPage : payload.rowsPerPage,
			})
			secureStorage.removeItem('backPayload')
		}
		axios.post(Config.ApiUrl + '/user/ca/userlist',payload)
		.then(res => {
				// console.log(res.data)
				this.setState({
					rows:res.data.users,
					totalPages : res.data.totalPages,
					totalData : res.data.totalData,
					backPayload : payload,
					isLoading:false
				})
		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}
	handleClear = (e) =>{
		const params = {
			keyword : '',
			parentTree : [secureStorage.getItem('user')],
			currentPage:1
		}
		this.setState(params)
		this.loadUsers(params)
	}
	
	handleCheckBox = (userId,isBlocked,userTypeId) => {
		axios.post(Config.ApiUrl + '/user/ca/block',{
			userId,
			isBlocked,
      userTypeId
		})
		.then(res => {
			// console.log(res)
		})
		.catch(error => {
			console.log(error);
		});
	}

  getAllChildren(row){
    
    const children = row.children ? row.children.map((item)=>{
      // checking['b'+item.id]
      let deeper = []
      if(item.children)
        deeper = this.getAllChildren(item)
      return deeper.concat([item])
    }):[]
    // console.log({children})
    return children.concat([row])
  }

  renderRow({row,index,depth,parentId}){
    const {classes,t} = this.props
    const rows = [(
      <Table.Row
        key={row.id}
        style={{backgroundColor:row.userTypeId===3?'#8CB9E3':'#e8f4f7',}}
        >
          <Table.Cell>
            {
            !row.children?
            <ButtonSemantic disabled circular icon="hdd" style={{marginLeft:depth}}/>
            :
            !this.state['expanded'+row.id] ?
            <ButtonSemantic circular icon="chevron circle right" style={{marginLeft:depth}}
              onClick={()=>{
                this.setState({['expanded'+row.id]:true})
              }}
            />
            :
            <ButtonSemantic circular icon="chevron circle down" style={{marginLeft:depth}}
              onClick={()=>{
              this.setState({['expanded'+row.id]:false})
            }} 
            />}
            {`${row.id.toString().padStart(6,0)} (${row.userType.toUpperCase()})`}
          </Table.Cell>

          <Table.Cell>{row.name}</Table.Cell>

          <Table.Cell style={{textAlign:'right'}}>
            <span style={{marginRight:20}}>{row.balanceLimit!==undefined?fnumber(row.balanceLimit):'-'}</span>
            {!row.children?<Button color="facebook" size='sm' round onClick={()=>{
              this.setState({
                modalShow:true,
                modalTitle:t('ceiling'),
                inputAmount:row.balanceLimit,
                selectedRow:row,
                selectedIndex:index,
              })
            }} justIcon ><EditIcon /></Button>:null}
          </Table.Cell>

          <Table.Cell style={{textAlign:'right'}}>
            {row.balance!==undefined?fnumber(row.balance):'-'}
            {!row.children?<Button color="warning" size='sm' round onClick={()=>{
              this.setState({
                modalShow:true,
                modalTitle:t('mutation'),
                inputAmount:0,
                selectedRow:row,
                selectedIndex:index,
              })
            }} justIcon ><EditIcon /></Button>:null}
            <Checkbox
              id={'b'+row.id}
              checked={this.state['b'+row.id] !== undefined ? this.state['b'+row.id] : false}
              tabIndex={-1}
              onClick={(e) => {
                // console.log('b'+row.id)
                  //console.log(this.state['c'+product.id] !== undefined)

                  // this.setState({['b'+row.id]: e.target.checked }); 
                  
                  const users = _.uniqBy(_.flattenDeep(this.getAllChildren(row)),'id')
                  // console.log({ids})
                  const checking = {}
                  users.map((item)=>{
                    checking['b'+item.id] = e.target.checked
                    return item
                  })
                  
                  this.setState({selectedUsers:_.uniqBy(this.state.selectedUsers.concat(users.filter(item=>EndUsers.indexOf(item.userTypeId)!==-1)),'id')})
                  // console.log(_.uniqBy(this.state.selectedUsers.concat(users.filter(item=>EndUsers.indexOf(item.userTypeId)!==-1)),'id'))
                  this.setState({...checking})
                  
                // this.handleCheckBox(row.id,e.target.checked) 
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          </Table.Cell>

          <Table.Cell>
            <Checkbox
              id={'c'+row.id}
              checked={this.state['c'+row.id] !== undefined ? this.state['c'+row.id] : row.isBlocked}
              tabIndex={-1}
              onClick={(e) => {
                // console.log('c'+row.id)
                if(row.isBlocked && this.state['c'+row.id] === undefined){
                  //console.log(this.state['c'+product.id] !== undefined)
                  this.setState({['c'+row.id]: !row.isBlocked });
                } 
                else
                  this.setState({['c'+row.id]: e.target.checked }); 
                
                this.handleCheckBox(row.id,e.target.checked,row.userTypeId) 
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          </Table.Cell>
        </Table.Row>
    )]
    // && row.isExpanded
    const children = row.children && this.state['expanded'+row.id] ? row.children.map((item,idx)=>{
      return this.renderRow({row:item,index:idx,depth:depth+30,parentId:row.id})
    }):[]
    return rows.concat(children)
  }

  render(){
    
		const {classes,t} = this.props;

    let allRows = []
		this.state.rows.map((row,index)=>{
      allRows = allRows.concat(this.renderRow({row,index,depth:0}))
      return row
    })
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='huge'>TOP UP</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											
											<GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
												<Input
													labelText={t('search')}
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder : `UID / ${t('name')} / ${t('phone')}`,
														value : this.state.keyword,
														onChange : (e)=>{this.setState({keyword:e.target.value})},
														onKeyPress : (e)=>{if(e.key === 'Enter'){
															const params = {
																currentPage:1,
																keyword:e.target.value
															}
															this.setState(params)
															this.loadUsers(params)
														}}
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
												<span>
												<div className={classNames(classes.buttonGroup,classes.buttons)}>
													<Tooltip id="tooltip-search" title={t('search')} placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="twitter" size="sm" round onClick={()=>this.loadUsers()} className={classes.firstButton} ><SearchIcon /></Button>
													</Tooltip>
													<Tooltip id="tooltip-clear" title="Clear" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="google" size="sm" round onClick={()=>this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
													</Tooltip>
												</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign:'right'}}>
                <Button style={{marginLeft:20}} color="instagram" round onClick={()=>this.setState({modalShow:true,modalTitle:'Reset',inputAmount:0,remark:'Reset '+t('ceiling')})} ><RotateLeftIcon />reset</Button>
                <Button color="facebook" round onClick={()=>this.setState({modalShow:true,modalTitle:'TOP UP',inputAmount:0,remark:'TOP UP'})} ><ArrowUpwardIcon />top up</Button>
							</GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign:'right'}}>
                
							</GridItem>
              
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>

									<Table selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>UID</Table.HeaderCell>
                        <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                        <Table.HeaderCell style={{textAlign:'right'}}>{t('ceiling')}</Table.HeaderCell>
                        <Table.HeaderCell style={{textAlign:'right'}}>
                          {t('balance')}
                          <Checkbox
                            id='selectAll'
                            checked={this.state.selectAll}
                            tabIndex={-1}
                            onClick={(e) => {
                              
                              const checking = {}
                              
                              let users = this.state.rows.map((item)=>{
                                // checking['b'+item.id] = e.target.checked
                                return this.getAllChildren(item)
                              })
                              users = _.uniqBy(_.flattenDeep(users),'id')
                              users.map(item=>{
                                checking['b'+item.id] = e.target.checked
                                return item
                              })
                              this.setState({selectedUsers:_.uniqBy(this.state.selectedUsers.concat(users.filter(item=>EndUsers.indexOf(item.userTypeId)!==-1)),'id')})
                              // console.log(_.uniqBy(this.state.selectedUsers.concat(users.filter(item=>EndUsers.indexOf(item.userTypeId)!==-1)),'id'))
                              this.setState({selectAll:e.target.checked,...checking})
                            }}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Block</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>
										<Table.Body>
                      {allRows}
                    </Table.Body>
									</Table>
								</Segment>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage)=>{
											if(currentPage !== this.state.currentPage){
												this.setState({currentPage})
												this.loadUsers({currentPage})
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5,15,25,'All']}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value)=>{
											this.setState({rowsPerPage:value,currentPage:1})
											this.loadUsers({rowsPerPage:value,currentPage:1})
										}}
									/>
								</div>
							</GridItem>
            </GridContainer>
            {/* Modal >> */}
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={CustomTransition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
                <GridContainer justify="center">
                  <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                    {this.state.modalTitle!=='Reset' ? 
                    <Input
                      labelText='Amount'
                      id="inputAmount"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: 'Amount',
                        value: fnumber(this.state.inputAmount),
                        onChange: (e)=>{
                          const valStr = e.target.value.replace(/\./g, '')
                          let inputAmount = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
                          if (inputAmount < 0)
                            inputAmount = 0
                          this.setState({ inputAmount })
                        },
                      }}
                    />:
                    <p>Reset {t('ceiling')}</p>
                    }
                    {['Mutation','Mutasi'].indexOf(this.state.modalTitle)!==-1 && 
                    <Input
                      labelText='Remark'
                      id="remark"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: 'Remark',
                        value: this.state.remark,
                        onChange: (e)=>this.setState({remark:e.target.value}),
                      }}
                    />
                    }
                  </GridItem>
                </GridContainer>
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
                <Button onClick={()=>this.setState({modalShow:false,...resetState})} round color="warning">
                    {t('cancel')}
                </Button>
                {['Ceiling','Plafon'].indexOf(this.state.modalTitle)!==-1?
                <Button round color="success" onClick={(e)=>{
                  const userId = this.state.selectedRow.id
                  axios.post(Config.ApiUrl + '/user/ca/ceiling/set',{
                    userId,
                    balanceLimit:this.state.inputAmount,
                  })
                  .then(res => {
                    // console.log(res.data)
                    this.setState({modalShow:false,...resetState})
                    const users = this.state.rows
                    
                    const newUsers =  this.updateRows({
                      userId,
                      field:'balanceLimit',
                      value:res.data.balanceLimit,
                      parents:res.data.parents,
                      users,
                    })
                    this.setState({rows:newUsers})
                  })
                  .catch(error => {
                    console.log(error);
                  });
                }}><Save />
                  {t('save')}
                </Button>
                :
                ['Mutation','Mutasi'].indexOf(this.state.modalTitle)!==-1?
                <div>
                  <Button round color="danger" 
                  disabled={this.state.remark!=='' && this.state.inputAmount>0?false:true} 
                  onClick={(e)=>this.mutation(this.state.selectedRow,this.state.selectedIndex,this.state.inputAmount,'deduct',this.state.remark,this.state.selectedRow.balance)}>
                    <RemoveIcon />
                    {t('deduct')}
                  </Button>
                  <Button round color="success" 
                  disabled={this.state.remark!=='' && this.state.inputAmount>0?false:true}
                  onClick={(e)=>this.mutation(this.state.selectedRow,this.state.selectedIndex,this.state.inputAmount,'transfer',this.state.remark)}>
                    <AddIcon />
                    Transfer
                  </Button>
                </div>
                :
                this.state.modalTitle==='TOP UP'?
                  <Button round color="success" 
                  disabled={this.state.remark!=='' && this.state.inputAmount>0?false:true}
                  onClick={(e)=>this.topup()}>
                    <AddIcon />
                    top up
                  </Button>
                :
                  <Button round color="success" 
                  // disabled={this.state.remark!=='' && this.state.inputAmount>0?false:true}
                  onClick={(e)=>this.reset()}>
                    <RotateLeftIcon />
                    Reset
                  </Button>
                }
							</DialogActions>
						</Dialog>
						{/* << Modal */}
          </div>
        </div>
      </div>
		)
	}
  updateRows({userId,field,value,parents,users}){
    let idx = _.findIndex(users,item=>item.id===userId)
    if(idx===-1){
      idx = _.findIndex(users,item=>parents.indexOf(item.id)!==-1)
      const user = users[idx]
      user.children = this.updateRows({userId,field,value,parents,users:user.children})
      users[idx] = user
    }else{
      const user = users[idx]
      user[field] = value
      users[idx] = user
    }
    return users
  }
  mutation = (user,selectedIndex,amount,mutation,remark,userBalance)=>{
    const balance = secureStorage.getItem('balance')
    if((mutation === 'transfer' && balance>=amount) || (mutation === 'deduct' && userBalance>=amount)){
      const payload = {
        userId:user.id,
        user,
        amount,
        mutation,
        remark
      }
      axios.post(Config.ApiUrl + '/user/ca/mutation',payload)
      .then(res => {
        // console.log(res)
        secureStorage.setItem('balance',res.data.balance)
        this.setState({modalShow:false,...resetState})
        const users = this.state.rows
        const newUsers =  this.updateRows({
          userId:user.id,
          field:'balance',
          value:res.data.userBalance,
          parents:res.data.parents,
          users,
        })
        this.setState({rows:newUsers})
      })
      .catch(error => {
        console.log(error);
      });
    }
  }
  topup = ()=>{
    const balance = secureStorage.getItem('balance')
    
    const filtered = this.state.selectedUsers.filter(item=>this.state['b'+item.id] && item.userTypeId===4)
    const totalAmount = filtered.length * parseInt(this.state.inputAmount)
    // console.log({balance,totalAmount})
    // const users = 
    if(balance>=totalAmount){
      this.state.selectedUsers.map((item,index)=>{
        if(this.state['b'+item.id] )
          this.mutation(item,index,this.state.inputAmount,'transfer','TOP UP')
        return item
      })
    }
  }
  reset = ()=>{
    const balance = secureStorage.getItem('balance')

    const filtered = this.state.selectedUsers.filter(item=>this.state['b'+item.id] && item.balanceLimit>item.balance)

    const totalAmount = _.sum(filtered.map(item=>item.balanceLimit-item.balance))
    // console.log({filtered,balance,totalAmount})
    if(balance>=totalAmount){
      this.state.selectedUsers.map((item,index)=>{
        if(this.state['b'+item.id] && item.balanceLimit>item.balance)
          this.mutation(item,index,item.balanceLimit-item.balance,'transfer','Ceiling Reset')
        return item
      })
    }
  }
}
CaTopUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(style))(CaTopUp);
