// Core
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from "classnames";

//Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";
// import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";

import {Label} from 'semantic-ui-react'

// Icons
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

// Libs
import axios from 'axios';
import Config from 'config'
import { fnumber } from 'components/Helper'
import moment from 'moment'

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Pagination from "components/Pagination/Pagination.js"

import {Table} from 'semantic-ui-react'

const styles = theme => ({
	...modalStyle(theme),
	...style,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
	oddRow : {
		backgroundColor:'#e8f4f7'
	},
	evenRow :{
		backgroundColor:'#cbe3ee'
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Bot extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			//user : secureStorage.getItem('user'),

			// paging states >>
			rowsPerPage : 5,
			currentPage : 1,
			totalPages : 0,
			totalData : 0,
			rows : [],
			//<<pagin states
			
			keyword : '',
			
			modalMode : '',
			modalShow : false,
			modalTitle : '',

			modalName : '',
			modalEmail : '',
      modalTelegramId:'',
      modalPassword:'',
      modalRePassword:'',

			selectedBill: '',
			selectedParentBill : '',

      telegramIsEdit : false,
      telegramId : '',

		}
	}
	componentDidMount(){
		// this.loadBill()
	}

	loadBill=(params)=>{
		axios.post(Config.ApiUrl + '/payment',{
			currentPage : params && params.currentPage ? params.currentPage : 1,
			rowsPerPage : params && params.rowsPerPage? params.rowsPerPage:this.state.rowsPerPage
		})
		.then(res => {
			//console.log(params &&  params.partnerDropdown?'true':'false')
			console.log(res.data)
			this.setState({
				rows : res.data.bills,
				totalPages : res.data.totalPages,
				totalData : res.data.totalData
			})
		})
		.catch(error => {
			console.log(error);
		});
			
	}

	handleSubmit = () =>{
		this.loadBill()
	}
	
	handleDropDownChange = (e) => {
			this.setState({[e.target.name]:e.target.value})
	}
	handleClear = (e) =>{
		this.setState({
			keyword : '',
		})
		this.loadBill({keyword:''})
	}

	handleShowModal = () =>{
		this.setState({modalShow:true})
	}
	

	renderStatus(bill){
		let color
		switch(bill.statusId){
			case 2 :
				color = 'twitter'
				break
			case 3 : 
				color = 'warning'
				break
			case 4 : 
				color = 'success'
				break
			case 6 : 
				color = 'danger'
				break
			default :
				color = 'facebook'
		}

		return <Badge color={color}>{bill.BillStatus.name}</Badge>
	}
	renderButton(bill,parentBill){
		let disabled = false
		if(bill.Splitted && bill.Splitted.length>0)
			disabled = true
		if(bill.statusId!==2)
			disabled = true
		if(bill.statusId === 6 && !bill.bankId)
			disabled = true

		return <Button color="instagram" size="sm" round 
		disabled={disabled}
		onClick={
			()=>{
				this.setState({
					modalMode : 'confirm',
					modalShow:true,
					modalTitle:'Payment Confirmation',
					modalName : !parentBill ? bill.BillCharged.name: parentBill.BillCharged.name,
					modalAmount : bill.uniqueAmount,
					selectedBill : bill,
					selectedParentBill : parentBill
				})
			}
		} >Confirm</Button>
	}

	render(){
		const {classes} = this.props;
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='big'>Admin List</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12} className={classes.grid}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Button size='sm' round color="instagram" style={{marginTop:30}} onClick={(e)=>this.initModal()}>add admin</Button>
											</GridItem>
											<GridItem xl={4} lg={4} md={4} sm={6} xs={6}>
                        <Input
                          labelText="Telegram ID"
                          id="telegramId"
                          formControlProps={{
                            // fullWidth: true
                          }}
                          inputProps={{
                            placeholder : 'Telegram ID',
                            value : this.state.telegramId,
                            readOnly : !this.state.telegramIsEdit,
                            onChange:(e)=>{
                              this.setState({telegramId:e.target.value})
                            }
                          }}
                        />
                        { !this.state.telegramIsEdit ?
                          <Button size='sm' justIcon round color="instagram" style={{marginTop:30}} onClick={(e)=>this.setState({telegramIsEdit:true})}><EditIcon /></Button>
                        :
                          <Button size='sm' justIcon round color="instagram" style={{marginTop:30}} onClick={(e)=>{
                            if(!isNaN(this.state.telegramId)){
                              axios.post(Config.ApiUrl + 'user/telegram/update',{
                                telegramId:this.state.telegramId
                              })
                              .then(res=>{
                                this.setState({telegramIsEdit:false})
                              }).catch(err=>{
                                console.log(err)
                              })
                            }
                          }}><SaveIcon /></Button>
                        }
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
											
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>

											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>#</Table.HeaderCell>
											<Table.HeaderCell>LID</Table.HeaderCell>
											<Table.HeaderCell>Name</Table.HeaderCell>
											<Table.HeaderCell>Email</Table.HeaderCell>
											<Table.HeaderCell>Telegram ID</Table.HeaderCell>
											<Table.HeaderCell>Action</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
										this.state.rows.map((bill,index)=>{
											const loop = bill.Splitted.length === 0 ? 1 : bill.Splitted.length+1
											const tableRow = []
											for(let i=0;i<loop;i++){
												const splitted = i>0 && bill.Splitted.length > 0 && bill.Splitted[i-1]
												tableRow.push(<Table.Row key={index+i} className={index%2===0?classes.evenRow:classes.oddRow}>
													<Table.Cell>{i===0 && (index+1)+ (isNaN(this.state.rowsPerPage)? 0 :((this.state.currentPage-1)* this.state.rowsPerPage))}</Table.Cell>
													<Table.Cell>{i===0 ? bill.id.toString().padStart(6,0) : splitted.id.toString().padStart(6,0)}</Table.Cell>
													<Table.Cell>{i===0 && bill.BillCharged.id.toString().padStart(6,0)}</Table.Cell>
													<Table.Cell>{i===0 && bill.BillCharged.name}</Table.Cell>
													<Table.Cell>{i===0 && (bill.cycle===1 ? 
														moment(bill.createdAt).startOf('day').add(11,'hours').format('LLLL')
														:
														moment(bill.createdAt).add(3,'hours').format('LLLL'))
													}</Table.Cell>
													<Table.Cell>{i===0 ? bill.Bank && bill.Bank.name : splitted.Bank && splitted.Bank.name}</Table.Cell>
													<Table.Cell>{i===0 ? bill.Splitted.length === 0 && fnumber(bill.uniqueAmount) : fnumber(splitted.uniqueAmount)}</Table.Cell>
													<Table.Cell>{i===0 ? fnumber(bill.amount) : fnumber(splitted.amount)}</Table.Cell>
													<Table.Cell>{i===0 ? fnumber(bill.paidAmount?bill.paidAmount:0) : fnumber(splitted.paidAmount?splitted.paidAmount:0)}</Table.Cell>
													<Table.Cell>{i===0 ? this.renderStatus(bill) : this.renderStatus(splitted)}</Table.Cell>
													<Table.Cell>{i===0 ? this.renderButton(bill) : this.renderButton(splitted,bill)}</Table.Cell>
												</Table.Row>)
											}
											return tableRow													
										})
										}
									</Table.Body>
									
								</Table>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage)=>{
											if(currentPage !== this.state.currentPage){
												this.setState({currentPage})
												this.loadBill({currentPage})
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5,15,25,'All']}
										defaultRowsPerPage={this.state.rowsPerPage}
										onChangeRowsPerPage={(value)=>{
											console.log(value)
											this.setState({rowsPerPage:value,currentPage:1})
											this.loadBill({rowsPerPage:value,currentPage:1})
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={Transition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								
								<GridContainer justify='center'>
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<Input
											labelText="Name"
											id="modalName"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : 'Name',
												value : this.state.modalName,
												onChange : (e)=> this.setState({modalName:e.target.value}),
											}}
										/>
                    <Input
											labelText="Email"
											id="modalEmail"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : 'Email',
												value : this.state.modalEmail,
												onChange : (e)=> this.setState({modalEmail:e.target.value}),
											}}
										/>
										<Input
											labelText="Telegram ID"
											id="modalTelegramId"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : 'Telegram ID',
												value : this.state.modalTelegramId,
												onChange : (e)=> this.setState({modalTelegramId:e.target.value}),
											}}
										/>
                    {this.state.modalTitle==='Add Admin' &&
                    <div>
                      <Input
                        labelText="Password"
                        id="modalPassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder : 'Password',
                          value : this.state.modalPassword,
                          type:'password',
                          onChange : (e)=> this.setState({modalPassword:e.target.value}),
                        }}
                      />
                      <Input
                        labelText="Re-Type Password"
                        id="modalRePassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder : 'Re-Type Password',
                          value : this.state.modalRePassword,
                          type:'password',
                          onChange : (e)=> this.setState({modalRePassword:e.target.value}),
                        }}
                      />
                    </div>}
                    
									</GridItem>
								</GridContainer>
								
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
                <Button onClick={()=>this.handleModal('cancel')} color="danger">Cancel</Button>
								{ this.state.modalTitle === 'Add Admin' ?
								<Button onClick={()=>this.handleModal('submit')} color="success">Submit</Button>:null}
								{ this.state.modalMode === 'cancel' ?
								<Button onClick={()=>this.handleModal('cancel')} color="danger">Cancel</Button>:null}
								{ this.state.modalMode === 'pending' ?
								<Button onClick={()=>this.handleModal('pending')} color="danger">Delete</Button>:null}
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
    )
	}
  initModal = (item)=>{
    if(!item){
      this.setState({
        modalShow:true,
        modalTitle:'Add Admin'
      })
    }
  }
  handleModal = (target) =>{
		if(target !== 'cancel'){
			if(this.state.modalAmount && this.state.selectedBill){
				const payload = {
					amount : this.state.modalAmount,
					selectedBill : this.state.selectedBill,
					selectedParentBill : this.state.selectedParentBill
				}
				
				axios.post(Config.ApiUrl + '/payment/confirm',payload)
				.then(res=>{
					this.loadBill()
					this.setState({modalShow:false,selectedBill:''})
				}).catch(err=>{
					console.log(err)
				})
			}
		}
		else
			this.setState({modalShow:false})
	}
}
Bot.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Bot);