import React from 'react'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Input from "components/CustomInput/CustomInputTicket.js";

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import CircularProgress from "@material-ui/core/CircularProgress"

import { fnumber } from 'components/Helper'
import axios from 'axios'
import Config from 'config'

import { Label } from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import secureStorage from 'libs/secureStorage';

const styles = {
  ...style,
}

class Bill extends React.Component {
  constructor(props) {
    super()
    this.state = {
      amount: '',
      status: '',

      bill: '',
      unique: '345',
      uniqueAmount: '2.000.345',

      accountNumber: '',

      bank: [],
      selectedBank: '',

      toolTipOpen: false,

      alert: false,
      error: [],
      success: [],

      showModal: false,
      modalTitle: '',
      modalMessage: '',

      buttonDisabled: true,
      splitButtonDisabled: true,

      splitAmount: '',
      remainingAmount: '',
    }
  }
  componentDidMount() {
    this.initBill()
    this.initBank()
  }
  initBank() {
    axios.post(Config.ApiUrl + '/bill/bank')
      .then(res => {
        // console.log(res.data)
        this.setState({ bank: res.data })
      }).catch(err => {
        console.log(err)
      })
  }
  initBill() {
    axios.post(Config.ApiUrl + '/bill/status')
      .then(res => {
        // console.log(res.data)
        const bill = res.data
        if (bill.Splitted) {
          bill.Splitted = _.sortBy(bill.Splitted, 'id')
        }
        this.setState({
          bill,
          status: bill.statusId,
          uniqueAmount: bill.uniqueAmount,
          unique: bill.unique,
          // accountNumber : `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`,
        })
        this.setRemainingAmount(bill)
        // if(res.data.statusId!==2)
        // 	this.setState({buttonDisabled:false})
      }).catch(err => {
        //console.log(err)
      })
  }
  setRemainingAmount(bill) {
    if (bill) {
      let remainingAmount = bill.amount
      bill.Splitted.map(split => {
        remainingAmount -= split.amount
        return split
      })
      if (remainingAmount === 0)
        this.setState({ splitButtonDisabled: true })
      this.setState({ remainingAmount })
    }
  }

  handleInput = (e) => {
    //alert(e.id)
    if (this.state.buttonDisabled)
      this.setState({ buttonDisabled: false })

    const valStr = e.target.value.replace(/\./g, '')
    let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
    if (value < 0)
      value = 0
    this.setState({ amount: fnumber(value) })
    if (!value || value < 1000 || !this.state.selectedBank)
      this.setState({ buttonDisabled: true })
  }

  handleAlertExit = (e) => {
    this.setState({ alert: false })
  }
  handleCloseModal = (e) => {
    this.setState({ showModal: false })
  }
  handleTooltipClose = (e) => {
    this.setState({ toolTipOpen: false })
  }

  handleCopy = (e) => {
    this.input.select();
    document.execCommand('copy');
    this.setState({ toolTipOpen: true })
  }
  getDueDate = (bill) => {
    const user = secureStorage.getItem('user')
    let dueDate
    if (bill) {
      if (moment(bill.createdAt).weekday() === 6)
        dueDate = moment(bill.createdAt).startOf('day').add(2, 'days').add(11, 'hours').add(30, 'minutes')
      else if (moment(bill.createdAt).weekday() === 0)
        dueDate = moment(bill.createdAt).startOf('day').add(1, 'days').add(11, 'hours').add(30, 'minutes')
      else if (bill.cycle === 2)
        dueDate = moment(bill.createdAt).add(3, 'hours')
      else
        dueDate = moment(bill.createdAt).startOf('day').add(11, 'hours').add(30, 'minutes')
      if (user.userTypeId === 4)
        dueDate = dueDate.subtract(30, 'minutes')
      return dueDate
    }
    else
      return 'No Active Bill Available'
  }
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem xl={2} lg={2} md={2} sm={12} xs={12}>
                    <Label size='big'>{t('bill').toUpperCase()}</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={8} className={classes.grid}>
                    <input style={{ position: 'absolute', left: '-5000px', top: '-5000px' }}
                      defaultValue={this.state.uniqueAmount}
                      ref={(input) => this.input = input}
                    />
                    {!this.state.bill ?
                      <div>
                        <Label size='big' color='blue'>{t('No Bill Available')}</Label>
                      </div>
                      : null}
                    {
                      this.state.bill &&
                      <div>
                        <SnackbarContent
                          message={
                            [1, 7, 3].indexOf(this.state.status) !== -1 ?
                              <span style={{ color: '#000' }}>
                                Jatuh tempo tagihan ini sampai dengan jam <b style={{ color: '#0000FF' }}>{this.getDueDate(this.state.bill).format('HH:mm')}</b> pada hari dan tanggal yg sama.
                                Jika pembayaran melewati batas waktu yang telah ditentukan maka sistem akan memblokir akun secara otomatis.
                                Silahkan transfer sebesar <b style={{ color: '#FF0000' }}>Nominal Transfer</b> untuk mempercepat verifikasi pembayaran.

                              </span>
                              :
                              this.state.status === 2 ?
                                <span style={{ color: '#000' }}>
                                  Sistem sedang melakukan verifikasi, buka halaman Bill History untuk melihat status pembayaran.
                                </span>
                                :
                                this.state.status === 6 ?
                                  <span style={{ color: '#000' }}>
                                    Mohon untuk melunasi tagihan, akun telah terblokir secara otomatis oleh sistem. Akun akan terbuka secara otomatis setelah tagihan dilunasi. Silahkan hubungi bagian administrasi untuk keterangan lebih lanjut.
                                  </span>
                                  : null
                          }
                          onExited={this.handleAlertExit}
                          close
                          color="warning"
                          icon="info_outline"
                        />
                        <Input
                          labelText={t('dueAmount')}
                          id="billAmount"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            readOnly: true,
                            value: this.state.bill ? fnumber(this.state.bill.amount) : 'No Active Bill Available',
                            placeholder: 'Bill Amount',
                            type: 'tel',
                          }}
                        />
                        <Input
                          labelText={t('dueDate')}
                          id="dueDate"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            readOnly: true,
                            value: this.getDueDate(this.state.bill).format('LLLL'),
                            placeholder: 'Bill Amount',
                            type: 'tel',
                          }}
                        />
                        {this.state.bill && this.state.bill.Splitted.length > 0 &&
                          <Input
                            key='remainingAmount'
                            labelText={t('remainingAmount')}
                            id="remainingAmount"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              readOnly: true,
                              value: fnumber(this.state.remainingAmount),
                              placeholder: 'Remaining Amount',
                              type: 'tel',
                            }}
                          />}
                        {
                          this.state.bill && this.state.bill.Splitted.length === 0 ?
                            this.getForm(this.state.bill)
                            :
                            this.state.bill.Splitted.map((splitted, index) => (this.getForm(splitted, index)))
                        }
                      </div>
                    }
                    {
                      this.state.bill && this.state.bill.Splitted.length === 0 && [1, 6].indexOf(this.state.status) !== -1 &&
                      <div style={{ textAlign: 'center' }}>
                        <Button round color="instagram" onClick={() => this.submit(this.state.bill)} disabled={this.state['buttonDisabled' + this.state.bill.id] !== undefined ? this.state['buttonDisabled' + this.state.bill.id] : true} >
                          Request {t('payment')}
                        </Button>
                      </div>
                    }
                  </GridItem>
                  {/* {
                    this.state.bill && this.state.bill.Splitted.length === 0 && [1,6].indexOf(this.state.status) !== -1 && */}
                  <GridItem xs={12} sm={12} md={8} className={classes.grid}>

                    <div>
                      {
                        // this.getNominalInput()
                      }
                      <Label size='large' color='blue'>{t('splitBill')}</Label>
                      <Input
                        key='splitAmount'
                        labelText={t('splitAmount')}
                        id='splitAmount'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: fnumber(this.state.splitAmount),
                          placeholder: 'Split Amount',
                          type: 'tel',
                          onChange: (e) => {
                            let val = e.target.value.replace(/\./g, '')
                            // const dueAmount = this.state.bill && this.state.bill.amount
                            val = !val || isNaN(val) ? 0 : parseInt(val)
                            if (val > this.state.remainingAmount) {
                              val = 0
                            }
                            let splitButtonDisabled = true
                            if (val !== 0) {
                              splitButtonDisabled = false
                            }
                            this.setState({ splitButtonDisabled, splitAmount: val })
                            // this.setNominal(i,val)
                            // this.setSplitButton(i,val)
                          }
                        }}
                      />
                      <Input
                        key='remainingAmount'
                        labelText={t('remainingAmount')}
                        id="remainingAmount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          readOnly: true,
                          value: fnumber(this.state.remainingAmount),
                          placeholder: 'Remaining Amount',
                          type: 'tel',
                        }}
                      />
                    </div>
                    {/* } */}
                    <div style={{ textAlign: 'center' }}>
                      <Button round color="instagram"
                        onClick={() => {
                          // const splittedNominal = []
                          // for(let i=1;i<=this.state.splitNumber;i++){
                          //   splittedNominal.push(this.state['nominalTransfer'+i])
                          // }
                          if (this.state.splitAmount) {
                            const payload = {
                              splitAmount: this.state.splitAmount,
                              bill: this.state.bill,
                              // splittedNominal,
                            }
                            // console.log(payload)
                            axios.post(Config.ApiUrl + '/bill/split', payload)
                              .then(res => {
                                // console.log(res.data)
                                this.setState({ bill: res.data, splitAmount: 0, splitButtonDisabled: true })
                                this.setRemainingAmount(res.data)
                              }).catch(err => {
                                console.log(err)
                              })
                          }
                        }}
                        disabled={this.state.splitButtonDisabled}>
                        {t('splitBill')}
                      </Button>
                    </div>
                  </GridItem>
                  {/* } */}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
  getNominalInput() {
    const rows = []
    for (let i = 1; i < this.state.splitNumber; i++) {
      rows.push(<Input
        key={'nominalTransfer' + i}
        labelText={`Nominal Transfer ${i}`}
        id={'nominalTransfer' + i}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          value: this.state['nominalTransfer' + i] ? fnumber(this.state['nominalTransfer' + i]) : 0,
          placeholder: 'Nominal Transfer ' + i,
          type: 'tel',
          onChange: (e) => {
            let val = e.target.value.replace(/\./g, '')
            val = !val || isNaN(val) ? 0 : parseInt(val)
            this.setNominal(i, val)
            this.setSplitButton(i, val)
          }
        }}
      />)
    }
    return rows
  }
  setSplitButton(index, value) {
    let splitButtonDisabled = false
    for (let i = 1; i < this.state.splitNumber + 1; i++) {
      if (i === index && !value)
        splitButtonDisabled = true
      else if (!this.state['nominalTransfer' + i])
        splitButtonDisabled = true
    }
    this.setState({ splitButtonDisabled })
  }
  setNominal(index, value) {
    let total = 0
    for (let i = 1; i < this.state.splitNumber; i++) {
      if (i === index)
        total += value
      else
        total += this.state['nominalTransfer' + i] ? this.state['nominalTransfer' + i] : 0
    }
    const lastAmount = this.state.bill.amount - total
    // console.log({lastAmount,total})
    if (lastAmount < 0)
      this.setState({
        ['nominalTransfer' + index]: 0,
        ['nominalTransfer' + this.state.splitNumber]: parseInt(this.state['nominalTransfer' + this.state.splitNumber]) + parseInt(this.state['nominalTransfer' + index])
      })
    else
      this.setState({
        ['nominalTransfer' + index]: value,
        ['nominalTransfer' + this.state.splitNumber]: this.state.bill.amount - total
      })
  }
  submit = (bill) => {

    const bank = this.state['selectedBank' + bill.id]
    // const banks = this.state.bill.Splitted.length > 0 && this.state.bill.Splitted.map(split=>(this.state['selectedBank'+split.id]))

    // if(this.state.status===1){
    axios.post(Config.ApiUrl + '/bill/payment', {
      bank,
      // banks,
      bill,//:this.state.bill
    }).then(res => {
      // console.log(res.data)
      const bill = res.data
      this.setState({
        buttonDisabled: true,
        status: bill.statusId,
        bill,
      })
    }).catch(err => {
      console.log(err)
    })
    // }
  }
  getForm(bill, index) {
    const { classes, t } = this.props
    const borderStyle = bill.parentId ? {
      borderStyle: 'solid',
      padding: '10px 10px 10px 10px',
      borderRadius: 10,
      marginTop: 20,
      borderColor: '#AEAEAE',
      borderWidth: 1
    } : {}
    return (
      <div key={'nominalTransfer' + bill.id}
        style={{ ...borderStyle }}>
        {!bill.Splitted &&
          <Label size='large' color='blue'>Transfer {index + 1}</Label>}
        {!bill.Bank &&
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor={'nominalTransfer' + bill.id}
              className={classes.selectLabel}
            >
              Bank
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}

              value={this.state['selectedBank' + bill.id] ? this.state['selectedBank' + bill.id] : ''}
              onChange={(e) => {
                const bank = e.target.value

                let buttonDisabled = false
                // this.state.bill.Splitted.map(split=>{
                //     if(!this.state['accountNumber'+split.id] && split.id !== bill.id)
                //       buttonDisabled = true
                //     return split
                // })
                // console.log({['buttonDisabled'+bill.id]:buttonDisabled})
                this.setState({
                  ['buttonDisabled' + bill.id]: buttonDisabled,
                  ['selectedBank' + bill.id]: bank,
                  ['accountNumber' + bill.id]: `${bank.name} ${bank.accountNumber} a.n. ${bank.accountName}`,
                })
              }}
              inputProps={{
                name: "bank",
                id: "bank"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
                value={-1}
              >
                {this.state.bank.length > 0 ? 'Bank' : <CircularProgress size={30} />}
              </MenuItem>
              {this.state.bank.map((item, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
        <Input
          labelText={t('uniqueCode')}
          id={"unique" + bill.id}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: bill.unique,
            type: 'tel',
            readOnly: true,
          }}
        />
        <br />
        <Input
          labelText="Nominal Transfer"
          id={"uniqueAmount" + bill.id}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: fnumber(bill.uniqueAmount),
            type: 'tel',
            readOnly: true,
          }}
          style={{ float: "left", overflow: "hidden" }}
        />
        <Input
          labelText={t('bankAccount')}
          id={"accountNumber" + bill.id}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: this.state['accountNumber' + bill.id] ? this.state['accountNumber' + bill.id] : bill.Bank ? `${bill.Bank.name} ${bill.Bank.accountNumber} a.n. ${bill.Bank.accountName}` : '',//this.state.selectedBank ? this.state.selectedBank.accountNumber:'',
            type: 'tel',
            readOnly: true,
          }}
        />
        {bill.parentId && bill.statusId === 1 &&
          <div style={{ textAlign: 'center' }}>
            <Button round color="instagram" disabled={this.state['buttonDisabled' + bill.id] !== undefined ? this.state['buttonDisabled' + bill.id] : true}
              onClick={(e) => {
                this.submit(bill)
              }}
            >
              Request {t('payment')}
            </Button>
          </div>}
        {bill.parentId && bill.statusId !== 1 &&
          <Input
            labelText="Status"
            id={"status" + bill.id}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: bill.BillStatus.name,
              // type : 'tel',
              readOnly: true,
            }}
          />
        }
      </div>
    )
  }
}

Bill.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withTranslation(), withStyles(styles))(Bill);

// const split = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]