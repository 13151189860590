// Core
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"

import { withTranslation } from "react-i18next"
import { compose } from "redux"

// UI compononents
import Table from "components/Table/Table"
import Input from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Pagination from "components/Pagination/Pagination.js"
import Tooltip from "@material-ui/core/Tooltip"
import { Dimmer, Loader, Segment, Label, Checkbox as CheckboxSem } from "semantic-ui-react"

// Icons
import SearchIcon from "@material-ui/icons/Search"
import ClearAllIcon from "@material-ui/icons/ClearAll"

// Styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"
import contactStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"

// libs
import Config from "config"
import axios from "axios"
import _ from "lodash"
import moment from "moment"

// Components
import secureStorage from "libs/secureStorage"

const style = (theme) => ({
	...modalStyle(theme),
	...contactStyle,
	buttons: {
		marginTop: "25px",
	},
})

class ViewOtp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			rowsPerPage: 5,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			rows: [],
			//<<pagin states
			keyword: "",

			isLoading: false,
		}
	}

	componentDidMount() {
		this.loadData()
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		let payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : this.state.currentPage,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
		}

		axios
			.post(Config.ApiUrl + "/user/otp", payload)
			.then((res) => {
				this.setState({
					rows: res.data.users,
					totalPages: res.data.totalPages,
					totalData: res.data.totalData,
					isLoading: false,
					keyword: "",
				})
			})
			.catch((error) => {
				this.setState({ isLoading: false })
				console.log(error)
			})
	}

	handleClear = (e) => {
		const params = {
			keyword: "",
			currentPage: 1,
		}
		this.setState(params, () => {
			this.loadData(params)
		})
	}

	render() {
		const { classes, t } = this.props
		const user = secureStorage.getItem("user")

		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							{/* Header */}
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size="huge">{t("ViewOtp")}</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
												<Input
													labelText={t("search")}
													id="keyword"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														placeholder: `UID / ${t("name")}`,
														value: this.state.keyword,
														onChange: (e) => {
															this.setState({ keyword: e.target.value })
														},
														onKeyPress: (e) => {
															if (e.key === "Enter") {
																const params = {
																	currentPage: 1,
																	keyword: e.target.value,
																}
																this.setState(params, () => {
																	this.loadData(params)
																})
															}
														},
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
												<span>
													<div className={classNames(classes.buttonGroup, classes.buttons)}>
														<Tooltip
															id="tooltip-search"
															title="Search"
															placement="top"
															classes={{ tooltip: classes.tooltip }}
														>
															<Button
																color="twitter"
																size="sm"
																round
																onClick={() => this.loadData()}
																className={classes.firstButton}
															>
																<SearchIcon />
															</Button>
														</Tooltip>
														<Tooltip
															id="tooltip-clear"
															title="Clear"
															placement="top"
															classes={{ tooltip: classes.tooltip }}
														>
															<Button
																color="google"
																size="sm"
																round
																onClick={() => this.handleClear()}
																className={classes.lastButton}
															>
																<ClearAllIcon />
															</Button>
														</Tooltip>
													</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</GridItem>

							{/* Table */}
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>

									<Table
										stickyHead
										stickyFoot
										striped
										tableHead={["#", "UID", t("name"), "Email", t("verCode"), t("Date"), t("userType")]}
										tableData={this.state.rows.map((row, index) => [
											index +
												1 +
												(this.state.currentPage - 1) * (isNaN(this.state.rowsPerPage) ? 0 : this.state.rowsPerPage),
											row.id.toString().padStart(6, 0),
											row.name,
											row.email,
											row.verCode ?? "-",
											moment(row.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
											row.userType,
										])}
									/>
								</Segment>
							</GridItem>

							{/* Paging */}
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter}>
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage) => {
											if (currentPage !== this.state.currentPage) {
												this.setState({ currentPage }, () => {
													this.loadData({ currentPage })
												})
											}
										}}
										rowsPerPage={[5, 15, 25, 50, 100, "All"]}
										defaultRowsPerPage={this.state.rowsPerPage}
										onChangeRowsPerPage={(value) => {
											this.setState({ rowsPerPage: value, currentPage: 1 }, () => {
												this.loadData({ rowsPerPage: value, currentPage: 1 })
											})
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		)
	}
}

ViewOtp.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(style))(ViewOtp)
