import React, {Component} from 'react';
import classNames from "classnames";
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import { List, ListItem, ListItemText}  from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge.js";
import { fnumber } from 'components/Helper'
import Config from 'config'
import axios from 'axios';

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import Input from "components/CustomInput/CustomInput.js";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import secureStorage from 'libs/secureStorage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const styles = theme => ({
	...style,
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	sortableList :{
		zIndex : '4',
		'&:hover': {
			backgroundColor: '#0e76a8',
		},
	}
});

const SortableItem = sortableElement(({row,className}) => 
	<ListItem divider className={className}>
			<ListItemText primary={row.Partner.name} />
			<ListItemText primary={row.Partner.code} />
	</ListItem>
);

const SortableContainer = sortableContainer(({children}) => {
	return <List component="nav">{children}</List>;
});

  
class ProductSwitching extends Component {
	constructor(props){
		super(props);
		this.state = {
			product : null,
			fee : 0,
			partner : [],
			partnerDropdown : '',
			productSwitchingList : [],
			payload : '',

			modalShow : false,
			modalTitle : '',
			modalPrice : '',
			modalPsId: '',
		};
	}
	
	componentDidMount(){
		this.loadProduct();
	}

	loadProductSwitching(product){
		const priorityType = this.props.location.priorityType
		axios.post(Config.ApiUrl + '/product/priority',{
				product : product,
				priorityType,
		})
		.then(res => {
				this.setState({productSwitchingList:res.data})
				//console.log(res.data)
		})
	}

	setProductSwitching(productSwitchingList){
		const priorityType = this.props.location.priorityType
			axios.post(Config.ApiUrl + '/product/priority/set',{
					rows : productSwitchingList,
					priorityType,
			})
			.then(res => {
				// console.log(res.data)
					this.setState({productSwitchingList:res.data})
			})
	}

	loadProduct(){
			if(this.props.location.product){
					this.setState({product : this.props.location.product})
					//this.setState({productSwitchingList:this.props.location.product.PartnerFees})
					this.loadProductSwitching(this.props.location.product)
			}
	}

	onSortEnd = ({oldIndex, newIndex}) => {
			let productSwitchingList = arrayMove(this.state.productSwitchingList, oldIndex, newIndex)
			this.setProductSwitching(productSwitchingList)
			// this.setState(({productSwitchingList}) => ({
			// 		productSwitchingList: arrayMove(productSwitchingList, oldIndex, newIndex),
			// }));
			// this.loadProduct()
	};
	
	handleGoBack = () =>{
		secureStorage.setItem('backPayload',this.props.location.backPayload)
		this.props.history.goBack()
	}

	handleModal = (target) =>{
		if(target==='submit'){
			axios.post(Config.ApiUrl + '/product/price/update',{
				psId : this.state.modalPsId,
				price : this.state.modalPrice
			}).then(res=>{
				this.loadProduct()
			}).catch(err=>{

			})
		}
		this.setState({modalShow:false})
	}

	render() {
		const {classes} = this.props;

		if(!this.props.location.product)
			this.props.history.goBack()

		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify='center'>
							<GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
								<Button onClick={this.handleGoBack} color="danger" round><ArrowBackIosIcon />Back</Button>
								<br />
								<h4 className={classes.title}>{this.state.product?this.state.product.name:null}</h4>
							</GridItem>
							<GridItem xl={8} lg={8} md={8} sm={12} xs={12}>
									<Paper className={classes.paper}>
											<h3>{this.state.log}</h3>
											<React.Fragment>
													<div style={{paddingLeft:20,paddingTop:20,paddingBottom:20,paddingRight:20}}>
															<SortableContainer onSortEnd={this.onSortEnd}>
																	{this.state.productSwitchingList.map((row, index) => (
																			<SortableItem key={`item-${row.Partner.name}`} index={index} row={row} distance={1} className={classes.sortableList} />
																	))}
															</SortableContainer>
													</div>
											</React.Fragment>
									</Paper>
							</GridItem>
							<GridItem xs={12}>
								<Table
									tableHead={[
										"Priority",'Partner', "Price",
									]}
									tableData={
										this.state.productSwitchingList.map((row,index)=>(
										[
										index+1,row.Partner.name,
										<span>
											<Badge color="rose">{fnumber(row.price)}</Badge>
											<Button color="info" size="sm" justIcon round onClick={
												(e)=>{
													this.setState({
														modalShow : true,
														modalTitle : `Edit ${row.Partner.name} Price`,
														modalPrice : row.price,
														modalPsId : row.id
													})
												}
											} ><EditIcon /></Button>
										</span>
										]))
									}
									customCellClasses={[
										classes.textCenter,
										classes.textCenter,
										classes.textCenter,
										classes.textRight
									]}
									customClassesForCells={[4, 5, 6,7]}
									customHeadCellClasses={[
										classes.textCenter,
										classes.textCenter,
										classes.textCenter,
										classes.textRight
									]}
									customHeadClassesForCells={[4, 5, 6,7]}
								/>
								</GridItem>
							</GridContainer>
							<Dialog
								classes={{
									root: classes.modalRoot,
									paper: classes.modal
								}}
								open={this.state.modalShow}
								TransitionComponent={Transition}
								keepMounted
								//onClose={() => this.setState({successModal: false})}
								aria-labelledby="classic-modal-slide-title"
								aria-describedby="classic-modal-slide-description"
							>
								<DialogTitle
									id="classic-modal-slide-title"
									disableTypography
									className={classes.modalHeader}
								>
									<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
								</DialogTitle>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<GridContainer justify='center'>
										<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
											<Input
												labelText="Price"
												id="modalPrice"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													placeholder : 'Price',
													value : fnumber(this.state.modalPrice),
													type : 'tel',
													onChange: (e)=>{
														this.setState({
															modalPrice : e.target.value.replace(/\./g, '')
														})
													}
												}}
											/>
										</GridItem>
									</GridContainer>
								</DialogContent>
								<DialogActions className={classes.modalFooter}>
									<Button onClick={()=>this.handleModal('cancel')} color="danger">Cancel</Button>
									<Button onClick={()=>this.handleModal('submit')} color="success">Submit</Button>
								</DialogActions>
							</Dialog>
						</div>
					</div>
				</div>
      );
    }
  }
	ProductSwitching.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(ProductSwitching);
