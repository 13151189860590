/*eslint-disable*/
import React, { isValidElement } from "react";
// @material-ui/core components

import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Phone from '@material-ui/icons/PhoneAndroid'
import Address from "@material-ui/icons/ContactMail"
import ProvinceIcon from "@material-ui/icons/Map"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Close from "@material-ui/icons/Close";
import Clearfix from "components/Clearfix/Clearfix.js";

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Input from "components/CustomInput/CustomInput.js";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/newbg1.jpg";

import {withRouter} from "react-router-dom"
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { RepeatOneSharp } from "@material-ui/icons";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Provinces from "helper/Provinces"
/**
 * Alert Snackbar
 */
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

//libs
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import Config from 'config'
import * as EmailValidator from 'email-validator'

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


class ResetPassword extends React.Component {

	constructor(props){
		super(props);
		this.state = {

			password : '',
			repassword : '',
			alert : false,
			error:[],
			success :[],

			redirect : false,
			
			showModal : false,
			modalTitle : '',
			modalMessage :'',

      isConfirmCodeValid : true

		}
	}
  componentDidMount() {
		//initFb();
		
		this.props.match.params.confirmCode && this.isConfirmCodeValid(this.props.match.params.confirmCode)
		
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
	}

	isConfirmCodeValid(confirmCode){

		axios.post(Config.ApiUrl+ '/user/reset/isvalid',{
      confirmCode,
    }).then(res => {
        this.setState({
          confirmCode: confirmCode,
          isConfirmCodeValid : true
        })
    }).catch(error => {
      this.setState({
        isConfirmCodeValid : false
      })
        console.log(error)
    })
	}
	reset(){
		const payload = {
			password : this.state.password,
			confirmCode : this.state.confirmCode,
		}
    // console.log(Config.ApiUrl+ 'user/reset/change',payload)
		axios.post(Config.ApiUrl+ '/user/reset/change',payload)
		.then(res => {
				//console.log(res.data)
				
			this.setState({
				showModal : true,
				modalTitle : 'Sukses',
				modalMessage : res.data
			})
		}).catch(err => {
			if(err.response){
				this.setState({
					showModal : true,
					modalTitle : 'Gagal',
					modalMessage : err.response.data
				})
			}
			else{
				this.setState({
					showModal : true,
					modalTitle : 'Gagal',
					modalMessage : 'Koneksi jaringan terputus'
				})
			}
		});
	}

	//#region Event Handler

	handleRedirect = (e) =>{
		if(this.state.modalTitle === 'Sukses')
			this.setState({redirect:true})
		else
			this.setState({showModal:false})
	}
	handleClose = (e) =>{
		this.setState({showModal:false})
	}
	
	handleInput = (e) => {
		//alert(e.id)
		switch(e.target.id){
			case 'password':
				this.setState({password:e.target.value})
				break
			case 'repassword':
				this.setState({repassword: e.target.value})
				if(e.target.value === this.state.password){
					this.setState({
						success:{...this.state.success,[e.target.id]: true},
						error:{...this.state.error,[e.target.id]: false}
					})
				}
				else if(e.target.value !== this.state.password)
					this.setState({error:{...this.state.error,[e.target.id]: true}})
				else
					this.setState({success:{...this.state.success,[e.target.id]: false}})
				break
			default :
		}
		// if(e.target.value.length<this.state.password.length || e.target.id !== "repassword")
		// 	this.setState({error:{...this.state.error,[e.target.id]: false}})
	}
	handleBlur = (e) =>{
		
		if(e.target.value){
			switch(e.target.id){
				default:
					this.setState({
						error:{...this.state.error,[e.target.id]: false},
						success:{...this.state.success,[e.target.id]: true}
					})
			}
		}
		else{
			this.setState({
				success:{...this.state.success,[e.target.id]: false},
				error:{...this.state.error,[e.target.id]: true}
			})
		}
		//console.log(this.state.error)
	}
	handleSignUp = (e) =>{
		//this.setState({showModal:true})
		let error = []

		if(!this.state.password)
			error.password = true
			
		if(!this.state.repassword)
			error.repassword = true
		this.setState({error:{...error}})
		//console.log(this.state.error)
		//console.log(this.state.fullname, this.state.phone, this.state.email, this.state.address, this.state.province, this.state.password, this.state.repassword, this.state.checked.length)
		if(  !error.password && !error.repassword ){
			// console.log('register',this.state.province)
			this.reset();
		}
		else{
			this.setState({alert:true})
		}
	}

	handleAlertExit = () =>{
		this.setState({alert:false})
	}
	
  render(){
		
		if( !this.state.isConfirmCodeValid || this.state.redirect){
			return <Redirect to="/" />
		}
		const {classes} = this.props;
		
		return (
			<div>
				<div
					className={classes.pageHeader}
					style={{
						backgroundImage: "url(" + image + ")",
						backgroundSize: "cover",
						backgroundPosition: "top center"
					}}
				>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={10} md={10}>
								<Card className={classes.cardSignup}>
									<h2 className={classes.cardTitle} >Reset Password</h2>
									<CardBody>
										<GridContainer justify="center">
											<GridItem xs={12} sm={12} md={12}>
												<form className={classes.form}>
													
													<Input
														error={this.state.error?this.state.error.password:false}
														success={this.state.success?this.state.success.password:false}
														id="password"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Icon className={classes.inputAdornmentIcon}>
																		lock_outline
																	</Icon>
																</InputAdornment>
															),
															placeholder: "Password...",
															type:"password",
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Password"
													/>
													<Input
														error={this.state.error?this.state.error.repassword:false}
														success={this.state.success?this.state.success.repassword:false}
														id="repassword"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Icon className={classes.inputAdornmentIcon}>
																		lock_outline
																	</Icon>
																</InputAdornment>
															),
															placeholder: "Re-type Password...",
															type:"password",
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Re-Password"
													/>
													{
														this.state.alert?
															<SnackbarContent
															message={
																<span>
																	Semua kolom harus diisi
																</span>
															}
															onExited = {this.handleAlertExit}
															close
															color="danger"
															icon="info_outline"
														/>:null
													}
													<div>
														<div className={classes.textCenter}>
															<Button round color="instagram" onClick={this.handleSignUp}
															  disabled={ !this.state.error.password && !this.state.error.repassword ? false : true }
															>
																submit
															</Button>
														</div>
														<Dialog
															classes={{
																root: classes.modalRoot,
																paper: classes.modal
															}}
															open={this.state.showModal}
															TransitionComponent={Transition}
															keepMounted
															//onClose={() => this.setState({showModal: false})}
															aria-labelledby="classic-modal-slide-title"
															aria-describedby="classic-modal-slide-description"
														>
															<DialogTitle
																id="classic-modal-slide-title"
																disableTypography
																className={classes.modalHeader}
															>
																<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
															</DialogTitle>
															<DialogContent
																id="classic-modal-slide-description"
																className={classes.modalBody}
															>
																<p>{this.state.modalMessage}</p>
															</DialogContent>
															<DialogActions className={classes.modalFooter}>
																	<Button round onClick={this.handleRedirect} color="success">OK</Button>
															</DialogActions>
														</Dialog>
													</div>
												</form>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								
							</GridItem>
						</GridContainer>
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}
ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(ResetPassword));