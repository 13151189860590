module.exports = {
	user: "User",
	userList: "Daftar User",
	payment: "Pembayaran",
	ViewOtp: "Daftar OTP",
	verCode: "Kode User",

	billing: "Tagihan Berjalan",

	transaction: "Transaksi",
	entry: "Catatan",
	history: "Riwayat",
	byItem: "Rekap",

	balance: "Saldo",
	balanceMutation: "Mutasi Saldo",

	bill: "Tagihan",
	due: "Jatuh Tempo",

	settings: "Pengaturan",
	changePassword: "Ganti Password",

	login: "Masuk",
	logout: "Keluar",

	name: "Nama",
	phone: "Telp",
	userType: "Jenis User",

	actions: "Aksi",
	search: "Cari",
	copy: "Salin",

	oldPassword: "Password Lama",
	newPassword: "Password Baru",
	newPasswordConfirmation: "Konfirmasi Password Baru",
	type: "Masukkan",
	save: "Simpan",

	"No Bill Available": "Tagihan tidak tersedia",
	dueAmount: "Jumlah Tagihan",
	dueDate: "Tanggal Jatuh Tempo",
	uniqueCode: "Kode Unik",
	bankAccount: "Rekening",
	remainingAmount: "Sisa Tagihan",
	splitAmount: "Rupiah Terbagi",

	Price: "Harga",
	Revenue: "Pendapatan",
	Remark: "Keterangan",
	UserType: "JenisUser",
	Amount: "Nominal",
	Date: "Tanggal",
	Name: "Nama",

	price: "Harga",
	revenue: "Pendapatan",
	buyingPrice: "Harga Beli",
	sellingPrice: "Harga Jual",
	category: "Kategori",
	mutation: "Mutasi",

	back: "Kembali",
	ceiling: "Plafon",
	viewBy: "Lihat berdasarkan",
	view: "Lihat",
	date: "Tanggal",
	month: "Bulan",
	year: "Tahun",
	fromToDate: "Dari - S.d (Tanggal)",
	fromToDatetime: "Dari - S.d (Tanggal & Waktu)",
	from: "Dari",
	to: "Sampai",

	January: "Januari",
	February: "Februari",
	March: "Maret",
	May: "Mei",
	June: "Juni",
	July: "Juli",
	August: "Agustus",
	October: "Oktober",
	December: "Desember",
	remark: "Keterangan",
	transactionLog: "Log Transaksi",
	transactionPartner: "Transaksi Biller",
	Partner: "Biller",

	cancel: "Batal",
	billHistory: "Riwayat Tagihan",
	paymentHistory: "Riwayat Pembayaran",
	paidAmount: "Jumlah Terbayar",

	Ready: "Tersedia",
	"Request Pembayaran": "Request Pembayaran",
	"Terbayar Sebagian": "Terbayar Sebagian",
	Paid: "Lunas",
	Done: "Selesai",
	Outstanding: "Oudstanding",
	Splitted: "Terbagi",
	billDetail: "Detail Tagihan",
	splitBill: "Bagi Tagihan",
	deduct: "Tarik",
	add: "Tambahkan",

	accountName: "Atas Nama",
	accountNo: "No Rekening",
	bankName: "Nama Bank",

	show: "Tampilkan",
	"Are you sure want to": "Apakah anda yakin",
	delete: "Hapus",
	edit: "Ubah",

	"Total Amount": "Total Nominal",
	"Balance Mutation": "Mutasi Saldo",
	"IN/OUT": "MASUK/KELUAR",
	"FROM/TO": "Dari/Ke",
	in: "Masuk",
	out: "Keluar",
	"Rows per page": "Baris per halaman",
	of: "dari",
	All: "Semua",
	"Daily Transaction": "Transaksi Harian",
	Others: "Lain-lain",
	close: "Tutup",
	UnqAmount: "Nominal Uniq",
	Confirm: "Konfirmasi",
	forgetPass: "Lupa Password ?",
	enterEmailOrPass: "Masukkan Email atau No HP",
	emailOrPhone: "Email/No HP",
	feeTemplate: "Fee Template",
	"Fee Templates": "Fee Templates",
	"Template Name": "Nama Template",
	"No available templates": "Tidak ada template",
	deposit: "Deposit Murni",
	dispensation: "Dispensasi",
	Remaining: "Tersisa",
}
