// cores
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

// icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

// UI components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Input from "components/CustomInput/CustomInputTicket.js";
import Tooltip from "@material-ui/core/Tooltip";

import { Dimmer, Loader, Segment, Label } from "semantic-ui-react";

// libs
import Config from "config";
import axios from "axios";
import { fnumber } from "components/Helper";

// styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const CustomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const styles = (theme) => ({
  ...style,
});

class AdminBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: "",
      amount: 0,

      outstanding: "",
      disabledBalanceOutstanding: true,

      modalShow: false,
      modalTitle: "",

      isLoading: false,
    };

    this.balanceLimitRef = React.createRef();
  }

  componentDidMount() {
    this.loadBalance();
    this.loadBalanceOutstanding();
  }

  loadBalance() {
    this.setState({ isLoading: true });
    axios
      .post(Config.ApiUrl + "/user/balance")
      .then((res) => {
        this.setState({
          balance: res.data.balance,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  loadBalanceOutstanding() {
    axios
      .post(`${Config.ApiUrl}/user/balance/outstanding`)
      .then((res) => {
        console.log(res.data);
        this.setState({
          isLoading: false,
          outstanding: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  transferBalance = () => {
    const payload = {
      action: this.state.modalTitle === "Add Balance" ? "deduct" : "transfer",
      remark: this.state.modalTitle,
      amount: this.state.amount,
    };
    if (this.state.amount) {
      axios
        .post(`${Config.ApiUrl}/user/balance/${payload.action}`, payload)
        .then((res) => {
          console.log(res.data);
          this.setState({
            balance: res.data.balance,
            amount: 0,
            modalShow: false,
          });
          this.loadBalance();
          // eslint-disable-next-line react/prop-types
          this.props.onBalanceChange();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  setBalanceOutstanding = () => {
    const payload = {
      amount: this.state.outstanding,
    };

    if (this.state.outstanding) {
      axios
        .post(`${Config.ApiUrl}/user/balance/outstanding/set`, payload)
        .then((res) => {
          console.log(res.data);
          this.setState({
            disabledBalanceOutstanding: true,
            modalShow: false,
          });

          this.loadBalanceOutstanding();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ disabledBalanceOutstanding: true });
        });
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, t } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            {/* Search Action Component>> */}

            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="big">Admin Balance</Label>
                  </GridItem>
                  <GridItem xl={9} lg={9} md={9} sm={12} xs={12}></GridItem>
                </GridContainer>
              </GridItem>
              {/* Input balance user / admin */}
              <GridItem
                xl={12}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <Input
                    labelText="Balance"
                    id="balance"
                    formControlProps={{
                      style: { width: "250px" },
                    }}
                    inputProps={{
                      value: fnumber(this.state.balance),
                      type: "tel",
                      readOnly: true,
                    }}
                    style={{ float: "left", overflow: "hidden" }}
                  />
                  <div
                    className={classNames(classes.buttonGroup, classes.buttons)}
                    style={{ marginTop: 20 }}
                  >
                    <Tooltip
                      id="tooltip-search"
                      title="Add Balance"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        size="sm"
                        round
                        onClick={() =>
                          this.setState({
                            modalShow: true,
                            modalTitle: "Add Balance",
                          })
                        }
                        className={classes.firstButton}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-clear"
                      title="Deduct Balance"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="danger"
                        size="sm"
                        round
                        onClick={() =>
                          this.setState({
                            modalShow: true,
                            modalTitle: "Deduct Balance",
                          })
                        }
                        className={classes.lastButton}
                      >
                        <RemoveIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </Segment>
              </GridItem>
              {/* Input balance limit / outstanding balance  */}
              <GridItem
                xl={12}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <div>
                  <Input
                    labelText={t("outstandingBalance")}
                    id="balanceOutstanding"
                    formControlProps={{
                      style: { width: "250px" },
                    }}
                    inputProps={{
                      value: fnumber(this.state.outstanding),
                      type: "tel",
                      readOnly: this.state.disabledBalanceOutstanding,
                      onChange: (e) => {
                        const valStr = e.target.value.replace(/\./g, "");
                        let value =
                          isNaN(valStr) || valStr === "" ? 0 : parseInt(valStr);
                        if (value < 0) value = 0;
                        this.setState({ outstanding: value });
                      },
                      inputRef: this.balanceLimitRef,
                    }}
                    style={{ float: "left", overflow: "hidden" }}
                  />
                  {this.state.disabledBalanceOutstanding ? (
                    <Button
                      style={{ marginTop: 25 }}
                      color="warning"
                      size="sm"
                      justIcon
                      round
                      onClick={() => {
                        this.setState(
                          { disabledBalanceOutstanding: false },
                          () => {
                            this.balanceLimitRef.current &&
                              this.balanceLimitRef.current.focus();
                          }
                        );
                      }}
                    >
                      <EditIcon />
                    </Button>
                  ) : (
                    <Button
                      style={{ marginTop: 25 }}
                      color="facebook"
                      size="sm"
                      justIcon
                      round
                      onClick={this.setBalanceOutstanding}
                    >
                      <SaveIcon />
                    </Button>
                  )}
                </div>
              </GridItem>
            </GridContainer>

            {/* Modal >> */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={CustomTransition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer justify="center">
                  <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      labelText="Amount"
                      id="amount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Amount",
                        value: fnumber(this.state.amount),
                        onChange: (e) => {
                          let val = e.target.value.replace(/\./g, "");
                          val = isNaN(val) || !val ? 0 : parseInt(val);
                          if (val < 0) val = 0;
                          this.setState({ amount: val });
                        },
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            this.transferBalance();
                          }
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.setState({ modalShow: false, amount: 0 })}
                  color="warning"
                  round
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.transferBalance}
                  color={
                    this.state.modalTitle === "Add Balance"
                      ? "success"
                      : "danger"
                  }
                  round
                  disabled={this.state.remark === ""}
                >
                  {this.state.modalTitle === "Add Balance" ? (
                    <span>
                      <AddIcon />
                      Add
                    </span>
                  ) : (
                    <span>
                      <RemoveIcon />
                      Deduct
                    </span>
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            {/* << Modal */}
          </div>
        </div>
      </div>
    );
  }
}
AdminBalance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(style))(AdminBalance);
