import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  roseColor,
  secondaryColor,
  blackColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  instagramColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const paginationStyle = {
  pagination: {
    display: "flex",
    // paddingRight: "0",
    listStyle: "none",
    borderRadius: "0.25rem"
  },
  paginationItem: {
    display: "inline"
  },
  paginationLink: {
    ":first-of-type": {
      marginleft: "0"
    },
    letterSpacing: "unset",
    border: "0",
    borderRadius: "30px !important",
    transition: "all .3s",
    padding: "0px 11px",
    margin: "0 3px",
    minWidth: "30px",
    height: "30px",
    minHeight: "auto",
    lineHeight: "30px",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    background: "transparent",
    position: "relative",
    float: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    "&,&:hover,&:focus": {
      color: grayColor[0]
    },
    "&:hover,&:focus": {
      zIndex: "3",
      backgroundColor: grayColor[2],
      borderColor: grayColor[6]
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  primary: {
    "&,&:hover,&:focus": {
      backgroundColor: primaryColor[0],
      borderColor: primaryColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  info: {
    "&,&:hover,&:focus": {
      backgroundColor: infoColor[0],
      borderColor: infoColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  success: {
    "&,&:hover,&:focus": {
      backgroundColor: successColor[0],
      borderColor: successColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  warning: {
    "&,&:hover,&:focus": {
      backgroundColor: warningColor[0],
      borderColor: warningColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  danger: {
    "&,&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      borderColor: dangerColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  roseColor: {
    "&,&:hover,&:focus": {
      backgroundColor: roseColor[0],
      borderColor: roseColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  secondaryColor: {
    "&,&:hover,&:focus": {
      backgroundColor: secondaryColor[0],
      borderColor: secondaryColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(secondaryColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(secondaryColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(secondaryColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  blackColor: {
    "&,&:hover,&:focus": {
      backgroundColor: blackColor,
      borderColor: blackColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  twitterColor: {
    "&,&:hover,&:focus": {
      backgroundColor: twitterColor,
      borderColor: twitterColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(twitterColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(twitterColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(twitterColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  facebookColor: {
    "&,&:hover,&:focus": {
      backgroundColor: facebookColor,
      borderColor: facebookColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(facebookColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(facebookColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(facebookColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  googleColor: {
    "&,&:hover,&:focus": {
      backgroundColor: googleColor,
      borderColor: googleColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(googleColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(googleColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(googleColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  linkedinColor: {
    "&,&:hover,&:focus": {
      backgroundColor: linkedinColor,
      borderColor: linkedinColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(linkedinColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(linkedinColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(linkedinColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  pinterestColor: {
    "&,&:hover,&:focus": {
      backgroundColor: pinterestColor,
      borderColor: pinterestColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(pinterestColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(pinterestColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(pinterestColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  youtubeColor: {
    "&,&:hover,&:focus": {
      backgroundColor: youtubeColor,
      borderColor: youtubeColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(youtubeColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(youtubeColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(youtubeColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  tumblrColor: {
    "&,&:hover,&:focus": {
      backgroundColor: tumblrColor,
      borderColor: tumblrColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(tumblrColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(tumblrColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(tumblrColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  behanceColor: {
    "&,&:hover,&:focus": {
      backgroundColor: behanceColor,
      borderColor: behanceColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(behanceColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(behanceColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(behanceColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  dribbbleColor: {
    "&,&:hover,&:focus": {
      backgroundColor: dribbbleColor,
      borderColor: dribbbleColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(dribbbleColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(dribbbleColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(dribbbleColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  instagramColor: {
    "&,&:hover,&:focus": {
      backgroundColor: instagramColor,
      borderColor: instagramColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(instagramColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(instagramColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(instagramColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  redditColor: {
    "&,&:hover,&:focus": {
      backgroundColor: redditColor,
      borderColor: redditColor,
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(redditColor) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(redditColor) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(redditColor) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  disabled: {
    "&,&:hover,&:focus": {
      color: grayColor[10],
      cursor: "not-allowed",
      backgroundColor: whiteColor,
      borderColor: grayColor[6]
    }
  }
};

export default paginationStyle;
