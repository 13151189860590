//core
import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

//ui-core
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//ui-icons
import Copy from "@material-ui/icons/FileCopy"
import RefreshIcon from '@material-ui/icons/Refresh';

//ui-components
import Button from "components/CustomButtons/Button"
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Form, TextArea,Dimmer, Loader,Segment,Label,Transition,Confirm } from 'semantic-ui-react'

//style
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";



//libs
import Config from 'config'
import axios from 'axios';
import secureStorage from 'libs/secureStorage';

const styles = theme => ({
	...style,
	btnCopy : {
		marginTop : 20
	},
	grid :{
		textAlign : 'center'
	}
});

class GetToken extends React.Component{
	constructor(props){
		super();
		this.state = {
			prodToken : '',
			sandToken : '',
			prodIsLoading:true,
			sandIsLoading:true,
			tokenType:'sand',
			prodOpen:false,
			sandOpen:false,
		}
		this.InputProd = React.createRef()
		this.InputSand = React.createRef()
	}
	componentDidMount(){
		const user = secureStorage.getItem('user')
		user.isVerified && this.getToken('prod')
		this.getToken('sand')
	}
	getToken = (tokenType)=>{
		this.setState({[tokenType+'IsLoading']:true})
		axios.post(`${Config.ApiUrl}/user/token/get`,{tokenType})
		.then(res => {
			this.setState({
				[tokenType+'Token']:res.data.token,
				[tokenType+'IsLoading']:false
			})
		}).catch(err => {
			if(err.response && err.response.status===400){
				console.log({err})
				this.refreshToken()
			}
		});
	}
	refreshToken = (tokenType)=>{
		this.setState({[tokenType+'IsLoading']:true})
		axios.post(Config.ApiUrl+'/user/token/refresh',{tokenType})
		.then(res => {
			this.setState({
				[tokenType+'Token']:res.data.token,
				[tokenType+'IsLoading']:false
			})
		}).catch(err => {
			console.log(err)
		});
	}

	handleClick = (target) =>{
		
		if(target==='copy'){
			this.input.select()
			document.execCommand('copy')
			this.setState({open:true})
		}
		else if(target==='refresh'){
			this.refreshToken()
		}
	}
	handleTooltipClose = (e) => {
			this.setState({open:false})
	}
	handleLpTooltipClose = (e) => {
			this.setState({lpOpen:false})
	}
	handleDpTooltipClose = (e) => {
		this.setState({dpOpen:false})
	}

	render(){
		const {classes} = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
					
						<GridContainer justify="center">
							{user.isVerified &&
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
												<Label ribbon color='blue' size='huge' raised='true'>API Token</Label>
											</GridItem>
											<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
												
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={7} xs={7} className={classes.grid}>
										<input style={{position:'absolute',left:'-5000px',top:'-5000px'}} visible='false' defaultValue={this.state.prodToken} ref={this.InputProd} />
										<Dimmer.Dimmable as={Segment} blurring dimmed={this.state.prodIsLoading} raised>
											<Dimmer active={this.state.prodIsLoading}>
												<Loader />
											</Dimmer>
											<Form>
												<TextArea placeholder='Token' defaultValue={this.state.prodToken} style={{minHeight:100,resize:'none'}} readOnly />
											</Form>
										</Dimmer.Dimmable>
									</GridItem>
									<GridItem xl={2} lg={2} md={2} sm={5} xs={5} className={classes.grid}>
										<ClickAwayListener onClickAway={()=>this.setState({prodOpen:false})}>
											<Tooltip
												PopperProps={{
												disablePortal: true,
												}}
												onClose={()=>this.setState({prodOpen:false})}
												open={this.state.prodOpen}
												disableFocusListener
												disableHoverListener
												disableTouchListener
												placement="top-end"
												title="Copied"
											>
												<Button color="instagram" id='copy' onClick={(e)=>{
													this.InputProd.current.select()
													document.execCommand('copy')
													this.setState({prodOpen:true})
													setTimeout(()=>{
														this.setState({prodOpen:false})
													},1000)
												}} className={classes.btnCopy} disabled={this.state.prodIsLoading} round><Copy /> Copy</Button>
											</Tooltip>
										</ClickAwayListener>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={7} xs={7} className={classes.grid}>
										<Button color="success" id='refreshtoken' onClick={(e)=>this.setState({modalShow:true,tokenType:'prod'})} style={{marginTop:20}} disabled={this.state.prodIsLoading} round><RefreshIcon />Get New Token</Button>
									</GridItem>
									<GridItem xl={2} lg={2} md={2} sm={5} xs={5} className={classes.grid}>
										
									</GridItem>
								</GridContainer>											
							</GridItem>}
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
												<Label ribbon color='orange' size='huge' raised='true'>Sandbox Token</Label>
											</GridItem>
											<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
												
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={7} xs={7} className={classes.grid}>
										<input style={{position:'absolute',left:'-5000px',top:'-5000px'}} visible='false' defaultValue={this.state.sandToken} ref={this.InputSand} />
										<Dimmer.Dimmable as={Segment} blurring dimmed={this.state.sandIsLoading} raised>
											<Dimmer active={this.state.sandIsLoading}>
												<Loader />
											</Dimmer>
											<Form>
												<TextArea placeholder='Token' defaultValue={this.state.sandToken} style={{minHeight:100,resize:'none'}} readOnly />
											</Form>
										</Dimmer.Dimmable>
									</GridItem>
									<GridItem xl={2} lg={2} md={2} sm={5} xs={5} className={classes.grid}>
										<ClickAwayListener onClickAway={()=>this.setState({sandOpen:false})}>
											<Tooltip
												PopperProps={{
												disablePortal: true,
												}}
												onClose={()=>this.setState({sandOpen:false})}
												open={this.state.sandOpen}
												disableFocusListener
												disableHoverListener
												disableTouchListener
												placement="top-end"
												title="Copied"
											>
												<Button color="instagram" id='copy' onClick={(e)=>{
													this.InputSand.current.select()
													document.execCommand('copy')
													this.setState({sandOpen:true})
													setTimeout(()=>{
														this.setState({sandOpen:false})
													},1000)
												}} className={classes.btnCopy} disabled={this.state.sandIsLoading} round><Copy /> Copy</Button>
											</Tooltip>
										</ClickAwayListener>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={7} xs={7} className={classes.grid}>
										<Button color="success" id='refreshtoken' onClick={(e)=>this.setState({modalShow:true,tokenType:'sand'})} style={{marginTop:20}} disabled={this.state.sandIsLoading} round><RefreshIcon />Get New Token</Button>
									</GridItem>
									<GridItem xl={2} lg={2} md={2} sm={5} xs={5} className={classes.grid}>
										
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<Transition.Group animation='drop' duration={200}>
							{this.state.modalShow && 
							<Confirm
								dimmer='blurring'
								open={this.state.modalShow}
								header='Cautions !'
								content={`Generating new ${this.state.tokenType==='prod'?'API':'Sandbox'} Token will revoke your previous ${this.state.tokenType==='prod'?'API':'Sandbox'} Token.`}
								cancelButton='Cancel'
								confirmButton="Confirm"
								onCancel={() => this.setState({modalShow:false})}
								onConfirm={() =>{ 
									this.setState({modalShow:false})
									// this.handleClick('refresh')
									this.refreshToken(this.state.tokenType)
								}}
							/>}
						</Transition.Group>
					</div>
				</div>
			</div>
		)
	}
}
GetToken.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GetToken);