import React from 'react'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
	grayColor,
  primaryBoxShadow,
  coloredShadow,
} from "assets/jss/material-kit-pro-react"

import {Label} from 'semantic-ui-react'

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import Transaction from './Transaction';
import TransactionPie from './TransactionPie';
import TransactionByDate from './TransactionByDate';

const styles = {
	...style,
	'&.apexcharts-canvas': {
		// Tooltip
		'.apexcharts-xaxistooltip': {
			backdropFilter: 'blur(6px)',
			WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
			backgroundColor: primaryBoxShadow,
			border: 0,
			boxShadow: coloredShadow,
			color: grayColor[0],
			borderRadius: 5,
			'&:before': { borderBottomColor: 'transparent' },
			'&:after': { borderBottomColor: primaryBoxShadow }
		},
		'.apexcharts-tooltip.apexcharts-theme-light': {
			backdropFilter: 'blur(6px)',
			WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
			backgroundColor: primaryBoxShadow,
			border: 0,
			boxShadow: coloredShadow,
			borderRadius: 5,
			'& .apexcharts-tooltip-title': {
				border: 0,
				textAlign: 'center',
				fontWeight: 5,
				backgroundColor: grayColor[3],
				color: 'primary'
			}
		},
		// Legend
		'.apexcharts-legend': {
			padding: 0
		},
		'.apexcharts-legend-series': {
			display: 'flex !important',
			alignItems: 'center'
		},
		'.apexcharts-legend-marker': {
			marginRight: 8
		},
		'.apexcharts-legend-text': {
			lineHeight: '18px',
			textTransform: 'capitalize'
		}
	}
}

class Bill extends React.Component{
	constructor(props){
		super()
		this.state = {
			
		}
	}
	componentDidMount(){

	}
	
	render(){
		const {classes} = this.props;
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={2} sm={12} xs={12} className={classes.grid}>
								<Label size='huge'>Dashboard</Label>
							</GridItem>
							<GridItem xl={8} lg={8} md={8} sm={12} xs={12} style={{marginTop:20}}>
								<TransactionByDate />
							</GridItem>
							<GridItem xl={4} lg={4} md={4} sm={12} xs={12} style={{marginTop:20}}>
								<TransactionPie />
							</GridItem>
							<GridItem xl={8} lg={8} md={8} sm={12} xs={12} style={{marginTop:20}}>
								<Transaction />
							</GridItem>
							<GridItem xl={4} lg={4} md={4} sm={12} xs={12} style={{marginTop:20}}></GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		)
	}
  
}

Bill.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withTranslation(), withStyles(styles))(Bill);

// const split = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]