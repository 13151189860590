import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

import Config from 'config'
import axios from 'axios';
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "components/CustomInput/CustomInput.js";

import Icon from "@material-ui/core/Icon";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import secureStorage from "libs/secureStorage"

const style = theme => ({
	...contactUsStyle,
	buttons:{
		marginTop:'25px'
	}
})
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class ChangePassword extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			oldPass : '',
			newPass : '',
			newPassConf :'',
			confirmationModal : false,
			confirmationMessage : '',
		}
	}
	componentDidMount(){
	}
	save = () =>{
		if(!this.state.newPass){
			this.setState({
				confirmationMessage:'New Password can not be empty',
				confirmationModal : true,
			})
		}
		else if(this.state.newPass !== this.state.newPassConf){
			this.setState({
				confirmationMessage:'Re-type New Password did not match',
				confirmationModal : true,
			})
		}
		else{
			const user = secureStorage.getItem('user')
			const url = user.LoginUsers ? '/user/changepass/lu':'/user/changepass'
			axios.post(Config.ApiUrl+url,{
				oldPassword : this.state.oldPass,
				newPassword : this.state.newPass
			})
			.then(res=>{
				this.setState({
					confirmationMessage:'Password has been changed',
					confirmationModal : true,
					newPass : '',
					newPassConf : '',
					oldPass : ''
				})
			}).catch(err=>{
				if(err.response){
					this.setState({
						confirmationMessage:err.response.data,
						confirmationModal : true,
					})
				}
				else{
					this.setState({
						confirmationMessage:'Connection problem',
						confirmationModal : true,
					})
				}
			})
		}
	}
	handleSave = e => {
		this.save()
	}
	handleChange = e => {
		if(e.target.id === 'oldPass'){
      this.setState({ oldPass: e.target.value });
    }
    else if(e.target.id === 'newPass'){
      this.setState({ newPass: e.target.value });
    }
    else if(e.target.id === 'newPassConf'){
      this.setState({ newPassConf: e.target.value });
		}
		
  };
	handleKeydown = (e) =>{
    if (e.key === 'Enter')
      this.save()
	}
	handleModal = (e) => {
		this.setState({confirmationModal : false})
	}
	render(){
		const {classes,t} = this.props;
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center" style={{marginTop:50}}>
							<GridItem xl={2} lg={2} md={2} sm={12} xs={12}>
								<h4 className={classes.title}>{t('changePassword')}</h4>
							</GridItem>
							<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={8} lg={8} md={12} sm={12} xs={12}>
										<Input
											labelText={t('oldPassword')}
											id="oldPass"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "password",
												placeholder : `${t('type')} ${t('oldPassword')}`,
												value : this.state.oldPass,
												onChange:this.handleChange,
												startAdornment: (
													<InputAdornment position="start">
														<InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									<GridItem xl={8} lg={8} md={12} sm={12} xs={12}>
										<Input
											labelText={t('newPassword')}
											id="newPass"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "password",
												placeholder : `${t('type')} ${t('newPassword')}`,
												value : this.state.newPass,
												onChange:this.handleChange,
												startAdornment: (
													<InputAdornment position="start">
														<InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									<GridItem xl={8} lg={8} md={12} sm={12} xs={12}>
										<Input
											labelText={t('newPasswordConfirmation')}
											id="newPassConf"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "password",
												placeholder : `${t('type')} ${t('newPassword')}`,
												value : this.state.newPassConf,
												onChange:this.handleChange,
												onKeyDown:this.handleKeydown,
												startAdornment: (
													<InputAdornment position="start">
														<InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									<GridItem xl={8} lg={8} md={12} sm={12} xs={12} style={{textAlign:'center'}}>
										<Button round onClick={this.handleSave} color="facebook" size="lg">
                      {t('save')}
                    </Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</div>
					<Dialog
						classes={{
							root: classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.confirmationModal}
						TransitionComponent={Transition}
						keepMounted
						//onClose={() => this.setState({successModal: false})}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
							className={classes.modalHeader}
						>
							<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
						</DialogTitle>
						<DialogContent
							id="classic-modal-slide-description"
							className={classes.modalBody}
						>
							{this.state.confirmationMessage}
							
						</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button onClick={()=>this.handleModal('confirm')} color="instagram">Ok</Button>
							</DialogActions>
					</Dialog>
        </div>
      </div>
			)
		}
}
ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(),withStyles(style))(ChangePassword);