import React from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// core components

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import { Redirect } from "react-router-dom";

import axios from 'axios'
import Config from 'config'

import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { secureStorage } from "libs"

const styles = {
	...style,
}

class DashboardLink extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			validLink : false,
      isLoading : true,
		}
	}
	componentDidMount(){
		const hash = this.props.match.params.hash
    this.verifyLink({hash})
	}
  verifyLink = ({hash}) =>{

		const payload = {
			hash
		}
		// console.log(payload)
		axios.post(Config.ApiUrl + '/user/dashboardlink',payload)
		.then(res => {
				// console.log(res.data)
        // setSecretKey(res.data.token)
        secureStorage.setItem('token',res.data.token)
        secureStorage.setItem('user', res.data.user)
				this.setState({
					isLoading:false
				})

		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}
	
	render(){
    if(!this.state.isLoading)
      return <Redirect to='/Dashboard' />
		return (
			<Segment vertical>
      <Dimmer active={this.state.isLoading} inverted>
        <Loader />
      </Dimmer>
      </Segment>
		)
	}
  
}

DashboardLink.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DashboardLink);