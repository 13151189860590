import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import { Redirect} from "react-router-dom";

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// UI Components
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Dimmer, Loader, Segment,Label, Dropdown,Divider, Header, Icon,Input as InputSemantic } from 'semantic-ui-react'

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

//styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

// Libs
import Config from 'config'
import axios from 'axios';
import secureStorage from 'libs/secureStorage'

//Icons
import { Add, ArrowBackIos, Cancel, DeleteForever, Publish, Save,Build } from '@material-ui/icons';

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Badge from 'components/Badge/Badge';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

import { HexColorPicker } from "react-colorful"
import { ClickAwayListener } from '@material-ui/core';

import  LinkMui  from "@material-ui/core/Link"

import DefaultPicture from 'assets/img/app-default.png'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'


import _ from 'lodash'

const style = theme => ({
  ...modalStyle(theme),
	...contactUsStyle,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
	colorPicker:{
		display:'flex',
		flexDirection: 'row',
  	flexWrap: 'nowrap',
	},
	colorContainer:{
		position:'relative',
		width:40,
		height:'auto',
		marginLeft:10,
	},
	colorRect:{
		width:'100%',
		height:'100%',
		borderWidth:1,
		borderColor:'#000',
		borderRadius:5,
		position:'absolute',
		border:'solid',
		top:0,
		left:0,
	},
	applicationImage:{
    float: 'left',
    overflow: 'hidden',
    borderRadius: "5% !important",
    width: '100px',
    height: '100px',
    margin : "0 10px 10px 20px"
  },
	imgContainer:{
		borderRadius: "50px",
    lineHeight: "20px",
    margin: "0px",
    marginTop:'-10px',
    paddingTop:'10px',
    paddingRight:'10px',
    display: "inline-flex",
    color: "inherit",
    position: "relative",
		textALign:'center',
		'& svg': {
			position: 'absolute',
			top: '50px',
			right: '45px',
			zIndex: '1',
			width: '50px !important',
			height: '50px !important',
			color:'transparent',
    },
    "&:hover,&:focus": {
      background: "rgba(11, 127, 171, 0.2)",
			'& svg': {
				color:'#4b77be',
				alpha:'0.5',
			},
    },
	},
	uploadErrMsg:{
		color:'red'
	},
	carouselContainer:{
		marginTop:50,
		border:'solid',
		borderColor:'#000',
		borderWidth:1,
		borderRadius:5,
		backgroundColor:'#C0C0C0',
		filter: 'drop-shadow(0 2px 2px #1a1a1a)'},
})

const CustomTransition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});


class AppSetting extends React.Component{
	constructor(props){
		super(props);
		this.state = {
				
			rows : [],
			
			selectedGroups:[],
			selectedGroupsVal:[],
			name:'',

			groups:[],

			addButtonDisabled : true,
			buildButtonDisabled : false,
			isLoading:false,
      modalShow:false,
      modalTitle:'',
			selectedDomain:this.props.location.selectedDomain,
			domainName:this.props.location.selectedDomain && this.props.location.selectedDomain.name,

			appName : this.props.location.selectedDomain && (this.props.location.selectedDomain.appName ?? ''),
			shortName : this.props.location.selectedDomain && (this.props.location.selectedDomain.shortName??''),
			splashColor : this.props.location.selectedDomain && (this.props.location.selectedDomain.splashColor??'#ffffff'),
			bannerColor : this.props.location.selectedDomain && (this.props.location.selectedDomain.bannerColor??'#125688'),

			splashColorShow : false,
			bannerColorShow : false,

			applicationImage : DefaultPicture,

			saveButtonDisabled:true,
			uploadButtonDisabled : true,
			uploadErrMsg:'',
			uploading : false,

			assets :[],
			imageFile:'',
		}
	}
	componentDidMount(){
		// console.log(this.state.selectedDomain)
		// secureStorage.removeItem(`applicationImage${this.state.selectedDomain.id}`)
		if(this.state.selectedDomain){
			this.loadGroups()
			this.loadAllGroups()
			this.initApplicationImage()
		}
	}
	initApplicationImage(){
		const domain = this.state.selectedDomain
		const imageData = secureStorage.getItem(`applicationImage${this.state.selectedDomain.id}`)
    if((!imageData && domain.image) || (imageData && domain.image && imageData.name!==domain.image) ){
      this.getImage(domain.image)
			this.initAssets(true)
		}
		else if(imageData){
			this.setState({applicationImage:imageData.image,imageData})
			this.initAssets()
		}
	}
  getImage(filename){
    axios.get(`${Config.ApiUrl}/user/group/domain/image/${this.state.selectedDomain.id}/${filename}`,{responseType:'blob'})
    .then(res=>{
      
      const reader = new FileReader()
      reader.onload =  (e)=> {
        const image = e.target.result
        secureStorage.setItem(`applicationImage${this.state.selectedDomain.id}`,{name:filename,image})
				this.setState({applicationImage:image})
      }
      reader.readAsDataURL(res.data)
      
    }).catch(err=>{
      console.log(err)
    })
  }
	initAssets(reload){
		this.getAssetsLibrary()
		.then(assetsLibrary=>{
			let assets = secureStorage.getItem(`assets${this.state.selectedDomain.id}`)
			if(assets && !reload){
				// console.log(assets)
				this.setState({assets})
			}
			else{
				if(this.state.selectedDomain.hasAssets){
					const filenames = _.uniq(assetsLibrary.map(item=>item.filename))
					Promise.all(filenames.map(filename=>{
						return new Promise((resolve,reject)=>{
							axios.get(`${Config.ApiUrl}/user/group/asset/${this.state.selectedDomain.id}/${filename}`,{responseType:'blob'})
							.then(res => {
									const reader = new FileReader()
									reader.onload =  (e)=> {
										const src = e.target.result
										return resolve({filename,src})
									}
									reader.readAsDataURL(res.data)
							}).catch(err => {
								return reject(err)
							})
						})
					})).then(data=>{
						assets = assetsLibrary.map(item=>{
							const image = _.find(data,{filename:item.filename})
							return {name:item.name,image}
						})
						// console.log(assets)
						secureStorage.setItem(`assets${this.state.selectedDomain.id}`,assets)
						this.setState({assets})
					}).catch(err=>{
						console.log(err)
					})
				}
			}
		}).catch(err=>{
			console.log(err)
		})
	}
	getAssetsLibrary(){
		return new Promise((resolve,reject)=>{
			const assetsLibrary = secureStorage.getItem('assetsLibrary')
			if(assetsLibrary){
				return resolve(assetsLibrary)
			}
			else{
				axios.post(Config.ApiUrl + '/user/group/asset/library')
				.then(res => {
						// console.log(res.data);
						secureStorage.setItem('assetsLibrary',res.data)
						return resolve(res.data)
				})
				.catch(err => {
					return reject(err)
				})
			}
		})
	}

	loadGroups = (params) =>{
		this.setState({isLoading:true})
		axios.post(Config.ApiUrl + '/user/group',{
			domain:this.state.domainName,
		})
		.then(res => {
				// console.log(res.data);
				this.setState({
					rows:res.data,
					isLoading:false,
				})

		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}
	loadAllGroups = ()=>{
		this.state.selectedDomain && 
		axios.post(Config.ApiUrl + '/user/group/all',{
			domainId : this.state.selectedDomain.id
		})
		.then(res => {
				// console.log(res.data);
				this.setState({
					groups:res.data,
				})
		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}

  render(){
		const {classes,t} = this.props;
		
		const user = secureStorage.getItem('user')
		// const { selectedDomain } = this.props.location
		if (!this.state.selectedDomain)
			return <Redirect to='/Domain' />
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<div>
											<Button onClick={(e)=>{
												secureStorage.setItem('backPayload',this.props.location.backPayload)
												this.props.history.goBack()
												}} color="danger" round><ArrowBackIos />{t('back')}</Button>
										</div>
										<div style={{marginTop:20}}>
											<Label size='huge' color='blue'>{this.state.domainName}</Label>
										</div>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											
											<GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
												
											</GridItem>
											<GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
												
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:20}}>
								<Divider horizontal>
									<Header as='h3'>
										<Icon name='app store' />
										Application Settings
									</Header>
								</Divider>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={7} lg={7} md={8} sm={12} xs={12}>
										<Input labelText='Application Name' id="appName" formControlProps={{ fullWidth: true }}
											inputProps={{
												placeholder : `Application Name`,
												value : this.state.appName,
												onChange : (e)=>{this.setState({appName:e.target.value,saveButtonDisabled:false})},
											}}
										/>
										<Input labelText='Short Name' id="shortName" formControlProps={{ fullWidth: true }}
											inputProps={{
												placeholder : `Short Name`,
												value : this.state.shortName,
												onChange : (e)=>{this.setState({shortName:e.target.value,saveButtonDisabled:false})},
											}}
										/>
										<div style={{marginTop:20,position:'relative'}}>
											<ClickAwayListener onClickAway={()=>this.setState({splashColorShow:false})}>
												<span>
													{this.state.splashColorShow && 
													<HexColorPicker style={{zIndex:1,bottom:'50px',left:0,position:'absolute'}} color={this.state.splashColor} 
														onChange={(color)=>this.setState({splashColor:color,saveButtonDisabled:false})}  
													/>}
													<div className={classes.colorPicker}>
														<InputSemantic label='Splash Color' value={this.state.splashColor} 
															onFocus={()=>this.setState({splashColorShow:true})}
															onChange={(e)=>this.setState({splashColor:e.target.value,saveButtonDisabled:false})} 
														/>
														<div className={classes.colorContainer}>
															<div className={classes.colorRect} style={{backgroundColor:this.state.splashColor}}></div>
														</div>
													</div>
												</span>
											</ClickAwayListener>
										</div>
										<div style={{marginTop:40,position:'relative'}}>
											<ClickAwayListener onClickAway={()=>this.setState({bannerColorShow:false})}>
												<span>
													{this.state.bannerColorShow && 
													<HexColorPicker style={{zIndex:1,bottom:'50px',left:0,position:'absolute'}} color={this.state.bannerColor} 
														onChange={(color)=>this.setState({bannerColor:color,saveButtonDisabled:false})}  
													/>}
													<div className={classes.colorPicker}>
														<InputSemantic label='Banner Color' value={this.state.bannerColor} 
															onFocus={()=>this.setState({bannerColorShow:true})}
															onChange={(e)=>this.setState({bannerColor:e.target.value,saveButtonDisabled:false})}
															// onKeyPress={(e)=>console.log(e.key)} 
														/>
														<div className={classes.colorContainer}>
															<div className={classes.colorRect} style={{backgroundColor:this.state.bannerColor}}></div>
														</div>
													</div>
												</span>
											</ClickAwayListener>
										</div>
										<div style={{justifyContent:'center',textAlign:'center',marginTop:40}}>
											<Button color="instagram" round onClick={()=>{
												if(this.state.appName && this.state.shortName && this.state.splashColor && this.state.bannerColor){
													const {appName,shortName,splashColor,bannerColor} = this.state
													axios.post(Config.ApiUrl+'/user/group/domain/set',{
														domain : this.state.selectedDomain,
														appName,shortName,splashColor,bannerColor
													})
													.then(res=>{
														this.setState({saveButtonDisabled:true})
													}).catch(err=>{
														console.log(err)
													})
												}
											}}
											disabled={this.state.saveButtonDisabled} 
											><Save />{t('save')}</Button>
										</div>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:50}}>
								<Divider horizontal>
									<Header as='h3'>
										<Icon name='picture' />
										Assets
									</Header>
								</Divider>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:50}}>
								<GridContainer justify="center">
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign:'center'}}>
										<LinkMui component="button" onClick={(e)=>{
											const input = document.createElement('input')
											input.type = 'file';
											input.accept = 'image/jpeg, image/png'
											input.onchange = (e)=> {
													this.setState({uploadErrMsg:''})
													const data = new FormData()
													const file = e.target.files[0]
													data.append('file',file)
													const reader = new FileReader()
													
													reader.onload =  (ev)=> {
														const image = new Image()
														image.src = ev.target.result
														image.onload = ()=>{
															if(image.height === image.width){
																this.setState({applicationImage:ev.target.result,imageFile:file,uploadButtonDisabled:false})
															}
															else{
																this.setState({uploadErrMsg:'Dimensi panjang x lebar gambar/logo harus sama'})
															}
														}
													}
													reader.readAsDataURL(file)
											}
											input.click();
										}} className={classes.imgContainer}>
											<Publish />
											<img src={this.state.applicationImage} alt="..." className={classes.applicationImage} />
										</LinkMui>
										<div className={classes.uploadErrMsg}>{this.state.uploadErrMsg}</div>
										<div>
											<Button round
												disabled={this.state.uploadButtonDisabled} 
												onClick={()=>{
													this.setState({uploadButtonDisabled:true,uploading:true})
													if(this.state.imageFile){
														const data = new FormData()
														data.append('image',this.state.imageFile)
														data.append('domain',this.state.domainName)
														axios.post(Config.ApiUrl+'/user/group/asset/upload',data)
														.then(res=>{
															const imageData = {
																name: res.data.image,
																image:this.state.applicationImage
															}
															secureStorage.setItem(`applicationImage${this.state.selectedDomain.id}`,imageData)
															this.setState({uploading:false})
															this.initAssets(true)
															// console.log(imageData)
															// this.props.onProfileImageChange(imageData.image)
														}).catch(err=>{
															this.setState({uploadButtonDisabled:false,uploading:false})
															console.log(err)
														})
													}
											}} color='instagram'>upload</Button>
										</div>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<div className={classes.carouselContainer}>
								<Carousel>
									{this.state.assets.map(item=>(
									<div key={item.name}>
										<img src={item.image.src} alt={item.name} style={{maxWidth:'100%',width:'auto',verticalAlign:'middle'}} />
										<p className='legend'>{item.name}</p>
									</div>
									))}
								</Carousel>
								</div>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:50}}>
								<Divider horizontal>
									<Header as='h3'>
										<Icon name='group' />
										Accounts
									</Header>
								</Divider>
							</GridItem>
							{user.userTypeId===1 && 
							<GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
								<Dropdown
									style={{marginTop:10}}
									placeholder='Groups'
									fluid
									multiple
									search
									selection
									onChange={(e,{value})=>{
										const addButtonDisabled =value.length>0?false:true
										this.setState({
											selectedGroups:value.map(index=>this.state.groups[index]),
											selectedGroupsVal:value,
											addButtonDisabled,
										})
									}}
									value={this.state.selectedGroupsVal}
									options={this.state.groups.map((item,index)=>({
										key:index,
										text:`${item.name} - ${item.userType} - UID:${item.ownerId}`,
										value:index,
									}))}
								/>
							</GridItem>}
							{user.userTypeId===1&&
							<GridItem xl={8} lg={8} md={8} sm={12} xs={12}>
                <Button color="instagram" round onClick={()=>{
									// console.log(this.state.selectedGroups)
									this.setState({modalShow:true,modalTitle:'Add Group'})
								}}
								disabled={this.state.addButtonDisabled} 
								><Add />Add group</Button>
							</GridItem>}
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>
									<Table
									 	// sortable
										stickyHead
										stickyFoot
										striped
										tableHead={["#", "GID",'UID', 'Name',user.userTypeId===1&&'Owner',user.userTypeId===1&&'Remove']}
										tableData={
											this.state.rows.length?this.state.rows.map((row,index)=>(
											Array.isArray(row)?row:[	
												(index+1), 
												row.id.toString().padStart(2,0),
												row.ownerId.toString().padStart(2,0),
                        row.name,
												user.userTypeId===1&&<div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal} key={index}>
													<FormControlLabel classes={{label: classes.label}} //label={'Owner'}	
														control={
															<Radio checked={this.state.ownerId?this.state.ownerId===row.id:row.Domains[0].GroupDomain.isOwner} value={row.id} name="owner" aria-label="A"
																// disabled={item.name==='dotmatrix' ? this.state.dotmatrix==="online"?false:true:false}
																icon={<FiberManualRecord className={classes.radioUnchecked} />} checkedIcon={ <FiberManualRecord className={classes.radioChecked} />}
																classes={{ checked: classes.radio,root: classes.radioRoot}}
																onChange={(e)=>{
																	// console.log(e.target.value)
																	// console.log(this.state.ownerId,row.id)
																	const ownerId = parseInt(e.target.value)
																	const domainId = this.state.selectedDomain.id
																	axios.post(Config.ApiUrl + '/user/group/domain/setowner',{
																		domainId,
																		ownerId,
																	})
																	.then(res=>{
																		this.setState({ownerId})
																	})
																	.catch(err=>{
																		console.log(err)
																	})
																}}
															/>
														}
													/>
												</div>,
                        user.userTypeId===1&&<Button color="danger" size='sm' justIcon round onClick={()=>{
                          this.setState({
                            modalShow : true,
                            modalTitle : 'Remove '+row.name,
                            selectedGroups:[row],
                          })
                        }} ><DeleteForever /></Button>
											])):[]
										}
										textAlignCells={['center','center','center','right']}
										textAlignForCells={[0, 1, 5, 6]}
										textAlignHeaders={['center','center','center','right']}
										textAlignForHeaders={[0, 1, 5, 6]}
										// rawData={this.state.rows}
										// onSort={(data)=>this.setState({rows:data})}
									/>
								</Segment>
							</GridItem>
							{user.userTypeId===1 &&
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:50}}>
								<Divider horizontal>
									<Header as='h3'>
										<Icon name='cogs' />
										Build
									</Header>
								</Divider>
							</GridItem>}
							{user.userTypeId===1 &&
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign:'center',marginTop:50}}>
								<Button color="instagram" round onClick={()=>{
									// console.log(this.state.selectedGroups)
									this.setState({buildButtonDisabled:true})
									axios.post(Config.ApiUrl + '/user/group/domain/build',{domain:this.state.selectedDomain})
									.then(res=>{

									}).catch(err=>{
										console.log(err)
									})
								}}
								// disabled={this.state.buildButtonDisabled} 
								><Build />Build</Button>
							</GridItem>}
            </GridContainer>
            {/* Modal >> */}
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={CustomTransition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								{
									this.state.modalTitle==='Add Group' ?
									<span>Add Group(s) {this.state.selectedGroups && this.state.selectedGroups.map(item=><Badge key={item.id} color='facebook'>{item.name}</Badge>)} to be able to manage domain</span>
									:
									<span>Remove {this.state.selectedGroups && this.state.selectedGroups.map(item=><Badge key={item.id} color='facebook'>{item.name}</Badge>)} from domain</span>
								}
								
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
              <div>
                <Button onClick={() => this.setState({ modalShow: false,selectedGroups:'',partnerErr:''})} color="warning" round><Cancel /> Cancel</Button>
                <Button onClick={()=>{
                  let url = this.state.modalTitle==='Add Group' ? 'add':'remove'
                  axios.post(Config.ApiUrl + '/user/group/' + url,{
                    domainId : this.state.selectedDomain.id,
                    selectedGroups : this.state.selectedGroups,
                  })
                  .then(res=>{
                    this.setState({
                      modalShow:false,
                      selectedGroups:[],
											selectedGroupsVal:[],
											addButtonDisabled:true,
                    })
                    this.loadGroups()
										this.loadAllGroups()
                  })
                  .catch(err=>{
                    console.log(err)
                  })
                }} color={this.state.modalTitle==='Add Group' ? 'facebook':'danger'} round >
									{this.state.modalTitle==='Add Group' ? <Save />:<DeleteForever />}
									{this.state.modalTitle==='Add Group' ? 'Save':'Remove'}
								</Button>
              </div>
							</DialogActions>
						</Dialog>
						{/* << Modal */}
          </div>
        </div>
      </div>
		)
	}
}
AppSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withStyles(style),withTranslation())(AppSetting);
