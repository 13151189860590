// Cores
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from "components/Table/Table";
import PropTypes from 'prop-types';
import classNames from "classnames";

// UI Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "components/Badge/Badge.js";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import { Dimmer, Loader, Segment, Label } from 'semantic-ui-react'

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Libs
import axios from 'axios';
import Config from 'config'
import { fnumber } from 'components/Helper';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditIcon from '@material-ui/icons/Edit';
import Check from "@material-ui/icons/Check";
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

import { Input as InputSem } from 'semantic-ui-react'


import { Link } from "react-router-dom";
import secureStorage from 'libs/secureStorage';

const styles = theme => ({
	...modalStyle(theme),
	...style,
	...tooltipsStyle,
	buttons: {
		marginTop: '25px'
	},
	// container : {
	// 	...style.container,
	// 	"@media (min-width: 576px)": {
	// 		maxWidth: "540px"
	// 	},
	// 	"@media (min-width: 768px)": {
	// 		maxWidth: "720px"
	// 	},
	// 	"@media (min-width: 992px)": {
	// 		maxWidth: "960px"
	// 	},
	// 	"@media (min-width: 1200px)": {
	// 		maxWidth: "1440px"
	// 	}
	// }
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//user : secureStorage.getItem('user'),

			// paging states >>
			rowsPerPage: 15,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			rows: [],
			//<<pagin states

			partnerDropdown: '',
			partners: [],

			categoryMultiple: [],
			categories: [],

			keyword: '',

			modalShow: false,

			backPayload: '',

			isLoading: false,
		}
	}
	componentDidMount() {
		this.loadProduct()
		this.loadCategory()
		this.loadPartner()
	}

	loadProduct = (params) => {
		//console.log(this.props.history)
		this.setState({ isLoading: true })
		let payload = {
			productCategories: params && params.categoryMultiple ? params.categoryMultiple : this.state.categoryMultiple,
			partnerId: params && params.partnerDropdown === '' ? params.partnerDropdown : this.state.partnerDropdown,
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage
		}
		if (secureStorage.getItem('backPayload')) {
			payload = secureStorage.getItem('backPayload')
			this.setState({
				categoryMultiple: payload.productCategories,
				partnerDropdown: payload.partnerId,
				keyword: payload.keyword,
				currentPage: payload.currentPage,
				rowsPerPage: payload.rowsPerPage
			})
			secureStorage.removeItem('backPayload')
		}
		axios.post(Config.ApiUrl + '/product', payload)
			.then(res => {
				//console.log(params &&  params.partnerDropdown?'true':'false')
				//console.log(res)
				this.setState({
					rows: res.data.products,
					totalPages: res.data.totalPages,
					totalData: res.data.totalData,
					backPayload: payload,
					isLoading: false
				})
			})
			.catch(error => {
				this.setState({ isLoading: false })
				console.log(error);
			});

	}

	loadCategory() {
		axios.post(Config.ApiUrl + '/product/category/dropdown')
			.then(res => {

				this.setState({
					categories: res.data,
				})
			})
			.catch(error => {
				console.log(error);
			});
	}
	loadPartner() {
		axios.post(Config.ApiUrl + '/product/partner/dropdown')
			.then(res => {

				this.setState({
					partners: res.data,
				})
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleSubmit = () => {
		this.loadProduct()
	}

	handleDropDownChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleClear = (e) => {
		this.setState({
			keyword: '',
			partnerDropdown: '',
			categoryMultiple: []
		})
		this.loadProduct({ keyword: '', partnerDropdown: '', categoryMultiple: [] })
	}

	handleCheckBox = (productId, isAvailable, type) => {
		axios.post(Config.ApiUrl + '/product/availability', {
			productId,
			isAvailable,
			type,
		})
			.then(res => {
				//console.log(res)
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleShowModal = () => {
		this.setState({ modalShow: true })
	}
	handleModal = (target) => {
		this.setState({ modalShow: false })
	}

	getCheapest = (ps) => {

		let cheapest = ps.length > 0 ? ps[0].price : 0
		ps.map(item => {
			if (item.price < cheapest)
				cheapest = item.price
			return item
		})
		return <Badge color="success">{fnumber(cheapest)}</Badge>
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>

						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='huge'>Products</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<FormControl fullWidth className={classes.selectFormControl}>
													<InputLabel
														htmlFor="category-select"
														className={classes.selectLabel}
													>
														Category
													</InputLabel>
													<Select
														multiple
														MenuProps={{
															className: classes.selectMenu,
															classes: { paper: classes.selectPaper }
														}}
														classes={{
															select: classes.select
														}}
														value={this.state.categoryMultiple}
														onChange={this.handleDropDownChange}
														inputProps={{
															name: "categoryMultiple",
															id: "categoryMultiple"
														}}
													>
														<MenuItem
															disabled
															classes={{
																root: classes.selectMenuItem
															}}
															value={''}
														>
															Category
														</MenuItem>
														{this.state.categories.map((item, index) => (
															<MenuItem
																key={index}
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelectedMultiple
																}}
																value={item.id}
															>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<FormControl fullWidth className={classes.selectFormControl}>
													<InputLabel
														htmlFor="partner-select"
														className={classes.selectLabel}
													>
														Partner
													</InputLabel>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select
														}}
														value={this.state.partnerDropdown}
														onChange={this.handleDropDownChange}
														inputProps={{
															name: "partnerDropdown",
															id: "partnerDropdown"
														}}
													>
														<MenuItem
															disabled
															classes={{
																root: classes.selectMenuItem
															}}
															value={''}
														>
															Partner
														</MenuItem>
														{this.state.partners.map((item, index) => (
															<MenuItem
																key={index}
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value={item.id}
															>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Input
													labelText="Search"
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder: 'Item Name',
														value: this.state.keyword,
														onChange: (e) => { this.setState({ keyword: e.target.value }) },
														onKeyPress: (e) => { if (e.key === 'Enter') { this.setState({ keyword: e.target.value }); this.loadProduct({ keyword: e.target.value }) } }
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<span>
													<div className={classNames(classes.buttonGroup, classes.buttons)}>
														<Tooltip id="tooltip-search" title="Search" placement="top" classes={{ tooltip: classes.tooltip }}>
															<Button color="info" size="sm" round onClick={() => this.loadProduct()} className={classes.firstButton} ><SearchIcon /></Button>
														</Tooltip>
														<Tooltip id="tooltip-clear" title="Clear" placement="top" classes={{ tooltip: classes.tooltip }}>
															<Button color="info" size="sm" round onClick={() => this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
														</Tooltip>
													</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>
									<Table
										stickyHead
										stickyFoot
										striped
										tableHead={[
											"#", "PID", 'Open', "Item", "Category", "Partner", "Price", "Cheapest", "Partners", "Default Fee", 'Partner[TL]', 'Open[TL]',
										]}
										tableData={
											this.state.rows.length > 0 ? this.state.rows.map((product, index) => (
												[
													(index + 1) + (isNaN(this.state.rowsPerPage) ? 0 : ((this.state.currentPage - 1) * this.state.rowsPerPage)),
													product.id.toString().padStart(4, 0),
													<Checkbox
														id={'c' + product.id}
														checked={this.state['c' + product.id] !== undefined ? this.state['c' + product.id] : product.isAvailable}
														tabIndex={-1}
														onClick={(e) => {
															if (product.isAvailable && this.state['c' + product.id] === undefined) {
																this.setState({ ['c' + product.id]: !product.isAvailable });
															}
															else
																this.setState({ ['c' + product.id]: e.target.checked });
															this.handleCheckBox(product.id, e.target.checked, 'dm')
														}}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>,
													!this.state['idEditing' + product.id] ?
														<span>
															<Button color="google" size="sm" justIcon round
																onClick={(e) => {
																	this.setState({
																		['idEditing' + product.id]: true,
																		['oldProductName' + product.id]: this.state['productName' + product.id] ? this.state['productName' + product.id] : product.name,
																		['productName' + product.id]: this.state['productName' + product.id] ? this.state['productName' + product.id] : product.name
																	})
																}}
															><EditIcon /></Button>
															<span style={{ marginLeft: '10px' }}>{this.state['productName' + product.id] ? this.state['productName' + product.id] : product.name}</span>
														</span>
														:
														<span>
															<Button color="danger" size="sm" justIcon round
																onClick={(e) => {
																	this.setState({
																		['productName' + product.id]: this.state['oldProductName' + product.id],
																		['idEditing' + product.id]: false
																	})
																}}
															><CancelIcon /></Button>
															<span style={{ marginLeft: '10px', marginRight: '10px' }}>
																<InputSem size='large'
																	placeholder='Item Name'
																	value={this.state['productName' + product.id]}
																	onChange={(e) => this.setState({ ['productName' + product.id]: e.target.value })}
																	onKeyDown={(e) => {
																		if (e.key === 'Enter') {
																			const name = this.state['productName' + product.id]
																			if (name) {
																				axios.post(Config.ApiUrl + '/product/update/name', {
																					id: product.id,
																					name,
																				})
																					.then(res => {
																						this.setState({ ['productName' + product.id]: name })
																					})
																					.catch(error => {
																						console.log(error);
																					});
																				this.setState({ ['idEditing' + product.id]: false })
																			}
																		}
																	}}
																/>
															</span>
															<Button color="linkedin" size="sm" justIcon round
																onClick={(e) => {
																	const name = this.state['productName' + product.id]
																	if (name) {
																		axios.post(Config.ApiUrl + '/product/update/name', {
																			id: product.id,
																			name,
																		})
																			.then(res => {
																				this.setState({ ['productName' + product.id]: name })
																			})
																			.catch(error => {
																				console.log(error);
																			});
																		this.setState({ ['idEditing' + product.id]: false })
																	}
																}}
															><SaveIcon /></Button>
														</span>,
													product.ProductCategory.name,
													<span>
														<span style={{ marginRight: '10px' }}>{product.ProductSwitchings.map((ps, key) => ps.priority === 1 ? ps.Partner.name : null)}</span>
														<Button color="primary" size="sm" justIcon round component={Link} to={{ pathname: '/ProductSwitching', priorityType: 'priority', product: product, backPayload: this.state.backPayload }} ><EditIcon /></Button>
													</span>,
													product.ProductSwitchings.map((ps, key) => (ps.priority === 1 ? <Badge color="rose" key={key}>{fnumber(ps.price)}</Badge> : null)),
													this.getCheapest(product.ProductSwitchings),
													product.ProductSwitchings.length,
													<span>
														{product.AdminNominals.length > 0 ? <Badge color="success">✓</Badge> : <Badge color="danger">N/A</Badge>}
														<Button color="warning" size="sm" justIcon round component={Link} to={{ pathname: '/AdminNominal', product: product, backPayload: this.state.backPayload }} ><EditIcon /></Button>
													</span>,
													<span>
														<span style={{ marginRight: '10px' }}>{product.ProductSwitchings.map((ps, key) => ps.priorityTl === 1 ? ps.Partner.name : null)}</span>
														<Button color="primary" size="sm" justIcon round component={Link} to={{ pathname: '/ProductSwitching', priorityType: 'priorityTl', product: product, backPayload: this.state.backPayload }} ><EditIcon /></Button>
													</span>,
													<Checkbox
														id={'ctl' + product.id}
														checked={this.state['ctl' + product.id] !== undefined ? this.state['ctl' + product.id] : product.isAvailableTl}
														tabIndex={-1}
														onClick={(e) => {
															if (product.isAvailableTl && this.state['ctl' + product.id] === undefined) {
																this.setState({ ['ctl' + product.id]: !product.isAvailableTl });
															}
															else
																this.setState({ ['ctl' + product.id]: e.target.checked });
															this.handleCheckBox(product.id, e.target.checked, 'tl')
														}}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>,
												])) : []
										}
										textAlignCells={['center', 'right', 'right', 'right', 'center']}
										textAlignForCells={[4, 5, 6, 7, 8]}
										textAlignHeaders={['center', 'right', 'right', 'right', 'center']}
										textAlignForHeaders={[4, 5, 6, 7, 8]}
									/>
								</Segment>
								<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
									<div className={classes.justifyContentCenter} >
										<Pagination
											totalButton={7}
											totalPages={this.state.totalPages}
											totalData={this.state.totalData}
											currentPage={this.state.currentPage}
											onChangePage={(currentPage) => {
												this.setState({ currentPage })
												this.loadProduct({ currentPage })
											}}
											// pages={[
											// 	...this.paginationButton(),
											// ]}
											rowsPerPage={[5, 15, 25, 'All']}
											defaultRowsPerPage={15}
											onChangeRowsPerPage={(value) => {
												this.setState({ rowsPerPage: value, currentPage: 1 })
												this.loadProduct({ rowsPerPage: value, currentPage: 1 })
											}}
										/>
									</div>
								</GridItem>
							</GridItem>

						</GridContainer>
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={Transition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								<GridContainer justify='center'>
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<Input
											labelText="Price"
											id="modalPrice"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder: 'Price',
												value: fnumber(this.state.modalPrice),
												type: 'tel'
											}}
										/>
									</GridItem>
								</GridContainer>
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button onClick={() => this.handleModal('cancel')} color="danger">Cancel</Button>
								<Button onClick={() => this.handleModal('submit')} color="success">Submit</Button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
		)
	}
}
Product.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Product);

// const SearchBy = ['Item','Category','H2H DM','H2H TL'] 