const SalesType = [
  "DM 2 Levels",
  "TL 3 Levels",
  "H2H DM",
  "H2H TL",
  "DP 3 Levels",
  "H2H RF",
  "FD 5 Levels",
  "FT 5 Levels",
  "VT 2 Levels H2H",
  "GT 4 Levels",
];
const DirectUserType = [6, 2, 8, 5, 9, 12, 14, 19, 24, 26];
const EndUsers = [4, 5, 7, 8, 11, 12, 18, 23, 25, 29];
const DmUsers = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
const TlUsers = [2, 3, 4, 5, 19, 20, 21, 22, 23, 24, 25, 26, 26, 27, 28, 29];
const BillingUsers = [2, 4, 5, 19, 20, 23, 24, 25, 26, 27, 29];

const isAdminManagable = (user, selectedUser) => {
  if (
    (user.userTypeId === 2 && [3].includes(selectedUser.userTypeId)) ||
    ([19].includes(user.userTypeId) &&
      [20, 21, 22].includes(selectedUser.userTypeId)) ||
    ([20].includes(user.userTypeId) &&
      [21, 22].includes(selectedUser.userTypeId) &&
      user.isAdministrator) ||
    ([26].includes(user.userTypeId) &&
      [27, 28].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 27 &&
      [28].includes(selectedUser.userTypeId) &&
      user.isAdministrator)
  ) {
    return true;
  }

  return false;
};

const isDmRealtime = (user, selectedUser) => {
  if (
    (user.userTypeId === 1 &&
      [2, 5, 6, 8, 9, 12, 13, 14, 19, 24, 26].includes(
        selectedUser.userTypeId
      )) ||
    // USER CATL LVL 3
    (user.userTypeId === 2 && [4].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 3 &&
      [4].includes(selectedUser.userTypeId) &&
      user.isAdministrator) ||
    // USER MIFT LVL 5
    (user.userTypeId === 19 && [20, 23].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 20 && [23].includes(selectedUser.userTypeId)) ||
    ([21, 22].includes(user.userTypeId) &&
      [23].includes(selectedUser.userTypeId) &&
      user.isAdministrator) ||
    (user.userTypeId === 24 && [25].includes(selectedUser.userTypeId)) ||
    // H2H LVL 2
    (user.userTypeId === 24 && [25].includes(selectedUser.userTypeId)) ||
    // MIGT LVL 4
    ([26, 27].includes(user.userTypeId) &&
      [27, 29].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 28 &&
      [29].includes(selectedUser.userTypeId) &&
      user.isAdministrator)
  ) {
    return true;
  }

  return false;
};

const isManagable = (user, selectedUser) => {
  if (
    (user.userTypeId === 1 &&
      [2, 5, 6, 8, 9, 12, 13, 14, 19, 24, 26].includes(
        selectedUser.userTypeId
      )) ||
    // if (
    //   (user.userTypeId === 1 &&
    //     [2, 12, 13, 14, 20, 23, 27, 29].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 2 && [4].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 2 &&
      [3].includes(selectedUser.userTypeId) &&
      selectedUser.isAdministrator) ||
    (user.userTypeId === 3 &&
      [4].includes(selectedUser.userTypeId) &&
      user.isAdministrator) ||
    (user.userTypeId === 19 && [20].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 20 && [23].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 24 && [25].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 26 && [27].includes(selectedUser.userTypeId)) ||
    (user.userTypeId === 27 && [29].includes(selectedUser.userTypeId))
  ) {
    return true;
  }
  return false;
};

export {
  SalesType,
  DirectUserType,
  EndUsers,
  DmUsers,
  TlUsers,
  BillingUsers,
  isManagable,
  isDmRealtime,
  isAdminManagable,
};
