import React, { useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Table, Checkbox } from "semantic-ui-react";

import _ from "lodash";

import styles from "assets/jss/material-kit-pro-react/components/tableStyle.js";
import { TableContainer } from "@material-ui/core";

const useStyles = makeStyles(styles);

function reducer(state, action) {
  switch (action.type) {
    case "INIT_DATA":
      return {
        ...state,
        data: state.data && action.data,
      };
    case "RETURN_SORT":
      if (action.column !== 0)
        return {
          ...state,
          data: state.data && _.sortBy(state.data, [action.column]),
          direction: null,
        };
      else if (state.direction === "descending")
        return {
          ...state,
          data: state.data && state.data.slice().reverse(),
          direction: null,
        };
      else {
        return {
          ...state,
          direction: null,
        };
      }

    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data && state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: state.data && _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    default:
      throw new Error();
  }
}

export default function CustomTable(props) {
  const [sortable, setSortable] = React.useState(false);
  const [sticky, setSticky] = React.useState(false);
  const [isSorting, setSorting] = React.useState(false);
  const [preview, setPreview] = React.useState(true);

  const {
    tableHead,
    tableFoot,
    tableData,
    rawData,
    tableHeaderColor,
    tableFooterColor,
    hover,
    colorsColls,
    coloredColls,
    striped,
    textAlignForCells,
    textAlignCells,
    textAlignForHeaders,
    textAlignHeaders,
    textAlignForFooters,
    textAlignFooters,
  } = props;

  const classes = useStyles();

  const [state, dispatch] = React.useReducer(reducer, {
    column: null,
    data: rawData,
    direction: null,
  });
  const { column, data, direction } = state;
  const wrapper = useCallback(
    (params) => {
      // setSorting(true)

      return new Promise((resolve, reject) => {
        dispatch(params);

        // const rwData = params && column === params.column ? data.slice().reverse() : _.sortBy(data, [params.column])

        if (data) {
          const rwData =
            params.type === "RETURN_SORT"
              ? params.column !== 0
                ? _.sortBy(data, [0])
                : direction === "descending"
                ? data.slice().reverse()
                : data
              : params && column === params.column
              ? data.slice().reverse()
              : _.sortBy(data, [params.column]);
          // console.log({data})
          // console.log({data,rwData})
          resolve(rwData);
        } else reject();
      });
    },
    [data, column, dispatch, direction]
  );
  React.useEffect(() => {
    // console.log(_.isEqual(data,tableData),isSorting)
    !_.isEqual(data, rawData) && wrapper({ type: "INIT_DATA", data: rawData });
    // isSorting && setSorting(false)
  }, [tableData, isSorting, wrapper, rawData, data]);

  return (
    <div className={classes.tableResponsive}>
      {props.sortable && (
        <div className={classes.toggle}>
          <Checkbox
            toggle
            label="Sortable"
            onClick={(e, { checked }) => {
              if (!checked)
                wrapper({ type: "RETURN_SORT", column: 0 }).then(
                  (data) =>
                    props.onSort && props.onSort(data) && setSorting(false)
                ) && setSorting(true);
              setSortable(checked);
            }}
          />
        </div>
      )}

      <div className={classes.toggle}>
        <Checkbox
          toggle
          label="Pin"
          onClick={(e, { checked }) => setSticky(checked)}
        />
      </div>
      {props.togglePreview && (
        <div className={classes.toggle}>
          <Checkbox
            toggle
            label="Preview"
            checked={preview}
            onClick={(e, { checked }) => setPreview(checked)}
          />
        </div>
      )}
      <TableContainer className={sticky ? classes.container : ""}>
        <Table sortable={sortable} className={classes.table} unstackable>
          {tableHead !== undefined ? (
            <Table.Header className={classes[tableHeaderColor]}>
              <Table.Row className={classes.tableRow}>
                {tableHead.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableHeadCell +
                    " " +
                    classes.tableCell +
                    " " +
                    cx({
                      [classes.stickyHead]: sticky,
                    });
                  const textAlign =
                    textAlignForHeaders.indexOf(key) !== -1
                      ? {
                          textAlign:
                            textAlignHeaders[textAlignForHeaders.indexOf(key)],
                        }
                      : {};
                  return prop ? (
                    <Table.HeaderCell
                      className={tableCellClasses}
                      key={key}
                      sorted={sortable && column === key ? direction : null}
                      onClick={() =>
                        sortable &&
                        wrapper({ type: "CHANGE_SORT", column: key }).then(
                          (data) =>
                            props.onSort &&
                            props.onSort(data) &&
                            setSorting(false)
                        ) &&
                        setSorting(true)
                      }
                      style={
                        sortable
                          ? { backgroundColor: "#fff", ...textAlign }
                          : { ...textAlign }
                      }
                    >
                      {prop}
                    </Table.HeaderCell>
                  ) : null;
                })}
              </Table.Row>
            </Table.Header>
          ) : null}
          <Table.Body>
            {preview &&
              tableData.map((prop, key) => {
                var rowColor = "";
                var rowColored = false;
                if (prop.color !== undefined) {
                  rowColor = prop.color;
                  rowColored = true;
                  prop = prop.data;
                }
                const tableRowClasses = cx({
                  [classes.tableRowHover]: hover,
                  [classes[rowColor + "Row"]]: rowColored,
                  [classes.oddRow]: striped && key % 2 === 0,
                  [classes.evenRow]: striped && key % 2 === 1,
                });

                return (
                  <Table.Row
                    key={key}
                    hover={hover}
                    className={classes.tableRow + " " + tableRowClasses}
                  >
                    {prop.map((prop, key) => {
                      const tableCellClasses =
                        classes.tableCell +
                        " " +
                        cx({
                          [classes[colorsColls[coloredColls.indexOf(key)]]]:
                            coloredColls.indexOf(key) !== -1,
                        });
                      const textAlign =
                        textAlignForCells.indexOf(key) !== -1
                          ? {
                              textAlign:
                                textAlignCells[textAlignForCells.indexOf(key)],
                            }
                          : {};
                      return prop ? (
                        <Table.Cell
                          className={tableCellClasses}
                          style={{ ...textAlign }}
                          key={key}
                        >
                          {prop}
                        </Table.Cell>
                      ) : null;
                    })}
                  </Table.Row>
                );
              })}
          </Table.Body>
          {tableFoot !== undefined ? (
            <Table.Footer className={classes[tableFooterColor]}>
              <Table.Row className={classes.tableRow}>
                {tableFoot.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableFootCell +
                    " " +
                    classes.tableCell +
                    " " +
                    cx({
                      [classes.stickyFoot]: sticky,
                    });
                  const textAlign =
                    textAlignForFooters.indexOf(key) !== -1
                      ? {
                          textAlign:
                            textAlignFooters[textAlignForFooters.indexOf(key)],
                        }
                      : {};
                  return prop ? (
                    <Table.HeaderCell
                      className={tableCellClasses}
                      style={{ ...textAlign }}
                      key={key}
                    >
                      {prop}
                    </Table.HeaderCell>
                  ) : null;
                })}
              </Table.Row>
            </Table.Footer>
          ) : null}
        </Table>
      </TableContainer>
    </div>
  );
}

CustomTable.defaultProps = {
  togglePreview: false,
  tableHeaderColor: "gray",
  tableFooterColor: "gray",
  hover: "false",
  colorsColls: [],
  coloredColls: [],
  striped: false,
  textAlignForCells: [],
  textAlignCells: [],
  textAlignForHeaders: [],
  textAlignHeaders: [],
  textAlignForFooters: [],
  textAlignFooters: [],
};

CustomTable.propTypes = {
  togglePreview: PropTypes.bool,
  onSort: PropTypes.func,
  sortedColumn: PropTypes.object,
  sortable: PropTypes.bool,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  // tableHead: PropTypes.arrayOf(PropTypes.string),
  tableHead: PropTypes.array,
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  tableFoot: PropTypes.arrayOf(PropTypes.string),
  hover: PropTypes.string,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  textAlignForCells: PropTypes.arrayOf(PropTypes.number),
  textAlignCells: PropTypes.arrayOf(PropTypes.string),
  textAlignForHeaders: PropTypes.arrayOf(PropTypes.number),
  textAlignHeaders: PropTypes.arrayOf(PropTypes.string),
  textAlignForFooters: PropTypes.arrayOf(PropTypes.number),
  textAlignFooters: PropTypes.arrayOf(PropTypes.string),
  striped: PropTypes.bool,
};
