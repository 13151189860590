import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "assets/locales/en/translation"
import translationID from "assets/locales/id/translation"


const fallbackLng = ["id"];
const availableLanguages = ["en", "id"];

const resources = {
  en: {
    translation: translationEN
  },
  id: {
    translation: translationID
  },
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load:'languageOnly',
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n