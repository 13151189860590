import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import PropTypes from "prop-types";
import GDatePicker from "./GDatePicker/GDatePicker";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle";
import moment from "moment";

const styles = {
  ...customSelectStyle,
  container: {
    //marginTop:'-90px'
  },
  grid: {
    //backgroundColor: '#000000'
  },
  btnSubmit: {
    height: "35px",
    width: "80px",
    marginTop: "25px",
  },
};

class GSearchActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log: "",
      hiddenYear: true,
      hiddenMonth: true,
      submitDisabled: true,
      year: "",
      month: "",
      date: moment(),
      fromDate: moment(),
      toDate: moment(),
      //ViewhBy DropdownBox>>
      viewBy: 2,
      items: [],
      years: [],
      //<<ViewBy DropdownBox
    };
  }

  componentDidMount() {
    let today = new Date();
    this.setState({
      // viewBy:0,
      month: today.getMonth(),
    });
    this.loadYear();
  }

  loadYear() {
    const startYear = 2020;
    const endYear = moment().year();
    let years = [];
    for (let y = startYear; y <= endYear; y++) {
      years.push(y);
    }
    // console.log(years)
    this.setState({
      years,
      year: moment().year(),
      submitDisabled: false,
    });
  }

  handleSubmit = (e) => {
    let startDate = moment();
    let endDate = moment();
    const year = this.state.year && this.state.year;

    let month = this.state.month;
    if (this.state.viewBy === 2) {
      startDate = this.state.date.clone().startOf("day");
      endDate = this.state.date.clone().endOf("day");
    } else if (this.state.viewBy === 0) {
      startDate = moment({ y: year, M: month }).startOf("month");
      endDate = moment({ y: year, M: month }).endOf("month");
    } else if (this.state.viewBy === 3) {
      startDate = this.state.fromDate.startOf("day");
      endDate = this.state.toDate.endOf("day");
    } else if (this.state.viewBy === 4) {
      startDate = this.state.fromDate;
      endDate = this.state.toDate;
    } else {
      startDate = moment({ y: year }).startOf("year");
      endDate = moment({ y: year }).endOf("year");
    }
    let params = {
      startDate,
      endDate,
    };
    // console.log(startDate.toDate())
    // console.log(endDate.toDate())
    //this.setState({log:startDate + ' : '+endDate})
    this.props.onSubmit(params);
  };

  onChange = (params) => {
    let startDate = moment();
    let endDate = moment();
    const year = params && params.year ? params.year : this.state.year;
    const viewBy = params && params.viewBy ? params.viewBy : this.state.viewBy;
    const month = params && params.month ? params.month : this.state.month;
    const date = params && params.date ? params.date : this.state.date;
    const fromDate =
      params && params.fromDate ? params.fromDate : this.state.fromDate;
    const toDate = params && params.toDate ? params.toDate : this.state.toDate;

    switch (viewBy) {
      case 2:
        startDate = date.clone().startOf("day");
        endDate = date.clone().endOf("day");
        break;
      case 0:
        startDate = moment({ y: year, M: month }).startOf("month");
        endDate = moment({ y: year, M: month }).endOf("month");
        break;
      case 3:
        startDate = fromDate.startOf("day");
        endDate = toDate.endOf("day");
        break;
      case 4:
        startDate = fromDate;
        endDate = toDate;
        break;
      default:
        startDate = moment({ y: year }).startOf("year");
        endDate = moment({ y: year }).endOf("year");
    }
    this.props.onChange && this.props.onChange({ startDate, endDate });
  };

  handleDateChange = (date, target) => {
    // console.log(date)
    if (target === "date") {
      this.setState({ date });
      this.onChange({ date });
    } else if (target === "fromDate") {
      this.setState({ fromDate: date });
      this.onChange({ fromDate: date });
    } else if (target === "toDate") {
      this.setState({ toDate: date });
      this.onChange({ toDate: date });
    }
  };

  //ViewBy event handler>>
  handleChangeDropdown = (e) => {
    if (e.target.name === "viewBy") {
      this.setState({ viewBy: e.target.value });
      this.onChange({ viewBy: e.target.value });
      if (e.target.value === 0) {
        this.setState({
          hiddenMonth: false,
          hiddenYear: false,
        });
      } else if (e.target.value === 1) {
        this.setState({
          hiddenMonth: true,
          hiddenYear: false,
        });
      } else if (e.target.value === 2 || e.target.value === 3) {
        this.setState({
          hiddenMonth: true,
          hiddenYear: true,
        });
      } else {
        this.setState({
          hiddenMonth: true,
          hiddenYear: true,
        });
      }
    } else if (e.target.name === "month") {
      this.setState({ month: e.target.value });
      this.onChange({ month: e.target.value });
      if (e.target.value === "") {
        this.setState({ submitDisabled: true });
      }
      if (this.state.year === "") this.setState({ submitDisabled: false });
    } else if (e.target.name === "year") {
      // console.log(e.target.value)
      this.setState({ year: e.target.value });
      this.onChange({ year: e.target.value });
      if (
        (e.target.value === "" || this.state.month === "") &&
        this.state.viewBy === 0
      )
        this.setState({ submitDisabled: true });
      else this.setState({ submitDisabled: false });
    }
  };
  //<<ViewBy event handler

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={6} sm={3} md={3} lg={3} xl={3} className={classes.grid}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="viewBy-select"
                className={classes.selectLabel}
              >
                {t("viewBy")}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.viewBy}
                onChange={this.handleChangeDropdown}
                inputProps={{
                  name: "viewBy",
                  id: "viewBy",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={""}
                >
                  {t("viewBy")}
                </MenuItem>
                {Items.map((item, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={index}
                  >
                    {t(item.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          {[3, 4].indexOf(this.state.viewBy) !== -1 ? (
            <GridItem
              xs={6}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.grid}
            >
              <GDatePicker
                labelText={t("from")}
                defaultValue={moment()}
                dateTimeProps={{
                  onChange: (date) => this.handleDateChange(date, "fromDate"),
                  timeFormat: this.state.viewBy === 3 ? false : true,
                }}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "From Date",
                  readOnly: true,
                }}
              />
            </GridItem>
          ) : (
            ""
          )}
          {!this.state.hiddenYear ? (
            <GridItem
              xs={6}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.grid}
            >
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="year-select"
                  className={classes.selectLabel}
                >
                  {t("year")}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.year}
                  onChange={this.handleChangeDropdown}
                  inputProps={{
                    name: "year",
                    id: "year",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={""}
                  >
                    {t("year")}
                  </MenuItem>
                  {this.state.years.map((item, index) => (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          ) : (
            ""
          )}
          {this.state.viewBy === 2 ? (
            <GridItem
              xs={6}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.grid}
            >
              <GDatePicker
                labelText={t("date")}
                defaultValue={moment()}
                dateTimeProps={{
                  onChange: (date) => this.handleDateChange(date, "date"),
                  timeFormat: false,
                }}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: t("date"),
                  readOnly: true,
                }}
              />
            </GridItem>
          ) : (
            ""
          )}
          {[3, 4].indexOf(this.state.viewBy) !== -1 ? (
            <GridItem
              xs={6}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.grid}
            >
              <GDatePicker
                labelText={t("to")}
                defaultValue={moment()}
                dateTimeProps={{
                  onChange: (date) => this.handleDateChange(date, "toDate"),
                  timeFormat: this.state.viewBy === 3 ? false : true,
                }}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "To Date",
                  readOnly: true,
                }}
              />
            </GridItem>
          ) : (
            ""
          )}
          {!this.state.hiddenMonth ? (
            <GridItem
              xs={6}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.grid}
            >
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="month-select"
                  className={classes.selectLabel}
                >
                  {t("month")}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.month}
                  onChange={this.handleChangeDropdown}
                  inputProps={{
                    name: "month",
                    id: "month",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={""}
                  >
                    {t("month")}
                  </MenuItem>
                  {moment.months().map((item, index) => (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={index}
                    >
                      {t(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          ) : (
            ""
          )}
          <GridItem xs={2} sm={2} md={2} lg={2} xl={2} className={classes.grid}>
            <Button
              color="info"
              round
              onClick={this.handleSubmit}
              disabled={this.state.submitDisabled}
              className={classes.btnSubmit}
            >
              Submit
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
GSearchActions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(GSearchActions);
/**
 * props :
 * APIUrl
 *
 * events:
 * onSubmit returning params of startDate and endDate of moment object
 */
const Items = [
  { name: "month" },
  { name: "year" },
  { name: "date" },
  { name: "fromToDate" },
  { name: "fromToDatetime" },
];
