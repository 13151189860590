// Cores
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table";
import PropTypes from "prop-types";
import classNames from "classnames";

// UI Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "components/Badge/Badge.js";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import { Dimmer, Loader, Segment, Label } from "semantic-ui-react";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Libs
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

import { Input as InputSem } from "semantic-ui-react";

import { Link } from "react-router-dom";
import secureStorage from "libs/secureStorage";

const styles = (theme) => ({
  ...modalStyle(theme),
  ...style,
  ...tooltipsStyle,
  buttons: {
    marginTop: "25px",
  },
});

class ProductUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],

      categoryMultiple: [],
      categories: [],

      keyword: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadProduct();
    this.loadCategory();
  }

  loadProduct = (params) => {
    //console.log(this.props.history)
    this.setState({ isLoading: true });
    let payload = {
      productCategories:
        params && params.categoryMultiple
          ? params.categoryMultiple
          : this.state.categoryMultiple,
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
    };

    axios
      .post(Config.ApiUrl + "/product/user", payload)
      .then((res) => {
        //console.log(params &&  params.partnerDropdown?'true':'false')
        //console.log(res)
        this.setState({
          rows: res.data.products,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  loadCategory() {
    axios
      .post(Config.ApiUrl + "/product/category/dropdown")
      .then((res) => {
        this.setState({
          categories: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDropDownChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClear = (e) => {
    this.setState({
      keyword: "",
      categoryMultiple: [],
    });
    this.loadProduct({
      keyword: "",
      categoryMultiple: [],
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  {/* Title Menu */}
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">Products</Label>
                  </GridItem>
                  {/* Filter */}
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GridContainer justify="center">
                      {/* Select Category */}
                      <GridItem xl={3} lg={3} md={4} sm={6} xs={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="category-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            multiple
                            MenuProps={{
                              className: classes.selectMenu,
                              classes: { paper: classes.selectPaper },
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.categoryMultiple}
                            onChange={this.handleDropDownChange}
                            inputProps={{
                              name: "categoryMultiple",
                              id: "categoryMultiple",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              value={""}
                            >
                              Category
                            </MenuItem>
                            {this.state.categories.map((item, index) => (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {/* Search Keyword */}
                      <GridItem xl={3} lg={3} md={4} sm={6} xs={6}>
                        <Input
                          labelText="Search"
                          id="keyword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Item Name",
                            value: this.state.keyword,
                            onChange: (e) => {
                              this.setState({ keyword: e.target.value });
                            },
                            onKeyPress: (e) => {
                              if (e.key === "Enter") {
                                this.setState({ keyword: e.target.value });
                                this.loadProduct({ keyword: e.target.value });
                              }
                            },
                          }}
                        />
                      </GridItem>
                      {/* Button */}
                      <GridItem xl={2} lg={2} md={4} sm={6} xs={6}>
                        <span>
                          <div
                            className={classNames(
                              classes.buttonGroup,
                              classes.buttons
                            )}
                          >
                            <Tooltip
                              id="tooltip-search"
                              title="Search"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="info"
                                size="sm"
                                round
                                onClick={() => this.loadProduct()}
                                className={classes.firstButton}
                              >
                                <SearchIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-clear"
                              title="Clear"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="info"
                                size="sm"
                                round
                                onClick={() => this.handleClear()}
                                className={classes.lastButton}
                              >
                                <ClearAllIcon />
                              </Button>
                            </Tooltip>
                          </div>
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* Table List */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <Table
                    stickyHead
                    stickyFoot
                    striped
                    tableHead={[
                      "#",
                      "PID",
                      "Item",
                      "Category",
                      "Price",
                      "Denom",
                    ]}
                    tableData={
                      this.state.rows.length > 0
                        ? this.state.rows.map((product, index) => [
                            index +
                              1 +
                              (isNaN(this.state.rowsPerPage)
                                ? 0
                                : (this.state.currentPage - 1) *
                                  this.state.rowsPerPage),
                            product.id.toString().padStart(4, 0),
                            product.name,
                            product.category,
                            <Badge color="rose">
                              {fnumber(product.price)}
                            </Badge>,
                            product.denom ? (
                              <Badge color="success">
                                {fnumber(product.denom)}
                              </Badge>
                            ) : (
                              "-"
                            ),
                          ])
                        : []
                    }
                    textAlignCells={["center", "right", "right"]}
                    textAlignForCells={[3, 4, 5]}
                    textAlignHeaders={["center", "right", "right"]}
                    textAlignForHeaders={[3, 4, 5]}
                  />
                </Segment>
                <GridItem
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.grid}
                >
                  <div className={classes.justifyContentCenter}>
                    <Pagination
                      totalButton={7}
                      totalPages={this.state.totalPages}
                      totalData={this.state.totalData}
                      currentPage={this.state.currentPage}
                      onChangePage={(currentPage) => {
                        this.setState({ currentPage });
                        this.loadProduct({ currentPage });
                      }}
                      rowsPerPage={[5, 10, 15, 25, "All"]}
                      defaultRowsPerPage={15}
                      onChangeRowsPerPage={(value) => {
                        this.setState({ rowsPerPage: value, currentPage: 1 });
                        this.loadProduct({
                          rowsPerPage: value,
                          currentPage: 1,
                        });
                      }}
                    />
                  </div>
                </GridItem>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

ProductUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductUser);
