import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";

import Person from "@material-ui/icons/Person";
// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { Dropdown } from "semantic-ui-react";
import i18next from "i18next";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Config from "config";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "assets/img/newbg1.jpg";

import { secureStorage } from "libs";
import Resource from "libs/resource";

import moment from "moment";

import textLogo from "assets/img/ginlogo.png";

import { Modal, Header, Transition } from "semantic-ui-react";

import * as EmailValidator from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";

const styles = (theme) => ({
  ...loginPageStyle(theme),
  headerLogoDefault: {
    borderRadius: 10,
    height: 40,
    width: "auto",
  },
  imageLogoDefault: {
    borderRadius: "40% !important",
    height: 60,
    width: "auto",
  },
  headerLogo: {
    borderRadius: 10,
    height: 40,
    width: "auto",
  },
  imageLogo: {
    borderRadius: "40% !important",
    height: 60,
    width: "auto",
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      redirectToReferrer: false,
      selectedLang: localStorage.getItem("i18nextLng") || "id",
      appName: "",
      logo: "/android-icon-512x512.png",
      info: "",

      verification: false,
      showPassword: false,

      resetPass: "",
      acResetPass: "",
      openModal: false,
      resetDisabled: true,
      resetErrMsg: "",
      captchaRes: "",
    };
    this.reCaptcha = React.createRef();
  }
  componentDidMount() {
    this.setState({ selectedLang: localStorage.getItem("i18nextLng") || "id" });
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    window.location.hostname.indexOf("payfren") === -1 && this.initResources();
  }
  initResources() {
    Resource.checkUpdate();
    Resource.getInfo()
      .then((info) => {
        this.setState({ appName: info.appName, info });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loginInquiry = () => {
    this.setState({ loading: true });
    axios
      .post(Config.ApiUrl + "/user/login", {
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        this.setState({ loading: false });
        // setSecretKey(res.data.token)
        secureStorage.setItem("token", res.data.token);
        secureStorage.setItem("user", res.data.user);
        this.setState({ redirectToReferrer: true });
      })
      .catch((error) => {
        // console.log(error)
        this.setState({ loading: false });
        if (error.response) {
          // console.log(error.response.data.message)
          this.setState({ message: error.response.data.message });
        }
        console.log(error);
      });
  };
  isDefaultHostname(hostname) {
    if (hostname.indexOf("payfren") !== -1) return true;
    else if (hostname.indexOf("gateway-in") !== -1) return true;
    else if (hostname.indexOf("localhost") !== -1) return true;
    else return false;
  }
  handleResetPass = (e) => {
    const phone = !isNaN(this.state.resetPass) && this.state.resetPass;
    const email =
      EmailValidator.validate(this.state.resetPass) && this.state.resetPass;
    const captchaRes = this.state.captchaRes;
    // const acResetPass = this.state.acResetPass && this.state.acResetPass.replace(/ - /g,'')
    // console.log(captchaRes)
    if ((phone || email) && captchaRes) {
      axios
        .post(Config.ApiUrl + "/user/resetpass", {
          phone,
          email,
          captchaRes,
          // acResetPass,
        })
        .then((res) => {
          secureStorage.setItem("resetPass", {
            phone,
            email,
            user: res.data.user,
          });
          this.setState({ verification: true, openModal: false });
        })
        .catch((err) => {
          console.log(err);
          this.reCaptcha && this.reCaptcha.current.reset();
          err.response && this.setState({ resetErrMsg: err.response.data });
        });
    }
  };
  render() {
    const { classes, t } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;
    const hostname = window.location.hostname;
    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    if (secureStorage.getItem("token")) {
      return <Redirect to="/" />;
    }
    if (this.state.verification) {
      console.log("this.state.verification", this.state.verification);
      return (
        <Redirect
          to={{ pathname: "/verification", authCode: this.state.authCode }}
        />
      );
    }

    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="google"
                      signup
                      className={classes.cardHeader}
                    >
                      {!this.isDefaultHostname(hostname) ? (
                        <img
                          src={this.state.logo}
                          className={classes.imageLogo}
                          alt={this.state.appName}
                        />
                      ) : (
                        <img
                          src={textLogo}
                          className={classes.imageLogoDefault}
                          alt={"Gateway-IN"}
                        />
                      )}
                      <h4 className={classes.cardTitle}>
                        {!this.isDefaultHostname(hostname)
                          ? this.state.appName
                          : "Gateway-IN"}{" "}
                        Dashboard
                      </h4>
                    </CardHeader>

                    <CardBody signup>
                      <CustomInput
                        id="username"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: `Username / Email / ${t("phone")}`,
                          type: "text",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: (e) =>
                            this.setState({ username: e.target.value }),
                          value: this.state.username,
                        }}
                      />
                      <CustomInput
                        id="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          onKeyDown: (e) =>
                            e.key === "Enter" && this.loginInquiry(),
                          onChange: (e) =>
                            this.setState({ password: e.target.value }),
                        }}
                      />
                      <p style={{ textAlign: "center", color: "red" }}>
                        {this.state.message}
                      </p>
                    </CardBody>
                    <div className={classes.textCenter}>
                      <Button
                        onClick={() => this.loginInquiry()}
                        round
                        color="google"
                        size="lg"
                      >
                        {t("login")}
                      </Button>
                    </div>
                    <div
                      style={{ textAlign: "center", margin: "10px 0 10px 0" }}
                    >
                      <Button
                        simple
                        round
                        color="instagram"
                        onClick={() => this.setState({ openModal: true })}
                      >
                        {t("forgetPass")}
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            <Transition.Group animation="fly down" duration={500}>
              {this.state.openModal && (
                <Modal
                  // dimmer='blurring'
                  onClose={() => this.setState({ openModal: false })}
                  // onOpen={()=>this.setState({openModal:true})}
                  open={this.state.openModal}
                  // trigger={
                  //   <div style={{textAlign:"center",margin:"-10px 0 10px 0"}}>
                  //     <Button simple round color="instagram"  onClick={()=>console.log('lupa')}>Lupa Password ?</Button>
                  //   </div>
                  // }
                >
                  <Header icon="repeat" content="Reset Password" />
                  <Modal.Content>
                    <p>{t("enterEmailOrPass")}</p>
                    <CustomInput
                      labelText="Email/No HP"
                      id="resetPass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t("enterEmailOrPass"),
                        type: "text",
                        value: this.state.resetPass,
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: (e) => {
                          this.setState({ resetErrMsg: "" });
                          const val = e.target.value;
                          if (!val) {
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          } else if (
                            isNaN(val) &&
                            !EmailValidator.validate(val)
                          )
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else if (!isNaN(val) && val.indexOf("08") !== 0)
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else if (!this.state.captchaRes)
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else
                            this.setState({
                              resetPass: val,
                              resetDisabled: false,
                            });
                        },
                        onKeyDown: (e) =>
                          e.key === "Enter" && this.handleResetPass(),
                      }}
                    />
                    <div style={{ color: "red" }}>{this.state.resetErrMsg}</div>
                    <ReCAPTCHA
                      ref={this.reCaptcha}
                      hl={this.state.selectedLang}
                      // sitekey={"6LeTB2YdAAAAAMRSlP7bunNp5XvSbZlBViFvKltd"}
                      sitekey={"6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"}
                      onChange={(e) => {
                        if (this.state.resetPass === "") {
                          this.setState({ captchaRes: e, resetDisabled: true });
                        } else if (
                          isNaN(this.state.resetPass) &&
                          !EmailValidator.validate(this.state.resetPass)
                        ) {
                          this.setState({ captchaRes: e, resetDisabled: true });
                        } else
                          this.setState({
                            captchaRes: e,
                            resetDisabled: false,
                          });
                      }}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      round
                      color="google"
                      onClick={() => this.setState({ openModal: false })}
                    >
                      cancel
                    </Button>
                    <Button
                      round
                      color="facebook"
                      onClick={this.handleResetPass}
                      disabled={this.state.resetDisabled}
                    >
                      confirm
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Transition.Group>
          </div>
          <Footer
            className={classes.footer}
            content={
              <div>
                <div className={classes.left}>
                  <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>Team</ListItem>
                  </List>
                </div>
                <div className={classes.right}>
                  <Dropdown
                    inline
                    options={[
                      { key: "id", value: "id", flag: "id", text: "ID" },
                      { key: "en", value: "en", flag: "us", text: "EN" },
                    ]}
                    defaultValue={this.state.selectedLang}
                    // value={this.state.selectedLang}
                    onChange={(e, { value }) => {
                      // this.setState({selectedLang:value})
                      i18next.changeLanguage(value);
                    }}
                    className={classes.changeLanguage}
                    renderLabel={(label) => ({
                      content: label.text + 123,
                      color: "black",
                    })}
                  />
                  &copy; Copyright {moment().year()}, Powered by{" "}
                  {this.state.info && this.state.info.aliasName
                    ? this.state.info.aliasName
                    : "GIN"}
                  .
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(Login);
