// Core
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

//Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";
// import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";

import {Label} from 'semantic-ui-react'

// Icons
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

// Libs
import axios from 'axios';
import Config from 'config'
import validator from 'validator'

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Pagination from "components/Pagination/Pagination.js"

import Table from "components/Table/Table";
import secureStorage from 'libs/secureStorage';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
	...modalStyle(theme),
	...style,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
	oddRow : {
		backgroundColor:'#e8f4f7'
	},
	evenRow :{
		backgroundColor:'#cbe3ee'
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class LoginUser extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			//user : secureStorage.getItem('user'),

			// paging states >>
			rowsPerPage : 5,
			currentPage : 1,
			totalPages : 0,
			totalData : 0,
			rows : [],
			//<<pagin states
			
			username :'',
			password : '',
			name : '',
			email : '',
			telegramId : '',
			
			groupId :'',
			
			modalMode : '',
			modalShow : false,
			modalTitle : '',

			modalName : '',
			modalAmount : '',

			selectedBill: '',
			selectedParentBill : '',

		}
	}
	componentDidMount(){
		this.loadData()
		this.loadTelegramGroup()
	}

	loadData=(params)=>{
		axios.post(Config.ApiUrl + '/user/loginuser',{
			currentPage : params && params.currentPage ? params.currentPage : 1,
			rowsPerPage : params && params.rowsPerPage? params.rowsPerPage:this.state.rowsPerPage
		})
		.then(res => {
			//console.log(params &&  params.partnerDropdown?'true':'false')
			console.log(res.data)
			this.setState({
				rows : res.data.loginUsers,
				totalPages : res.data.totalPages,
				totalData : res.data.totalData
			})
		})
		.catch(error => {
			console.log(error);
		});
			
	}
	loadTelegramGroup=()=>{
		const user = secureStorage.getItem('user')
		const groupId = user.telegramId ? user.telegramId:''
		this.setState({groupId})
	}

	handleSubmit = () =>{
		this.loadData()
	}
	
	handleDropDownChange = (e) => {
			this.setState({[e.target.name]:e.target.value})
	}
	handleClear = (e) =>{
		this.setState({
			keyword : '',
		})
		this.loadData({keyword:''})
	}

	handleShowModal = () =>{
		this.setState({modalShow:true})
	}
	

	renderStatus(bill){
		let color
		switch(bill.statusId){
			case 2 :
				color = 'twitter'
				break
			case 3 : 
				color = 'warning'
				break
			case 4 : 
				color = 'success'
				break
			case 6 : 
				color = 'danger'
				break
			default :
				color = 'facebook'
		}

		return <Badge color={color}>{bill.BillStatus.name}</Badge>
	}

	render(){
		const {classes,t} = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='big'>Login Admin</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12} style={{marginTop:20}}>
										<GridContainer justify="center">
											<GridItem xl={4} lg={4} md={6} sm={12} xs={12}>
												<Button color='instagram' round onClick={(e)=>this.setState({modalShow:true,modalTitle:t('add')+' Admin',modalMode:'add'})}  ><GroupAddIcon />{t('add')}</Button>
											</GridItem>
											{[1,2,19,20,24,26,27].includes(user.userTypeId) &&
											<GridItem xl={8} lg={8} md={6} sm={12} xs={12}>
												<GridContainer justify="center">
													<GridItem xl={10} lg={10} md={10} sm={10} xs={10}>
														<Input
															labelText="Telegram Group ID"
															id="groupId"
															formControlProps={{
																fullWidth: true
															}}
															inputProps={{
																readOnly : !this.state.editGroupId ? true : !this.state.editGroupId,
																placeholder : 'Telegram Group ID',
																value : this.state.groupId,
																onChange : (e)=> this.setState({groupId:e.target.value,errTele:''}),
															}}
														/>
														<p style={{color:'red'}}>{this.state.errTele}</p>
													</GridItem>
													<GridItem xl={2} lg={2} md={2} sm={2} xs={2}>
													{ 
													!this.state.isSaving?
													!this.state.editGroupId ?
													<Button color='warning' style={{marginTop:20}} justIcon round onClick={(e)=>{
														this.setState({editGroupId:true})
													}} ><EditIcon /></Button>
													:
													<Button color='facebook' style={{marginTop:20}} justIcon round onClick={(e)=>{
														if(this.state.groupId && !isNaN(this.state.groupId)){
															this.setState({isSaving:true})
															axios.post(Config.ApiUrl + '/user/loginuser/group',{telegramId:this.state.groupId})
															.then(res=>{
																// console.log(res.data)
																this.setState({groupId:res.data.telegramId,editGroupId:false,isSaving:false})
																const user = secureStorage.getItem('user')
																user.telegramId = res.data.telegramId
																secureStorage.setItem('user',user)
															}).catch(err=>{
																if(err.response)
																this.setState({errTele:err.response.data,isSaving:false})
																console.log(err.response)
															})
														}
													}} ><SaveIcon /></Button>
													:
														<CircularProgress style={{marginTop:20}}/>	
													}
													</GridItem>
												</GridContainer>
											</GridItem>}
										</GridContainer>
									</GridItem>
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<Table
										// togglePreview
										// sortable
										striped
										tableHead={['#','Username',t('Name'),[1,2,19,20].includes(user.userTypeId) && 'Email', [1,2,19,20].includes(user.userTypeId) && 'Telegram Id',t('actions')]}
										tableData={
											this.state.rows.map((item,index)=>{
												return [
													(index+1)+((this.state.currentPage-1)*(isNaN(this.state.rowsPerPage)? 0 : this.state.rowsPerPage )),
													item.username,
													item.name,
													[1,2,19,20].includes(user.userTypeId) ? item.email?item.email:'-':'',
													[1,2,19,20].includes(user.userTypeId) ? item.telegramId?item.telegramId:'-':'',
													<span>
														<Button color='info' size="sm" style={{marginTop:20,marginRight:20}} justIcon round onClick={(e)=>{
															this.setState({
																modalShow:true,
																modalTitle:`${t('edit')} ${item.name}`,
																modalMode:'edit',
																usernameReadOnly:true,
																username :item.username,
																name : item.name,
																email : item.email?item.email:'',
																telegramId : item.telegramId?item.telegramId:'',
															})
														}} ><EditIcon /></Button>
														<Button color='danger' size="sm" style={{marginTop:20}} justIcon round onClick={(e)=>{
															this.setState({
																modalShow:true,
																modalTitle:`${t('delete')} ${item.name}`,
																modalMode:'delete',
																username:item.username
															})
														}} ><DeleteIcon /></Button>
													</span>
												]
											})
										}
										tableFoot={
											this.state.total
										}
										// textAlignCells={['center','right','right','right','right','right','right']}
										// textAlignForCells={[0, 6, 7, 8, 9, 10, 11]}
										// textAlignHeaders={['center','right','right','right','right','right','right']}
										// textAlignForHeaders={[0, 6, 7, 8, 9, 10, 11]}
										// textAlignFooters={['center','right','right','right','right','right','right']}
										// textAlignForFooters={[0, 6, 7, 8, 9, 10, 11]}
										// rawData={this.state.extractedRows}
										// onSort={(data)=>this.setState({extractedRows:data})}
									/>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage)=>{
											if(currentPage !== this.state.currentPage){
												this.setState({currentPage})
												this.loadData({currentPage})
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5,15,25,'All']}
										defaultRowsPerPage={this.state.rowsPerPage}
										onChangeRowsPerPage={(value)=>{
											console.log(value)
											this.setState({rowsPerPage:value,currentPage:1})
											this.loadData({rowsPerPage:value,currentPage:1})
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={Transition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								{ this.state.modalMode === 'delete' ?
								<GridContainer justify='center'>
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										{t('Are you sure want to')} {this.state.modalTitle} ?
									</GridItem>
								</GridContainer>
								:
								<GridContainer justify='center'>
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<Input
											labelText="Username *"
											id="username"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												readOnly: !this.state.usernameReadOnly?false:true,
												placeholder : 'Username',
												value : this.state.username,
												onChange : (e)=> this.setState({username:e.target.value,error:''}),
											}}
										/>
										<Input
											labelText={this.state.modalMode==='add'?"Password *":'Password'}
											id="password"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type : 'password',
												placeholder : 'Password',
												value : this.state.password,
												onChange : (e)=> this.setState({password:e.target.value,error:''}),
											}}
										/>
										<Input
											labelText={t('Name')+" *"}
											id="name"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : t('Name'),
												value : this.state.name,
												onChange : (e)=> this.setState({name:e.target.value,error:''}),
											}}
										/>
										{[1,2,19,20].includes(user.userTypeId) &&
										<Input
											labelText="Email"
											id="email"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : 'Email',
												value : this.state.email,
												onChange : (e)=> this.setState({email:e.target.value,error:''}),
											}}
										/>}
										{ [1,2,19,20].includes(user.userTypeId) && 
										<Input
											labelText="Telegram ID"
											id="telegramId"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												placeholder : 'Telegram ID',
												value : this.state.telegramId,
												onChange : (e)=> this.setState({telegramId:e.target.value,error:''}),
											}}
										/>}
										<p style={{color:'red'}}>{this.state.error}</p>
									</GridItem>
								</GridContainer>
								}
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button round onClick={()=>this.handleModal('cancel')} color="warning"><CancelIcon />{t('cancel')}</Button>
								{ this.state.modalMode === 'add' ?
								<Button round onClick={()=>this.handleModal('add')} color="instagram"><AddIcon />{t('add')}</Button>:null}
								{ this.state.modalMode === 'edit' ?
								<Button round onClick={()=>this.handleModal('edit')} color="instagram"><SaveIcon />{t('save')}</Button>:null}
								{ this.state.modalMode === 'delete' ?
								<Button round onClick={()=>this.handleModal('delete')} color="danger"><DeleteIcon />{t('delete')}</Button>:null}
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
    )
	}
	handleModal = (target) =>{
		switch(target){
			case 'add':
				if(this.valid(target)){
					const {username,password,name,email,telegramId} = this.state
					console.log({username,password,name,email,telegramId})
					axios.post(Config.ApiUrl + '/user/loginuser/add',{username,password,name,email,telegramId})
					.then(res=>{
						this.loadData()
						this.setState({modalShow:false,username:'',password:'',name:'',email:'',telegramId:'',usernameReadOnly:false})
					}).catch(err=>{
						if(err.response){
							this.setState({error:err.response.data})
						}
					})
				}
				break
			case 'edit':
				if(this.valid(target)){
					const {username,password,name,email,telegramId} = this.state
					console.log({username,password,name,email,telegramId})
					axios.post(Config.ApiUrl + '/user/loginuser/update',{username,password,name,email,telegramId})
					.then(res=>{
						this.loadData()
						this.setState({modalShow:false,username:'',password:'',name:'',email:'',telegramId:'',usernameReadOnly:false})
					}).catch(err=>{
						console.log(err)
					})
				}
				break
			case 'delete':
				if(this.state.username){
					axios.post(Config.ApiUrl + '/user/loginuser/remove',{username:this.state.username})
					.then(res=>{
						this.loadData()
						this.setState({modalShow:false,username:'',password:'',name:'',email:'',telegramId:'',usernameReadOnly:false})
					}).catch(err=>{
						console.log(err)
					})
				}
				break
			default:
				this.setState({modalShow:false,username:'',password:'',name:'',email:'',telegramId:'',usernameReadOnly:false,error:''})
		}
	}
	valid(target){
		const {username,password,name,email,telegramId} = this.state
		if(!validator.isAlphanumeric(username)){
			this.setState({error:'Error : Username should be Alphanumeric characters'})
			return false
		}
		else if(target==='add' && !password){
			this.setState({error:`Error : Password can't be empty`})
			return false
		}
		else if(!name){
			this.setState({error:`Error : Name can't be empty`})
			return false
		}
		if(email && !validator.isEmail){
			this.setState({error:`Error : Invalid email`})
			return false
		}
		if(telegramId && isNaN(telegramId)){
			this.setState({error:`Error : Invalid Telegram ID`})
			return false
		}
		return true
	}
}
LoginUser.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(styles))(LoginUser);