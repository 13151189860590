import React from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// core components

import {Card,Box,CardHeader, IconButton} from "@material-ui/core"

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import axios from 'axios'
import Config from 'config'

import moment from 'moment'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'

import BaseOptions from 'content/Dashboard/BaseOptions';
import { googleColor } from 'assets/jss/material-kit-pro-react';
import { Autorenew } from '@material-ui/icons';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const styles = {
	...style,
}

class Transaction extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			startDate:'',
      endDate:'',
			chartData:[],
			categories:[],
		}
		this.source = axios.CancelToken.source()
	}
	componentDidMount(){
		
	}
	componentWillUnmount(){
		this.source.cancel('Operation cancelled')
	}
  loadTransaction = (params) =>{

		this.setState({isLoading:true})

		const payload = {
			startDate : params && params.startDate?params.startDate:this.state.startDate,
			endDate : params && params.endDate?params.endDate:this.state.endDate,
			byDate:true,
			type:'graph',
		}
		// console.log(payload)
		axios.post(Config.ApiUrl + '/transaction/dasboard',payload,{cancelToken:this.source.token})
		.then(res => {
				// console.log(res.data)
				this.setState({
					startDate : payload.startDate,
					endDate : payload.endDate,
					categories:res.data.categories,
					chartData:[{name:'Qty',type: 'area',data:res.data.sales}],
					isLoading:false
				})

		})
		.catch(err => {
			if (!axios.isCancel(err))
				this.setState({isLoading:false})
			console.log(err);
		});
	}
	getChartOptions(){
		
		return _.merge(BaseOptions(),{
			colors:[googleColor],
			stroke: { width: [2, 2, 3] },
			plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
			fill: { type: ['solid', 'gradient', 'solid'] },
			labels: this.state.categories,
			xaxis: { type: 'datetime' },
			tooltip: {
				shared: true,
				intersect: false,
				y: {
					formatter: (y) => {
						if (typeof y !== 'undefined') {
							return `${y.toFixed(0)}`;
						}
						return y;
					}
				}
			}
		})
	}
	
	render(){
		const {t} = this.props;
		return (
			<Card>
				<CardHeader title={t('Daily Transaction')}
					action={
						<IconButton aria-label="settings" onClick={()=>{
							const startDate = moment().subtract(30,'day').startOf('day')
							const endDate = moment()
							// console.log({startDate,endDate})
							this.loadTransaction({startDate,endDate})
						}}>
							<Autorenew />{t('show')}
						</IconButton>
					} 
				/>
				<Segment vertical>
					<Dimmer active={this.state.isLoading} inverted>
						<Loader />
					</Dimmer>
					<Box sx={{ p: 3, pb: 1 }} dir="ltr">
						<ReactApexChart type="line" series={this.state.chartData} options={this.getChartOptions()} height={500} />
					</Box>
				</Segment>
				
			</Card>
		)
	}
  
}

Transaction.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withTranslation(), withStyles(styles))(Transaction);