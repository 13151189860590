import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from "components/Table/Table";

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import PropTypes from 'prop-types';
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import { Label } from 'semantic-ui-react'

// Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from "@material-ui/icons/Check";



import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";


import axios from 'axios';
import Config from 'config'
import secureStorage from 'libs/secureStorage';

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";



const styles = theme => ({
	...modalStyle(theme),
	...style,
	...tooltipsStyle,
	buttons: {
		marginTop: '25px'
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

class Bank extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//user : secureStorage.getItem('user'),

			// paging states >>
			rowsPerPage: 5,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			rows: [],
			//<<pagin states

			keyword: '',

			modalMode: '',
			modalShow: false,
			modalTitle: '',

			modalBankId: '',
			modalBankName: '',
			modalAccountName: '',
			modalAccountNumber: '',
			// modalCoANo : '',

			// CoA : [],
			// selectedCoA : ''
		}
	}
	componentDidMount() {
		this.loadBank()
	}

	loadBank = (params) => {
		axios.post(Config.ApiUrl + '/bank', {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage
		})
			.then(res => {
				//console.log(params &&  params.partnerDropdown?'true':'false')
				// console.log(res.data)
				this.setState({
					rows: res.data.banks,
					totalPages: res.data.totalPages,
					totalData: res.data.totalData
				})
			})
			.catch(error => {
				console.log(error);
			});

	}
	// loadCoA(){
	// 	axios.post(Config.ApiUrl + '/bank/importCoA')
	// 	.then(res=>{
	// 		this.setState({CoA:res.data})
	// 	}).catch(err => {
	// 		console.log(err)
	// 	})
	// }

	handleAdd = (e) => {
		const user = secureStorage.getItem('user')
		// user.userTypeId===1 && this.loadCoA()
		this.setState({
			modalShow: true,
			modalTitle: 'Add Bank',
			modalMode: 'add',
			modalBankName: '',
			modalAccountName: '',
			modalAccountNumber: ''
		})
	}

	handleSubmit = () => {
		this.loadBank()
	}

	handleDropDownChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleClear = (e) => {
		this.setState({
			keyword: '',
		})
		this.loadBank({ keyword: '' })
	}

	handleCheckBox = (bankId, show) => {
		axios.post(Config.ApiUrl + '/bank/show', {
			bankId: bankId,
			show: show
		})
			.then(res => {
				//console.log(res)
			})
			.catch(error => {
				console.log(error);
			});
	}
	handleCheckLimited = (bankId, limited) => {
		axios.post(Config.ApiUrl + '/bank/limited', {
			bankId,
			limited
		})
			.then(res => {
				// console.log(res)
			})
			.catch(error => {
				console.log(error);
			});
	}
	handleCheckTl = (bankId, tl) => {
		axios.post(Config.ApiUrl + '/bank/tl', {
			bankId,
			tl
		})
			.then(res => {
				// console.log(res)
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleShowModal = () => {
		this.setState({ modalShow: true })
	}
	handleModal = (target) => {
		if (target !== 'cancel') {
			if (this.state.modalAccountName && this.state.modalAccountNumber && this.state.modalBankName) {
				const payload = {
					name: this.state.modalBankName,
					accountNumber: this.state.modalAccountNumber,
					accountName: this.state.modalAccountName,
					bankId: this.state.modalBankId
				}
				const url = target === 'add' ? '/bank/insert' : target === 'edit' ? '/bank/update' : '/bank/delete'
				// if(this.state.selectedCoA)
				// 	payload.CoA = this.state.selectedCoA

				axios.post(Config.ApiUrl + url, payload)
					.then(res => {
						this.loadBank()
						this.setState({
							modalShow: false,
							// selectedCoA:''
						})
					}).catch(err => {
						console.log(err)
					})
			}
		}
		else
			this.setState({
				modalShow: false,
				// selectedCoA: ''
			})
	}

	getHeader = () => {
		const { t } = this.props
		const user = secureStorage.getItem('user')
		if (user.id === 1)
			return ["#", "ID", t('show'), "Limited", "TL", t('name'), t('accountName'), t('accountNo'), t('actions'),]
		else
			return ["#", "ID", t('show'), t('name'), t('accountName'), t('accountNo'), t('actions'),]
	}

	render() {
		const { classes, t } = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>

						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='big'>Banks</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<div className={classNames(classes.buttons)}>
													<Button color="primary" size="sm" round onClick={this.handleAdd} ><AddCircleIcon />{t('add')} Bank</Button>
												</div>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>

											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Input
													labelText={t('search')}
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder: t('bankName'),
														value: this.state.keyword,
														onChange: (e) => { this.setState({ keyword: e.target.value }) },
														onKeyPress: (e) => { if (e.key === 'Enter') { this.setState({ keyword: e.target.value }); this.loadBank({ keyword: e.target.value }) } }
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<span>
													<div className={classNames(classes.buttonGroup, classes.buttons)}>
														<Tooltip id="tooltip-search" title={t('search')} placement="top" classes={{ tooltip: classes.tooltip }}>
															<Button color="info" size="sm" round onClick={() => this.loadBank()} className={classes.firstButton} ><SearchIcon /></Button>
														</Tooltip>
														<Tooltip id="tooltip-clear" title="Clear" placement="top" classes={{ tooltip: classes.tooltip }}>
															<Button color="info" size="sm" round onClick={() => this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
														</Tooltip>
													</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Table
									tableHead={this.getHeader()}
									tableData={
										this.state.rows && this.state.rows.length > 0 ? this.state.rows.map((bank, index) => (
											[
												(index + 1) + (isNaN(this.state.rowsPerPage) ? 0 : ((this.state.currentPage - 1) * this.state.rowsPerPage)),
												bank.id,
												<Checkbox
													id={'c' + bank.id}
													checked={this.state['c' + bank.id] !== undefined ? this.state['c' + bank.id] : bank.show}
													tabIndex={-1}
													onClick={(e) => {
														//console.log(bank.isAvailable)
														if (bank.show && this.state['c' + bank.id] === undefined) {
															//console.log(this.state['c'+bank.id] !== undefined)
															this.setState({ ['c' + bank.id]: !bank.show });
														}
														else
															this.setState({ ['c' + bank.id]: e.target.checked });
														this.handleCheckBox(bank.id, e.target.checked)
													}}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
												/>,
												user.id === 1 ?
													<Checkbox
														id={'l' + bank.id}
														checked={this.state['l' + bank.id] !== undefined ? this.state['l' + bank.id] : bank.limited}
														tabIndex={-1}
														onClick={(e) => {
															//console.log(bank.isAvailable)
															if (bank.show && this.state['l' + bank.id] === undefined) {
																//console.log(this.state['c'+bank.id] !== undefined)
																this.setState({ ['l' + bank.id]: !bank.limited });
															}
															else
																this.setState({ ['l' + bank.id]: e.target.checked });
															this.handleCheckLimited(bank.id, e.target.checked)
														}}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/> : null,
												user.id === 1 ?
													<Checkbox
														id={'tl' + bank.id}
														checked={this.state['tl' + bank.id] !== undefined ? this.state['tl' + bank.id] : bank.tl}
														tabIndex={-1}
														onClick={(e) => {
															//console.log(bank.isAvailable)
															if (bank.show && this.state['tl' + bank.id] === undefined) {
																//console.log(this.state['c'+bank.id] !== undefined)
																this.setState({ ['tl' + bank.id]: !bank.tl });
															}
															else
																this.setState({ ['tl' + bank.id]: e.target.checked });
															this.handleCheckTl(bank.id, e.target.checked)
														}}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/> : null,
												bank.name,
												bank.accountName,
												bank.accountNumber,
												// user.id === 1 ? bank.CoA.fullCoANo : null,
												<span>
													<Button color="warning" size="sm" justIcon round onClick={
														() => {
															// if (user.id === 1)
															// 	this.loadCoA()
															this.setState({
																modalMode: 'edit',
																modalTitle: `${t('edit')} ${bank.name}`,
																modalBankId: bank.id,
																modalBankName: bank.name,
																modalAccountName: bank.accountName,
																modalAccountNumber: bank.accountNumber,
																// selectedCoA: bank.CoA ? bank.CoA : '',
																modalShow: true
															})

														}
													} ><EditIcon /></Button>
													<Button color="danger" size="sm" justIcon round onClick={
														() => {
															this.setState({
																modalMode: 'delete',
																modalTitle: `${t('delete')} ${bank.name}`,
																modalBankId: bank.id,
																// selectedCoA: bank.CoA ? bank.CoA : '',
																modalShow: true
															})
														}
													} ><DeleteForeverIcon /></Button>
												</span>
											])) : []
									}
									customCellClasses={[
										classes.textCenter,
										classes.textCenter,
										classes.textCenter,
										classes.textRight
									]}
									customClassesForCells={[4, 5, 6, 7]}
									customHeadCellClasses={[
										classes.textCenter,
										classes.textCenter,
										classes.textCenter,
										classes.textRight
									]}
									customHeadClassesForCells={[4, 5, 6, 7]}
								/>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage) => {
											if (currentPage !== this.state.currentPage) {
												this.setState({ currentPage })
												this.loadBank({ currentPage })
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5, 15, 25, 'All']}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value) => {
											this.setState({ rowsPerPage: value, currentPage: 1 })
											this.loadBank({ rowsPerPage: value, currentPage: 1 })
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={Transition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								{this.state.modalMode === 'delete' ?
									<GridContainer justify='center'>
										<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
											{t('Are you sure want to')} {this.state.modalTitle} ?
										</GridItem>
									</GridContainer>
									:
									<GridContainer justify='center'>
										<GridItem xl={6} lg={6} md={6} sm={6} xs={12}>
											<Input
												labelText={t('bankName')}
												id="name"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													placeholder: t('bankName'),
													value: this.state.modalBankName,
													onChange: (e) => this.setState({ modalBankName: e.target.value }),
												}}
											/>

											{/* {user.id === 1 ? this.state.modalMode === 'add' ?
												<FormControl fullWidth className={classes.selectFormControl}>
													<InputLabel
														htmlFor="coa-select"
														className={classes.selectLabel}
													>
														Import CoA
													</InputLabel>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select
														}}
														value={this.state.selectedCoA}
														onChange={(e) => {
															this.setState({
																selectedCoA: e.target.value,
																modalBankName: e.target.value.name
															})
														}}
														inputProps={{
															name: "coaDropdown",
															id: "coaDropdown"
														}}
													>
														<MenuItem
															disabled
															classes={{
																root: classes.selectMenuItem
															}}
															value={''}
														>
															-CoA No-
														</MenuItem>
														{this.state.CoA.map((item, index) => (
															<MenuItem
																key={index}
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value={item}
															>
																{item.name + ' - ' + item.fullCoANo}
															</MenuItem>
														))}
													</Select>
												</FormControl>
												:
												<Input
													labelText="CoA No"
													id="CoANo"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder: 'CoA No',
														value: this.state.selectedCoA ? this.state.selectedCoA.name + ' - ' + this.state.selectedCoA.fullCoANo : '',
														disabled: true
													}}
												/> : null
											} */}
										</GridItem>
										<GridItem xl={6} lg={6} md={6} sm={6} xs={12}>
											<Input
												labelText={t('accountNo')}
												id="accountNumber"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													placeholder: t('accountNo'),
													value: this.state.modalAccountNumber,
													onChange: (e) => this.setState({ modalAccountNumber: e.target.value }),
												}}
											/>
											<Input
												labelText={t('accountName')}
												id="accountName"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													placeholder: t('accountName'),
													value: this.state.modalAccountName,
													onChange: (e) => this.setState({ modalAccountName: e.target.value }),
												}}
											/>
										</GridItem>
									</GridContainer>
								}
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button round onClick={() => this.handleModal('cancel')} color="danger">{t('cancel')}</Button>
								{this.state.modalMode === 'add' ?
									<Button round onClick={() => this.handleModal('add')} color="success">Submit</Button> : null}
								{this.state.modalMode === 'edit' ?
									<Button round onClick={() => this.handleModal('edit')} color="success">{t('save')}</Button> : null}
								{this.state.modalMode === 'delete' ?
									<Button round onClick={() => this.handleModal('delete')} color="success">{t('delete')}</Button> : null}
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
		)
	}
}
Bank.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(styles))(Bank);