import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import GSearchActions from "components/GSearchActions";
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";

import {
  Dropdown,
  Statistic,
  Dimmer,
  Loader,
  Segment,
  Label,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";
import CButton from "components/CustomButtons/Button";

import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "components/Badge/Badge.js";
import Input from "components/CustomInput/CustomInput.js";

import moment from "moment";
import secureStorage from "libs/secureStorage";
import _ from "lodash";

import { SalesType, DirectUserType, EndUsers } from "content/User/libs";
import {
  CircularProgress,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Close, FileCopy } from "@material-ui/icons";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import ReactJson from "react-json-view";
import modalReceipt from "helpers/modalReceipt";

const styles = (theme) => ({
  ...modalStyle(theme),
  ...style,
  oddRow: {
    backgroundColor: "#e8f4f7",
  },
  evenRow: {
    backgroundColor: "#cbe3ee",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    // overflowY: 'scroll'
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    overflow: "auto",
    "& > tbody > tr, & > thead > tr, & > tfoot > tr,": {
      height: "auto",
    },
  },
});

class TransactionPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPartners: [],
      partners: [],

      selectedCategories: [],
      categories: [],

      selectedProducts: [],
      products: [],

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      total: null,

      isLoading: false,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");

    this.loadCategory();
    this.loadProduct();
    user.userTypeId === 1 && this.loadPartner();
  }

  loadCategory() {
    axios
      .post(Config.ApiUrl + "/transaction/category")
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadProduct() {
    const selectedCategories = this.state.selectedCategories;

    const payload = {
      selectedCategories,
    };

    axios
      .post(Config.ApiUrl + "/transaction/product", payload)
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPartner() {
    axios
      .post(Config.ApiUrl + "/transaction/partner")
      .then((res) => {
        this.setState({ partners: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadTransaction = (params) => {
    const user = secureStorage.getItem("user");

    this.setState({ isLoading: true });
    const payload = {
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),

      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,

      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
    };

    axios
      .post(Config.ApiUrl + "/transaction/bypartner", payload)
      .then((res) => {
        //console.log(res.data.sales)
        this.setState({
          rows: res.data.rows,
          total: res.data.total,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  render() {
    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              {/* FILTER */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                {/* TITLE & DATE */}
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">
                      {t("transactionPartner").toUpperCase()}
                    </Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      onSubmit={this.loadTransaction}
                    />
                  </GridItem>
                </GridContainer>
                {/* CATEGORY, PRODUCT, PARTNER */}
                <GridContainer justify="center">
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder={t("category")}
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedCategories}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            selectedCategories: value,
                            selectedProducts: [],
                          },
                          () => this.loadProduct()
                        );
                      }}
                      options={this.state.categories.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  {/* Product */}
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="ITEM"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedProducts}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedProducts: value,
                          selectedCategories: [],
                        });
                      }}
                      options={this.state.products.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  {/* Partner */}
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="Partner"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedPartners}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedPartners: value,
                        });
                      }}
                      options={this.state.partners.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* TABLE LIST */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>

                  <div className={classes.tableResponsive}>
                    <Table selectable unstackable className={classes.table}>
                      <Table.Header>
                        <Table.Row>
                          {this.getHeader().map((header, key) => (
                            <Table.HeaderCell
                              key={key}
                              textAlign={header.align}
                            >
                              {header.caption}
                            </Table.HeaderCell>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {_.orderBy(this.state.rows, "productName").map(
                          (item, index) => {
                            const revenue = item.revenue ? item.revenue : 0;
                            const partner = item.partner ? item.partner : 0;
                            const totalAmount = partner + item.amount;

                            return (
                              <Table.Row
                                key={index}
                                className={
                                  index % 2 === 0
                                    ? classes.evenRow
                                    : classes.oddRow
                                }
                              >
                                <Table.Cell>{index + 1}</Table.Cell>
                                <Table.Cell>{item.productName}</Table.Cell>
                                <Table.Cell textAlign="right">
                                  {fnumber(item.qty)}
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {fnumber(revenue)}
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {fnumber(partner)}
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {fnumber(item.amount)}
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {fnumber(totalAmount)}
                                </Table.Cell>
                              </Table.Row>
                            );
                          }
                        )}
                      </Table.Body>
                      {this.state.total && this.state.rows.length > 1 && (
                        <Table.Footer>
                          <Table.Row style={{ fontWeight: "bold" }}>
                            <Table.Cell></Table.Cell>
                            <Table.Cell textAlign="right">
                              Grand Total
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.qty)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.revenue)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.partner)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.amount)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(
                                this.state.total.partner +
                                  this.state.total.amount
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Footer>
                      )}
                    </Table>
                  </div>
                </Segment>
              </GridItem>

              {/* PAGING */}
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 10, 25, "All"]}
                    defaultRowsPerPage={10}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({
                        rowsPerPage: value,
                        currentPage: 1,
                      });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }

  getHeader() {
    const { t } = this.props;

    const header = [
      { caption: "#", align: "left" },
      { caption: "Item", align: "left" },
      { caption: "Qty", align: "right" },
      { caption: t("revenue"), align: "right" },
      { caption: "Partner", align: "right" },
      { caption: t("Amount"), align: "right" },
      { caption: t("Total Amount"), align: "right" },
    ];

    return header;
  }
}

TransactionPartner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(TransactionPartner);
