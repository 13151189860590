import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Table from "components/Table/Table"
import axios from "axios"
import Config from "config"
import PropTypes from "prop-types"
import classNames from "classnames"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import { fnumber } from "components/Helper"

import Input from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button"

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

import Zoom from "@material-ui/core/Zoom"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/DeleteForever"

import { Redirect } from "react-router-dom"
import secureStorage from "libs/secureStorage"

const styles = (theme) => ({
	...modalStyle(theme),
	...style,
	buttons: {
		marginTop: "30px",
	},
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />
})

class AdminNominal extends React.Component {
	constructor(prop) {
		super(prop)
		this.state = {
			rows: [],
			product: null,
			modalShow: false,
			selectedAdmin: [],

			partnerDropdown: "",
			partners: [],

			priceDropdown: "",
			partnerPrice: [],

			inputAddAdmin: "", // form unput admin
			inputAdmin: "",

			inputRevenue: "",
			inputCa3: "",
			inputSubca3: "",
			inputUser3: "",

			inputH2hTl: "",

			inputSalesDm2: "",
			inputUser2: "",

			inputH2hDm: "",

			inputMitra5: "",
			inputCa5: "",
			inputSubca15: "",
			inputSubca25: "",
			inputUser5: "",

			modalTitle: "",
			modalBody: <div></div>,
		}
		this._isMounted = false
	}

	componentDidMount() {
		this._isMounted = true
		this._isMounted &&
			this.setState({
				product: this.props.location.product,
				partnerPrice:
					this.props.location.product &&
					this.props.location.product.ProductSwitchings.map((ps) => (ps.priority === 1 ? ps.price : null)).filter(
						(e) => e != null
					),
			})
		// this._isMounted && this.props.location.product && this.loadPartner()
		this.props.location.product && this.loadAdmin(this.props.location.product)
	}
	componentWillUnmount() {
		this._isMounted = false
	}
	loadAdmin = (product) => {
		axios
			.post(Config.ApiUrl + "/product/admin/get", {
				productId: product ? product.id : this.state.product.id,
			})
			.then((res) => {
				//console.log(res.data)
				this.setState({ rows: res.data })
			})
			.catch((error) => {
				console.log(error)
			})
	}
	loadPartner() {
		axios
			.post(Config.ApiUrl + "/product/partner/dropdown", {
				product: this.props.location.product,
			})
			.then((res) => {
				this._isMounted &&
					this.setState({
						partners: res.data,
						partnerDropdown: this.props.location.product.ProductSwitchings[0].partnerId,
					})
			})
			.catch((error) => {
				console.log(error)
			})
	}
	handleDropdown = (e) => {
		if (e.target.name === "partnerDropdown") {
			//console.log(e.target)
			axios
				.post(Config.ApiUrl + "/product/price/get", {
					partnerId: e.target.value,
					productId: this.state.product.id,
				})
				.then((res) => {
					this.setState({
						partnerPrice: res.data.map((ps) => ps.price),
						partnerDropdown: e.target.value,
					})
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
	handleAddAdmin = (e) => {
		const nominal = this.state.inputAddAdmin
		if (!isNaN(nominal)) {
			axios
				.post(Config.ApiUrl + "/product/admin/create", {
					nominal: nominal,
					productId: this.state.product.id,
				})
				.then((res) => {
					this.setState({ inputAddAdmin: "" })
					this.loadAdmin()
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
	handleDeleteAdmin = () => {
		axios
			.post(Config.ApiUrl + "/product/admin/delete", {
				adminNominalId: this.state.selectedAdmin.id,
			})
			.then((res) => {
				this.setState({ modalShow: false })
				this.loadAdmin()
			})
			.catch((error) => {
				console.log(error)
			})
	}
	handleGoBack = () => {
		secureStorage.setItem("backPayload", this.props.location.backPayload)
		this.props.history.goBack()
	}

	render() {
		const { classes } = this.props
		const { product } = this.props.location
		if (!product) return <Redirect to="/product" />
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
										<Button onClick={this.handleGoBack} color="danger" round>
											<ArrowBackIosIcon />
											Back
										</Button>
										<br />
										<h4 className={classes.title}>Item : {product.name}</h4>
									</GridItem>
									<GridItem xl={9} lg={9} md={9} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Input
													labelText="Partner"
													id="partner"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														value:
															this.props.location.product &&
															this.props.location.product.ProductSwitchings.map((ps) =>
																ps.priority === 1 ? ps.Partner.name : null
															).filter((e) => e != null),
														disabled: true,
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Input
													labelText="Partner Price"
													id="partnerPrice"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														value: this.state.partnerPrice.map((p) => fnumber(p)),
														disabled: true,
													}}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Input
													labelText="Admin Nominal"
													id="inputAddAdmin"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														placeholder: "Add Admin",
														value: this.state.inputAddAdmin,
														onChange: (e) =>
															this.setState({
																inputAddAdmin: e.target.value,
															}),
														onKeyPress: (e) => e.key === "Enter" && this.handleAddAdmin(e),
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={3} sm={6} xs={6}>
												<Button
													onClick={this.handleAddAdmin}
													color="info"
													size="sm"
													round
													justIcon
													className={classes.buttons}
												>
													<AddIcon />
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Table
									tableHead={[
										"#",
										"ADMIN",
										"REVENUE",
										"CA3",
										"SU3",
										"US3",
										"HTTL",
										"SL2",
										"US2",
										"HTDM",
										"MIF5",
										"CA5",
										"SO5",
										"ST5",
										"US5",
										"ACTIONS",
									]}
									tableData={
										this.state.rows.length > 0
											? this.state.rows.map((admin, index) => {
													const partnerFee =
														this.state.product && this.state.product.ProductCategory.productTypeId === 1
															? this.state.partnerPrice[0]
															: 0
													return [
														index + 1,
														fnumber(admin.nominal),
														fnumber(admin.DefaultAdminFees[0].amount),
														fnumber(admin.DefaultAdminFees[1].amount),
														fnumber(admin.DefaultAdminFees[2].amount),
														fnumber(
															admin.nominal -
																partnerFee -
																admin.DefaultAdminFees[0].amount -
																admin.DefaultAdminFees[1].amount -
																admin.DefaultAdminFees[2].amount
														),
														fnumber(admin.nominal - partnerFee - admin.DefaultAdminFees[0].amount),
														fnumber(admin.DefaultAdminFees[5].amount),
														fnumber(
															admin.nominal -
																partnerFee -
																admin.DefaultAdminFees[0].amount -
																admin.DefaultAdminFees[5].amount
														),
														fnumber(admin.nominal - partnerFee - admin.DefaultAdminFees[0].amount),
														fnumber(admin.DefaultAdminFees[13].amount),
														fnumber(admin.DefaultAdminFees[14].amount),
														fnumber(admin.DefaultAdminFees[15].amount),
														fnumber(admin.DefaultAdminFees[16].amount),
														fnumber(
															admin.nominal -
																partnerFee -
																admin.DefaultAdminFees[0].amount -
																admin.DefaultAdminFees[13].amount -
																admin.DefaultAdminFees[14].amount -
																admin.DefaultAdminFees[15].amount -
																admin.DefaultAdminFees[16].amount
														),
														// ...admin.DefaultAdminFees.map(daf=>fnumber(daf.amount)),
														<Button onClick={() => this.initAdminModal(admin)} color="warning" size="sm" round justIcon>
															<EditIcon />
														</Button>,
													]
											  })
											: []
									}
									customCellClasses={[
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textCenter,
									]}
									customClassesForCells={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
									customHeadCellClasses={[
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textRight,
										classes.textCenter,
									]}
									customHeadClassesForCells={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
								/>
							</GridItem>
						</GridContainer>
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal,
							}}
							open={this.state.modalShow}
							TransitionComponent={Transition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
								<h4 className={classes.modalTitle}>{product.name}</h4>
							</DialogTitle>
							<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
								<GridContainer justify="center">
									<GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
										<Input
											labelText="Admin"
											id="inputAdmin"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Admin",
												value: this.state.inputAdmin,
												onChange: this.handleInput,
												// value: fnumber(this.state.selectedAdmin.nominal),
												// disabled: true,
											}}
										/>
										{this.state.partnerPrice.length > 1 ? (
											<FormControl fullWidth className={classes.selectFormControl}>
												<InputLabel htmlFor="price-select" className={classes.selectLabel}>
													Partner
												</InputLabel>
												<Select
													MenuProps={{
														className: classes.selectMenu,
													}}
													classes={{
														select: classes.select,
													}}
													value={this.state.priceDropdown}
													onChange={this.handleInput}
													inputProps={{
														name: "priceDropdown",
														id: "priceDropdown",
													}}
												>
													<MenuItem
														disabled
														classes={{
															root: classes.selectMenuItem,
														}}
														value={""}
													>
														Partner
													</MenuItem>
													{this.state.partnerPrice.map((item, index) => (
														<MenuItem
															key={index}
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected,
															}}
															value={item}
														>
															{fnumber(item)}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										) : (
											<Input
												labelText="Partner"
												id="partnerPriceShow"
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													placeholder: "Partner Price",
													value:
														this.state.product && this.state.product.ProductCategory.productTypeId === 1
															? this.state.partnerPrice.map((p) => fnumber(p))
															: ["0"],
													disabled: true,
												}}
											/>
										)}
										<Input
											labelText="Revenue"
											id="inputRevenue"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Revenue",
												value: this.state.inputRevenue,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="H2H TL"
											id="inputH2hTl"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Host to Host TL",
												value: this.state.inputH2hTl,
												//onChange : (e)=> this.setState({inputH2hTl:e.target.value}),
												disabled: true,
											}}
										/>
										<Input
											labelText="H2H DM"
											id="inputH2hDm"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Host to Host DM",
												value: this.state.inputH2hDm,
												disabled: true,
												//onChange : (e)=> this.setState({inputH2hDm:e.target.value}),
											}}
										/>
									</GridItem>
									<GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
										<Input
											labelText="Mitra 5"
											id="inputMitra5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Mitra 5",
												value: this.state.inputMitra5,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="CA 5"
											id="inputCa5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "CA 5",
												value: this.state.inputCa5,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="Subca1 5"
											id="inputSubca15"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Subca1 5",
												value: this.state.inputSubca15,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="Subca2 5"
											id="inputSubca25"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Subca2 5",
												value: this.state.inputSubca25,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="User 5"
											id="inputUser5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "User 5",
												value: this.state.inputUser5,
												//onChange : (e)=> this.setState({inputUserTl:e.target.value}),
												disabled: true,
											}}
										/>
									</GridItem>

									<GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
										<Input
											labelText="CA 3"
											id="inputCa3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "CA 3",
												value: this.state.inputCa3,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="Subca 3"
											id="inputSubca3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Sub CA 3",
												value: this.state.inputSubca3,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="User 3"
											id="inputUser3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "User 3",
												value: this.state.inputUser3,
												//onChange : (e)=> this.setState({inputUserTl:e.target.value}),
												disabled: true,
											}}
										/>
										<Input
											labelText="SLDM 2"
											id="inputSalesDm2"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "Sales Deposit",
												value: this.state.inputSalesDm2,
												onChange: this.handleInput,
											}}
										/>
										<Input
											labelText="User DM 2"
											id="inputUser2"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												placeholder: "User DM 2",
												value: this.state.inputUser2,
												//onChange : (e)=> this.setState({inputUserDm:e.target.value}),
												disabled: true,
											}}
										/>
									</GridItem>

									<GridItem xl={4} lg={4} md={4} sm={4} xs={12}></GridItem>

									<GridItem xl={4} lg={4} md={4} sm={4} xs={12}></GridItem>
								</GridContainer>
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button onClick={() => this.handleDeleteAdmin()} color="danger" round>
									<DeleteIcon />
									Delete
								</Button>
								<Button onClick={() => this.setState({ modalShow: false })} color="warning" round>
									Cancel
								</Button>
								<Button onClick={() => this.handleSubmit()} color="success" round>
									Submit
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
		)
	}

	handleInput = (e) => {
		//console.log(e.target.value)
		const value = e.target.value === "" || isNaN(e.target.value) ? 0 : parseInt(e.target.value)
		const inputAdmin = e.target.id === "inputAdmin" ? value : this.state.inputAdmin

		let user2 = inputAdmin - this.state.priceDropdown
		let user3 = inputAdmin - this.state.priceDropdown
		let user5 = inputAdmin - this.state.priceDropdown
		let h2hTl = inputAdmin - this.state.priceDropdown
		let h2hDm = inputAdmin - this.state.priceDropdown

		// let user2 = this.state.selectedAdmin.nominal - this.state.priceDropdown
		// let user3 = this.state.selectedAdmin.nominal - this.state.priceDropdown
		// let user5 = this.state.selectedAdmin.nominal - this.state.priceDropdown
		// let h2hTl = this.state.selectedAdmin.nominal - this.state.priceDropdown
		// let h2hDm = this.state.selectedAdmin.nominal - this.state.priceDropdown

		//console.log([value,isNaN(e.target.value),e.target.value])

		user2 -= e.target.id === "inputRevenue" ? value : parseInt(this.state.inputRevenue)
		user3 -= e.target.id === "inputRevenue" ? value : parseInt(this.state.inputRevenue)
		user5 -= e.target.id === "inputRevenue" ? value : parseInt(this.state.inputRevenue)
		h2hTl -= e.target.id === "inputRevenue" ? value : parseInt(this.state.inputRevenue)
		h2hDm -= e.target.id === "inputRevenue" ? value : parseInt(this.state.inputRevenue)

		user3 -= e.target.id === "inputCa3" ? value : parseInt(this.state.inputCa3)
		user3 -= e.target.id === "inputSubca3" ? value : parseInt(this.state.inputSubca3)

		user5 -= e.target.id === "inputMitra5" ? value : parseInt(this.state.inputMitra5)
		user5 -= e.target.id === "inputCa5" ? value : parseInt(this.state.inputCa5)
		user5 -= e.target.id === "inputSubca15" ? value : parseInt(this.state.inputSubca15)
		user5 -= e.target.id === "inputSubca25" ? value : parseInt(this.state.inputSubca25)
		// user5 -= e.target.id === 'inputUser5' ? value : parseInt(this.state.inputUser5)

		user2 -= e.target.id === "inputSalesDm2" ? value : parseInt(this.state.inputSalesDm2)

		this.setState({
			[e.target.id ? e.target.id : e.target.name]: value,
			inputUser2: user2,
			inputUser3: user3,
			inputUser5: user5,
			inputH2hTl: h2hTl,
			inputH2hDm: h2hDm,
		})
	}

	initDeleteModal = (admin) => {
		this.setState({
			selectedAdmin: admin,
			modalShow: true,
			modalTitle: "Delete",
			modalBody: <span>Are you suse want to delete admin {admin.nominal}</span>,
		})
	}

	initAdminModal = (admin) => {
		const partnerFee =
			this.state.product && this.state.product.ProductCategory.productTypeId === 1 ? this.state.partnerPrice[0] : 0
		this.setState({
			selectedAdmin: admin,
			modalShow: true,
			priceDropdown: partnerFee,

			inputAdmin: admin.nominal,
			inputRevenue: admin.DefaultAdminFees[0].amount,

			inputCa3: admin.DefaultAdminFees[1].amount,
			inputSubca3: admin.DefaultAdminFees[2].amount,
			inputUser3:
				admin.nominal -
				(partnerFee +
					admin.DefaultAdminFees[0].amount +
					admin.DefaultAdminFees[1].amount +
					admin.DefaultAdminFees[2].amount), // admin.DefaultAdminFees[3].amount,

			inputH2hTl: admin.nominal - partnerFee - admin.DefaultAdminFees[0].amount, //admin.DefaultAdminFees[4].amount,

			inputSalesDm2: admin.DefaultAdminFees[5].amount,
			inputUser2: admin.nominal - (partnerFee + admin.DefaultAdminFees[0].amount + admin.DefaultAdminFees[5].amount), //admin.DefaultAdminFees[6].amount,

			inputH2hDm: admin.nominal - partnerFee - admin.DefaultAdminFees[0].amount, //admin.DefaultAdminFees[7].amount,

			inputMitra5: admin.DefaultAdminFees[13].amount,
			inputCa5: admin.DefaultAdminFees[14].amount,
			inputSubca15: admin.DefaultAdminFees[15].amount,
			inputSubca25: admin.DefaultAdminFees[16].amount,
			inputUser5:
				admin.nominal -
				(partnerFee +
					admin.DefaultAdminFees[0].amount +
					admin.DefaultAdminFees[13].amount +
					admin.DefaultAdminFees[14].amount +
					admin.DefaultAdminFees[15].amount +
					admin.DefaultAdminFees[16].amount),
		})
	}

	handleSubmit = (target) => {
		// const selectedAdmin = this.state.selectedAdmin
		const payload = {
			adminNominalId: this.state.selectedAdmin.id,
			admin: this.state.inputAdmin,
			revenue: this.state.inputRevenue,

			ca3: this.state.inputCa3,
			subca3: this.state.inputSubca3,
			user3: this.state.inputUser3,

			h2hTl: this.state.inputH2hTl,

			salesDm2: this.state.inputSalesDm2,
			user2: this.state.inputUser2,

			h2hDm: this.state.inputH2hDm,

			mitra5: this.state.inputMitra5,
			ca5: this.state.inputCa5,
			subca15: this.state.inputSubca15,
			subca25: this.state.inputSubca25,
			user5: this.state.inputUser5,
		}

		// console.log({ selectedAdmin, payload })
		axios
			.post(Config.ApiUrl + "/product/defaultfee/update", payload)
			.then((res) => {
				this.loadAdmin()
			})
			.catch((error) => {
				console.log(error)
			})
		this.setState({ modalShow: false })
	}
}

AdminNominal.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AdminNominal)
