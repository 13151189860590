// Core
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

// UI compononents
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import Badge from "components/Badge/Badge.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Dropdown,
  Dimmer,
  Loader,
  Segment,
  Label,
  Confirm,
  Transition,
  Checkbox as CheckboxSem,
} from "semantic-ui-react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Check from "@material-ui/icons/Check";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Save from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/SendRounded";
import CloseIcon from "@material-ui/icons/Close";

// Styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

// libs
import Config from "config";
import axios from "axios";
import { fnumber } from "components/Helper";
import _ from "lodash";

// Components
import ExportExcel from "components/ExportExcel";
import secureStorage from "libs/secureStorage";

import { isManagable, isAdminManagable, isDmRealtime } from "content/User/libs";

const style = (theme) => ({
  ...modalStyle(theme),
  ...contactUsStyle,
  ...tooltipsStyle,
  buttons: {
    marginTop: "25px",
  },
});

const CustomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      selectedUser: "",
      // paging states >>
      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states
      keyword: "",

      selectedProducts: [],
      products: [],

      selectedCategories: [],
      categories: [],

      txtUsername: "",
      balance: 0,
      mutation: "",
      balanceLimit: 0,
      balanceLimitReadOnly: true,
      balanceOutstanding: 0,
      balanceOutstandingReadOnly: true,
      buttonSendBlock: false,

      selectedProduct: "",
      selectedAdminNominal: "",
      selectedAdminNominalId: "",
      selectedFeeId: "",
      selectedProductTypeId: "",

      selectedItem: "",

      partnerPrice: "",
      inputRevenue: "",

      confirmShow: false,
      modalShow: false,
      modalTitle: "",
      modalBody: "",

      isVerified: false,

      transferPayload: "",
      remark: "",

      ip: "",
      newIp: "",

      isD0: false,
      isDm: false,
      isRealTime: false,
      isAdministrator: false,

      partners: [],
      selectedPartner: "",

      extractedRows: [],
      showAdminFee: false,

      showModalAdmin: true,

      isLoading: false,
      buttonTransferDisabled: false,
    };
    this.exportSell = React.createRef();
    this.exportBuy = React.createRef();
    this.inputBlockRef = React.createRef();
  }
  componentDidMount() {
    const user = secureStorage.getItem("user");
    const selectedUser = this.props.location.selectedUser;
    console.log({ selectedUser });
    if (secureStorage.getItem("user"))
      this.setState({ user: secureStorage.getItem("user") });
    if (selectedUser)
      this.setState({
        isD0: selectedUser.isD0,
        isDm: selectedUser.isDm,
        isRealTime: selectedUser.isRealTime,
        isAdministrator: selectedUser.isAdministrator,
      });

    selectedUser && this.loadBalance(selectedUser);

    selectedUser && this.initAdminFee();

    selectedUser &&
      this.setState({ ip: selectedUser.ip, newIp: selectedUser.ip });

    // selectedUser && [2,4,5].indexOf(selectedUser.userTypeId) !== -1 && this.loadBalanceLimit(selectedUser)
    selectedUser &&
      isManagable(user, selectedUser) &&
      this.loadBalanceLimit(selectedUser);
    user && user.userTypeId === 1 && this.loadBalanceBlock(selectedUser);
  }
  initAdminFee() {
    const user = secureStorage.getItem("user");
    const selectedUser = this.props.location.selectedUser;
    let showAdminFee = false;
    if (user.userTypeId === 1) {
      if (
        [2, 6, 5, 8, 9, 12, 13, 14, 19, 24, 26].indexOf(
          selectedUser.userTypeId
        ) !== -1
      )
        showAdminFee = true;
    } else if (
      user.userTypeId + 1 === selectedUser.userTypeId &&
      user.userTypeId !== 12
    )
      showAdminFee = true;
    if (showAdminFee) {
      this.loadCategory();
      this.loadProduct();
      showAdminFee && selectedUser && this.loadAdminFee();
      this.setState({ showAdminFee: showAdminFee });
    }
  }
  loadBalanceLimit(selectedUser) {
    const user = secureStorage.getItem("user");
    const url =
      user.userTypeId === 1 ? "/user/balancelimit" : "/user/ca/ceiling";
    axios
      .post(Config.ApiUrl + url, { selectedUser })
      .then((res) => {
        // console.log(res.data)
        this.setState({ balanceLimit: res.data ? res.data : 0 });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadBalanceBlock(selectedUser) {
    axios
      .post(Config.ApiUrl + "/user/balanceBlock/get", { selectedUser })
      .then((res) => {
        this.setState({ balanceOutstanding: res.data ? res.data : 0 });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadBalance(selectedUser) {
    const { balance } = this.props.location.selectedUser;
    this.setState({
      balance,
      selectedUser,
    });
  }
  loadCategory() {
    axios
      .post(Config.ApiUrl + "/product/category/dropdown")
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  loadProduct() {
    axios
      .post(Config.ApiUrl + "/product/dropdown")
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadAdminFee = (params) => {
    this.setState({ isLoading: true });
    axios
      .post(Config.ApiUrl + "/user/fee", {
        categories:
          params && params.selectedCategories
            ? params.selectedCategories
            : this.state.selectedCategories,
        products:
          params && params.selectedProducts
            ? params.selectedProducts
            : this.state.selectedProducts,
        user: this.props.location.selectedUser,
        keyword: params && params.keyword ? params.keyword : this.state.keyword,
        // Pagination payload
        currentPage:
          params && params.currentPage
            ? params.currentPage
            : this.state.currentPage,
        rowsPerPage:
          params && params.rowsPerPage
            ? params.rowsPerPage
            : this.state.rowsPerPage,
      })
      .then((res) => {
        //console.log(res.data.products)
        // console.log(res.data.totalPages)
        // console.log(res.data.totalData)
        this.setState({
          rows: res.data.sortedProducts,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          isLoading: false,
        });
        this.extractRows(res.data.sortedProducts);
        // console.log(res.data.sortedProducts)
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  sellDataset(item, index) {
    return {
      no:
        index +
        1 +
        (isNaN(this.state.rowsPerPage)
          ? 0
          : (this.state.currentPage - 1) * this.state.rowsPerPage),
      id: item.id.toString().padStart(4, 0),
      item: item.name,
      kategori: item.category,
      admin: item.adminNominal,
      harga: item.price + item.revenue,
    };
  }
  buyDataset(item, index) {
    return {
      no:
        index +
        1 +
        (isNaN(this.state.rowsPerPage)
          ? 0
          : (this.state.currentPage - 1) * this.state.rowsPerPage),
      id: item.id.toString().padStart(4, 0),
      item: item.name,
      kategori: item.category,
      admin: item.adminNominal,
      hargaBeli: item.price,
      revenue: item.revenue,
      hargaJual: item.price + item.revenue,
    };
  }
  extractRows = (rows) => {
    const excelBuyDataset = [];
    const excelSellDataset = [];
    const { selectedUser } = this.props.location;
    const user = secureStorage.getItem("user") && secureStorage.getItem("user");
    // console.log(rows)
    if (
      (user.userTypeId === 1 && [5, 8, 13].includes(selectedUser.userTypeId)) ||
      (user.userTypeId === 24 && [25].includes(selectedUser.userTypeId))
    )
      rows.map((item, index) => {
        if (item.isOpen) {
          excelSellDataset.push(this.sellDataset(item, index));
          excelBuyDataset.push(this.buyDataset(item, index));
        }
        return item;
      });
    else
      rows.map((item, index) => {
        excelSellDataset.push(this.sellDataset(item, index));
        excelBuyDataset.push(this.buyDataset(item, index));
        return item;
      });
    this.exportSell.current.updateDataset(excelSellDataset);
    this.exportBuy.current.updateDataset(excelBuyDataset);
  };

  handleCheckBox = (productId, userId, isOpen) => {
    const user = secureStorage.getItem("user");
    const url = user.userTypeId !== 1 ? "/ca" : "";
    axios
      .post(Config.ApiUrl + `/user${url}/product/open`, {
        productId,
        userId,
        isOpen,
      })
      .then((res) => {
        //console.log(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  transferBalance = (e) => {
    //console.log(params)
    this.setState({ buttonTransferDisabled: true });
    const payload = this.state.transferPayload;
    payload.remark = this.state.remark;
    axios
      .post(`${Config.ApiUrl}/user/balance/${payload.action}`, payload)
      .then((res) => {
        // console.log(res.data)
        this.setState({
          remark: "",
          balance: res.data.balance,
          mutation: "",
          modalShow: false,
          buttonTransferDisabled: false,
        });
        this.props.onBalanceChange();
      })
      .catch((error) => {
        this.setState({
          log: error + "",
          buttonTransferDisabled: false,
          remark: "",
        });
      });
  };
  handleTransfer = (action) => {
    if (this.state.mutation) {
      const amount = parseInt(this.state.mutation.replace(/\./g, ""));
      if (amount > 0) {
        const transferPayload = {
          correspondent: this.state.selectedUser,
          amount: amount,
          action,
        };
        this.setState({
          buttonTransferDisabled: false,
          transferPayload,
          modalShow: true,
          modalTitle: `${action} ${action === "transfer" ? "to" : "from"} ${
            this.state.selectedUser.name
          }`.toUpperCase(),
          modalBody: "transfer",
        });
      }
    }
  };

  handleConvert = (e) => {
    const valStr = e.target.value.replace(/\./g, "");
    let value = isNaN(valStr) || valStr === "" ? 0 : parseInt(valStr);
    if (value < 0) value = 0;
    this.setState({ mutation: fnumber(value) });
  };
  handleClear = (e) => {
    this.setState({
      keyword: "",
      selectedCategories: [],
      selectedProducts: [],
    });
    this.loadAdminFee({
      keyword: "",
      selectedCategories: [],
      selectedProducts: [],
    });
  };
  // isManagable(user,selectedUser){
  // 	if((user.userTypeId === 1 && [2,5,19,24,26].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 2 && [4].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 19 && [20].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 20 && [23].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 24 && [25].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 26 && [27].includes(selectedUser.userTypeId)) ||
  // 		(user.userTypeId === 27 && [29].includes(selectedUser.userTypeId)) ){
  // 		return true
  // 	}
  // 	return false
  // }

  render() {
    const { classes, t } = this.props;
    const { selectedUser } = this.props.location;
    const user = secureStorage.getItem("user");
    if (!selectedUser) return <Redirect to="/User" />;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Button
                      onClick={(e) => {
                        secureStorage.setItem(
                          "backPayload",
                          this.props.location.backPayload
                        );
                        this.props.history.goBack();
                      }}
                      color="danger"
                      round
                    >
                      <ArrowBackIosIcon />
                      {t("back")}
                    </Button>
                    <br />
                    <Label size="big">{selectedUser.name}</Label>
                    {[1, 24].includes(user.userTypeId) &&
                      ![24].includes(selectedUser.userTypeId) &&
                      selectedUser.ip && (
                        <p>
                          <Button
                            size="sm"
                            round
                            color="twitter"
                            onClick={this.initEditIp}
                          >
                            IP {this.state.ip} <EditIcon />
                          </Button>
                        </p>
                      )}
                    {(([1].includes(user.userTypeId) &&
                      [5, 8, 13, 25].includes(selectedUser.userTypeId)) ||
                      ([24].includes(user.userTypeId) &&
                        [25].includes(selectedUser.userTypeId))) &&
                      !selectedUser.isVerified &&
                      !this.state.isVerified && (
                        <div>
                          <p>
                            <Button
                              size="sm"
                              round
                              color="success"
                              onClick={() =>
                                this.setState({ confirmShow: true })
                              }
                            >
                              <VerifiedUser />
                              Activate
                            </Button>
                          </p>
                          <Transition.Group animation="drop" duration={200}>
                            {this.state.confirmShow && (
                              <Confirm
                                dimmer="blurring"
                                open={this.state.confirmShow}
                                header="Activate H2H"
                                content={`Activate ${selectedUser.name} for Production Mode`}
                                cancelButton="Cancel"
                                confirmButton="Confirm"
                                onCancel={() =>
                                  this.setState({ confirmShow: false })
                                }
                                onConfirm={() => {
                                  const url =
                                    user.userTypeId !== 1 ? "ca/" : "";
                                  console.log({ selectedUser });
                                  axios
                                    .post(
                                      Config.ApiUrl + `/user/${url}activate`,
                                      { id: selectedUser.id, selectedUser }
                                    )
                                    .then((res) => {
                                      this.setState({ isVerified: true });
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                              />
                            )}
                          </Transition.Group>
                        </div>
                      )}
                  </GridItem>
                  <GridItem xl={9} lg={9} md={9} sm={12} xs={12}>
                    <GridContainer>
                      <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                        <GridContainer justify="center">
                          <GridItem xl={2} lg={2} md={12} sm={6} xs={6}>
                            <Input
                              labelText="UID"
                              id="userId"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                //placeholder : 'Item Name',
                                disabled: true,
                                value: selectedUser.id
                                  .toString()
                                  .padStart(6, 0),
                              }}
                            />
                          </GridItem>
                          <GridItem xl={2} lg={2} md={12} sm={6} xs={6}>
                            <Input
                              labelText={t("userType")}
                              id="userType"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                //placeholder : 'Item Name',
                                disabled: true,
                                value: selectedUser.userType,
                              }}
                            />
                          </GridItem>
                          <GridItem xl={2} lg={2} md={12} sm={12} xs={12}>
                            <Input
                              labelText={t("balance")}
                              id="balance"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                //placeholder : 'Item Name',
                                disabled: true,
                                value: fnumber(this.state.balance),
                                //onChange : (e)=>{this.setState({keyword:e.target.value})},
                                //onKeyPress : (e)=>{if(e.key === 'Enter'){this.setState({keyword:e.target.value});this.loadAdminFee({keyword:e.target.value})}}
                              }}
                            />
                          </GridItem>
                          {(isManagable(user, selectedUser) ||
                            user.userTypeId === 1) && (
                            <GridItem xl={3} lg={3} md={7} sm={7} xs={12}>
                              <Input
                                labelText={
                                  isManagable(user, selectedUser)
                                    ? t("mutation")
                                    : "Transfer"
                                }
                                id="mutation"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Masukkan Jumlah saldo",
                                  value: this.state.mutation,
                                  onChange: this.handleConvert,
                                  //onKeyPress : (e)=>{if(e.key === 'Enter'){this.setState({keyword:e.target.value});this.loadAdminFee({keyword:e.target.value})}}
                                }}
                              />
                            </GridItem>
                          )}
                          {(isManagable(user, selectedUser) ||
                            user.userTypeId === 1) && (
                            <GridItem xl={3} lg={3} md={5} sm={5} xs={12}>
                              <span>
                                <div
                                  className={classNames(
                                    classes.buttonGroup,
                                    classes.buttons
                                  )}
                                >
                                  <Tooltip
                                    id="tooltip-search"
                                    title="Transfer Saldo"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Button
                                      color="success"
                                      size="sm"
                                      round
                                      onClick={() =>
                                        this.handleTransfer("transfer")
                                      }
                                      className={classes.firstButton}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    id="tooltip-clear"
                                    title="Tarik Saldo"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Button
                                      color="danger"
                                      size="sm"
                                      round
                                      onClick={() =>
                                        this.handleTransfer("deduct")
                                      }
                                      className={classes.lastButton}
                                    >
                                      <RemoveIcon />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </span>
                              {/* // :
														// 	<Tooltip id="tooltip-transfer" title="Transfer Saldo" placement="top" classes={{ tooltip: classes.tooltip }}>
														// 		<Button color="success" size="sm" round onClick={() => this.handleTransfer('transfer')} className={classes.buttons} ><AddIcon />Transfer</Button>
														// 	</Tooltip>
														//} */}
                            </GridItem>
                          )}
                        </GridContainer>
                      </GridItem>
                      {this.state.showAdminFee && (
                        <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                          <GridContainer justify="center">
                            <GridItem xl={3} lg={3} md={6} sm={12} xs={12}>
                              <Dropdown
                                style={{ marginTop: 25 }}
                                placeholder={t("category")}
                                fluid
                                multiple
                                search
                                selection
                                value={this.state.selectedCategories}
                                onChange={(e, { value }) => {
                                  this.setState({
                                    selectedCategories: value,
                                    selectedProducts: [],
                                  });
                                }}
                                options={this.state.categories.map(
                                  (item, index) => ({
                                    key: item.id,
                                    text: item.name,
                                    value: item.id,
                                  })
                                )}
                              />
                            </GridItem>
                            <GridItem xl={3} lg={3} md={6} sm={12} xs={12}>
                              <Dropdown
                                style={{ marginTop: 25 }}
                                placeholder="ITEM"
                                fluid
                                multiple
                                search
                                selection
                                value={this.state.selectedProducts}
                                onChange={(e, { value }) => {
                                  this.setState({
                                    selectedProducts: value,
                                    selectedCategories: [],
                                  });
                                }}
                                options={this.state.products.map(
                                  (item, index) => ({
                                    key: item.id,
                                    text: item.name,
                                    value: item.id,
                                  })
                                )}
                              />
                            </GridItem>
                            <GridItem xl={3} lg={3} md={7} sm={7} xs={12}>
                              <Input
                                labelText={t("search")}
                                id="keyword"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Item Name",
                                  value: this.state.keyword,
                                  onChange: (e) => {
                                    this.setState({ keyword: e.target.value });
                                  },
                                  onKeyPress: (e) => {
                                    if (e.key === "Enter") {
                                      this.setState({
                                        keyword: e.target.value,
                                      });
                                      this.loadAdminFee({
                                        keyword: e.target.value,
                                      });
                                    }
                                  },
                                }}
                              />
                            </GridItem>
                            <GridItem xl={3} lg={3} md={5} sm={5} xs={12}>
                              <span>
                                <div
                                  className={classNames(
                                    classes.buttonGroup,
                                    classes.buttons
                                  )}
                                >
                                  <Tooltip
                                    id="tooltip-search"
                                    title={t("search")}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Button
                                      color="info"
                                      size="sm"
                                      round
                                      onClick={() => this.loadAdminFee()}
                                      className={classes.firstButton}
                                    >
                                      <SearchIcon />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    id="tooltip-clear"
                                    title="Clear"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Button
                                      color="info"
                                      size="sm"
                                      round
                                      onClick={() => this.handleClear()}
                                      className={classes.lastButton}
                                    >
                                      <ClearAllIcon />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </span>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      )}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 25, textAlign: "right" }}
              >
                <GridContainer justify="center">
                  <GridItem
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginTop: 10 }}
                  >
                    {/* {[104, 391].includes(user.id) && ( */}
                    {isAdminManagable(user, selectedUser) && (
                      <CheckboxSem
                        toggle
                        label="Administrator"
                        checked={this.state.isAdministrator}
                        onClick={(e, { checked }) => {
                          const isAdministrator = checked;
                          const url = "/user/isadministrator";
                          axios
                            .post(Config.ApiUrl + url, {
                              userId: selectedUser.id,
                              isAdministrator,
                            })
                            .then((res) => {
                              console.log("ok");
                              this.setState({ isAdministrator });
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          // this.setState({isAdministrator:checked})
                        }}
                      />
                    )}
                    {/* // )} */}
                  </GridItem>
                  <GridItem
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginTop: 10 }}
                  >
                    {isManagable(user, selectedUser) && (
                      <CheckboxSem
                        toggle
                        label="H+0"
                        checked={this.state.isD0}
                        onClick={(e, { checked }) => {
                          const isD0 = checked;
                          const url =
                            user.userTypeId === 1 ? "/user/d0" : "/user/ca/d0";
                          axios
                            .post(Config.ApiUrl + url, {
                              userId: selectedUser.id,
                              isD0,
                            })
                            .then((res) => {
                              console.log("ok");
                              this.setState({ isD0 });
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          // this.setState({d0:checked})
                        }}
                      />
                    )}
                  </GridItem>
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                    {isManagable(user, selectedUser) && (
                      <span>
                        <Input
                          labelText={t("ceiling")}
                          id="balancelimit"
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            readOnly: this.state.balanceLimitReadOnly,
                            placeholder: t("ceiling"),
                            value: fnumber(this.state.balanceLimit),
                            onDoubleClick: () => {
                              // console.log('double click')
                              this.setState({ balanceLimitReadOnly: false });
                            },
                            onChange: (e) => {
                              const valStr = e.target.value.replace(/\./g, "");
                              let value =
                                isNaN(valStr) || valStr === ""
                                  ? 0
                                  : parseInt(valStr);
                              if (value < 0) value = 0;
                              this.setState({ balanceLimit: value });
                            },
                            // onKeyPress: (e) => { if (e.key === 'Enter') {
                            // 	this.setState({ keyword: e.target.value });
                            // 	this.loadAdminFee({ keyword: e.target.value })
                            // } }
                          }}
                        />
                        {!this.state.balanceLimitReadOnly ? (
                          <Button
                            color="facebook"
                            size="sm"
                            justIcon
                            round
                            onClick={() => {
                              const url =
                                user.userTypeId === 1
                                  ? "/user/balancelimit/set"
                                  : "/user/ca/ceiling/set";
                              axios
                                .post(Config.ApiUrl + url, {
                                  balanceLimit: this.state.balanceLimit,
                                  selectedUser,
                                })
                                .then((res) => {
                                  // console.log(res.data)
                                  this.setState({ balanceLimitReadOnly: true });
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                          >
                            <Save />
                          </Button>
                        ) : (
                          <Button
                            style={{ marginTop: 25 }}
                            color="warning"
                            size="sm"
                            justIcon
                            round
                            onClick={() => {
                              this.setState({ balanceLimitReadOnly: false });
                            }}
                          >
                            <EditIcon />
                          </Button>
                        )}
                      </span>
                    )}
                  </GridItem>
                  <GridItem
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginTop: 10 }}
                  >
                    {isDmRealtime(user, selectedUser) && (
                      <GridContainer justify="center">
                        <GridItem xl={6} lg={6} md={6} sm={12} xs={12}>
                          <CheckboxSem
                            toggle
                            label={t("deposit")}
                            checked={this.state.isDm}
                            onClick={(e, { checked }) => {
                              const isDm = checked;
                              const url = "/user/ca/dm";
                              axios
                                .post(Config.ApiUrl + url, {
                                  userId: selectedUser.id,
                                  isDm,
                                })
                                .then((res) => {
                                  console.log("ok");
                                  this.setState({ isDm });
                                })
                                .catch((error) => {
                                  console.log(error);
                                  this.setState({ isDm: !checked });
                                });
                              this.setState({ isDm: checked });
                            }}
                          />
                        </GridItem>
                        <GridItem xl={6} lg={6} md={6} sm={12} xs={12}>
                          {this.state.isDm && (
                            <CheckboxSem
                              toggle
                              label={"Real Time"}
                              checked={this.state.isRealTime}
                              onClick={(e, { checked }) => {
                                const isRealTime = checked;
                                const url = "/user/ca/realtime";
                                axios
                                  .post(Config.ApiUrl + url, {
                                    userId: selectedUser.id,
                                    isRealTime,
                                  })
                                  .then((res) => {
                                    console.log("ok");
                                    this.setState({ isRealTime });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    this.setState({ isRealTime: !checked });
                                  });
                                this.setState({ isRealTime: checked });
                              }}
                            />
                          )}
                        </GridItem>
                      </GridContainer>
                    )}
                  </GridItem>
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                    {this.state.showAdminFee && (
                      <ExportExcel
                        title={t("buyingPrice")}
                        filename="PayFren Buying Price List"
                        ref={this.exportBuy}
                      />
                    )}
                  </GridItem>
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                    {this.state.showAdminFee && (
                      <ExportExcel
                        title={t("sellingPrice")}
                        filename="PayFren Selling Price List"
                        ref={this.exportSell}
                      />
                    )}
                  </GridItem>
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                    {(([1].includes(user.userTypeId) &&
                      [5, 8, 13, 24].includes(selectedUser.userTypeId)) ||
                      ([24].includes(user.userTypeId) &&
                        [25].includes(selectedUser.userTypeId))) && (
                      <span>
                        Open/Close All
                        <Checkbox
                          id="openAll"
                          checked={
                            this.state.openAll !== undefined
                              ? this.state.openAll
                              : false
                          }
                          tabIndex={-1}
                          onClick={(e) => {
                            const isOpen = e.target.checked;
                            const url = user.userTypeId !== 1 ? "/ca" : "";
                            axios
                              .post(
                                Config.ApiUrl + `/user${url}/product/openall`,
                                {
                                  products: this.state.rows,
                                  userId: selectedUser.id,
                                  isOpen,
                                }
                              )
                              .then((res) => {
                                const openStates = _.assign.apply(
                                  _,
                                  this.state.rows.map((item) => ({
                                    ["c" + item.id]: isOpen,
                                  }))
                                );
                                this.setState({
                                  openAll: isOpen,
                                  ...openStates,
                                });
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </span>
                    )}
                  </GridItem>
                  <GridItem lg={4} md={4} sm={4} xs={4}>
                    {user && user.userTypeId === 1 && (
                      <span>
                        <Input
                          labelText={t("outstandingBalance")}
                          id="balanceOutstanding"
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            inputRef: this.inputBlockRef,
                            readOnly: this.state.balanceOutstandingReadOnly,
                            placeholder: t("outstandingBalance"),
                            value: fnumber(this.state.balanceOutstanding),
                            onDoubleClick: () => {
                              // console.log('double click')
                              this.setState({
                                balanceOutstandingReadOnly: false,
                              });
                            },
                            onChange: (e) => {
                              const valStr = e.target.value.replace(/\./g, "");
                              let value =
                                isNaN(valStr) || valStr === ""
                                  ? 0
                                  : parseInt(valStr);
                              if (value < 0) value = 0;
                              this.setState({ balanceOutstanding: value });
                            },
                          }}
                        />
                        {!this.state.balanceOutstandingReadOnly ? (
                          <>
                            <Tooltip
                              id="tooltip-search"
                              title="Simpan Saldo"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="facebook"
                                size="sm"
                                justIcon
                                round
                                style={{ marginTop: 25 }}
                                onClick={() => {
                                  console.log({
                                    balanceOutstanding: this.state
                                      .balanceOutstanding,
                                  });
                                  // if (this.state.balanceOutstanding) {
                                  axios
                                    .post(
                                      Config.ApiUrl + "/user/balanceBlock/set",
                                      {
                                        amount: this.state.balanceOutstanding,
                                        selectedUser,
                                      }
                                    )
                                    .then((res) => {
                                      // console.log(res.data)
                                      this.setState({
                                        balanceOutstandingReadOnly: true,
                                      });
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                  // }
                                }}
                              >
                                <Save />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-search"
                              title="Batal"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                style={{ marginTop: 25 }}
                                color="danger"
                                size="sm"
                                justIcon
                                round
                                onClick={() => {
                                  this.setState({
                                    balanceOutstandingReadOnly: true,
                                  });
                                }}
                              >
                                <CloseIcon />
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              id="tooltip-search"
                              title="Edit Saldo"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                style={{ marginTop: 25 }}
                                color="warning"
                                size="sm"
                                justIcon
                                round
                                onClick={() => {
                                  this.setState(
                                    { balanceOutstandingReadOnly: false },
                                    () => {
                                      this.inputBlockRef.current &&
                                        this.inputBlockRef.current.focus();
                                    }
                                  );
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-search"
                              title="Blok Manual"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                style={{ marginTop: 25 }}
                                color="success"
                                size="sm"
                                justIcon
                                round
                                disabled={this.state.buttonSendBlock}
                                onClick={() => {
                                  this.setState({ buttonSendBlock: true });
                                  axios
                                    .post(
                                      Config.ApiUrl + "/user/balanceBlock/send",
                                      {
                                        amount: this.state.balanceOutstanding,
                                        selectedUser,
                                      }
                                    )
                                    .then((res) => {
                                      this.setState({ buttonSendBlock: false });
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                      this.setState({ buttonSendBlock: false });
                                    });
                                }}
                              >
                                <SendIcon />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
              {this.state.showAdminFee && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Segment vertical>
                    <Dimmer active={this.state.isLoading} inverted>
                      <Loader />
                    </Dimmer>
                    <Table
                      striped
                      tableHead={
                        ([1].includes(user.userTypeId) &&
                          [5, 8, 13, 24].includes(selectedUser.userTypeId)) ||
                        ([24].includes(user.userTypeId) &&
                          [25].includes(selectedUser.userTypeId))
                          ? [
                              "#",
                              "ID",
                              "Item",
                              "Admin",
                              user.userTypeId === 1 && "Partner",
                              t("price"),
                              selectedUser.userTypeId !== 13
                                ? t("revenue")
                                : "Broker",
                              t("actions"),
                              "Open",
                            ]
                          : [
                              "#",
                              "ID",
                              "Item",
                              "Admin",
                              user.userTypeId === 1 && "Partner",
                              t("price"),
                              selectedUser.userTypeId !== 13
                                ? t("revenue")
                                : "Broker",
                              t("actions"),
                            ]
                      }
                      tableData={this.state.rows.map((item, index) => [
                        index +
                          1 +
                          (isNaN(this.state.rowsPerPage)
                            ? 0
                            : (this.state.currentPage - 1) *
                              this.state.rowsPerPage),
                        item.id.toString().padStart(4, 0),
                        item.name,
                        fnumber(item.adminNominal),
                        user.userTypeId === 1 && (
                          <span>
                            {item.partner}
                            {item.isDefaultPartner && (
                              <Badge color="info">Default</Badge>
                            )}
                            <Button
                              color="primary"
                              size="sm"
                              justIcon
                              round
                              onClick={() => this.initEditPartner(item)}
                            >
                              <EditIcon />
                            </Button>
                          </span>
                        ),
                        <Badge color="rose">{fnumber(item.price)}</Badge>,
                        <span>
                          {fnumber(item.revenue)}{" "}
                          {item.isDefault && (
                            <Badge color="info">Default</Badge>
                          )}
                        </span>,
                        <Button
                          color="warning"
                          size="sm"
                          justIcon
                          round
                          onClick={() => this.initAdminModal(item)}
                        >
                          <EditIcon />
                        </Button>,
                        ([1].includes(user.userTypeId) &&
                          [5, 8, 13, 24].includes(selectedUser.userTypeId)) ||
                        ([24].includes(user.userTypeId) &&
                          [25].includes(selectedUser.userTypeId)) ? (
                          <Checkbox
                            id={"c" + item.id}
                            checked={
                              this.state["c" + item.id] !== undefined
                                ? this.state["c" + item.id]
                                : item.isOpen
                            }
                            tabIndex={-1}
                            onClick={(e) => {
                              if (
                                item.isOpen &&
                                this.state["c" + item.id] === undefined
                              ) {
                                this.setState({
                                  ["c" + item.id]: !item.isOpen,
                                });
                              } else
                                this.setState({
                                  ["c" + item.id]: e.target.checked,
                                });
                              this.handleCheckBox(
                                item.id,
                                selectedUser.id,
                                e.target.checked
                              );
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        ) : null,
                      ])}
                      customCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight,
                        classes.textRight,
                      ]}
                      customClassesForCells={[0, 3, 4, 5]}
                      customHeadCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight,
                        classes.textRight,
                      ]}
                      customHeadClassesForCells={[0, 3, 4, 5]}
                    />
                  </Segment>
                </GridItem>
              )}
              {this.state.showAdminFee && (
                <GridItem
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.grid}
                >
                  <div className={classes.justifyContentCenter}>
                    <Pagination
                      totalButton={7}
                      totalPages={this.state.totalPages}
                      totalData={this.state.totalData}
                      currentPage={this.state.currentPage}
                      onChangePage={(currentPage) => {
                        if (currentPage !== this.state.currentPage) {
                          this.setState({ currentPage });
                          this.loadAdminFee({ currentPage });
                        }
                      }}
                      // pages={[
                      // 	...this.paginationButton(),
                      // ]}
                      rowsPerPage={[5, 15, 25, "All"]}
                      defaultRowsPerPage={15}
                      onChangeRowsPerPage={(value) => {
                        this.setState({ rowsPerPage: value, currentPage: 1 });
                        this.loadAdminFee({
                          rowsPerPage: value,
                          currentPage: 1,
                        });
                      }}
                    />
                  </div>
                </GridItem>
              )}
            </GridContainer>
            {/* Modal >> */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={CustomTransition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalBody === "EditRevenue" &&
                  this.editRevenueBody()}
                {this.state.modalBody === "transfer" && this.transferBody()}
                {this.state.modalBody === "EditIp" && this.editIpBody()}
                {this.state.modalBody === "EditPartner" &&
                  this.editPartnerBody()}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                {this.state.modalBody === "EditRevenue" &&
                  this.editRevenueAction()}
                {this.state.modalBody === "transfer" && this.transferAction()}
                {this.state.modalBody === "EditIp" && this.editIpAction()}
                {this.state.modalBody === "EditPartner" &&
                  this.editPartnerAction()}
              </DialogActions>
            </Dialog>
            {/* << Modal */}
          </div>
        </div>
      </div>
    );
  }
  initEditIp = (e) => {
    const { selectedUser } = this.props.location;
    this.setState({
      modalShow: true,
      modalTitle: `Edit ${selectedUser.name} IP`,
      modalBody: "EditIp",
    });
  };
  changeIp = (e) => {
    const { selectedUser } = this.props.location;
    const user = secureStorage.getItem("user");
    if (this.state.ip && this.state.newIp !== this.state.ip) {
      const url = user.userTypeId !== 1 ? "ca/" : "";
      axios
        .post(Config.ApiUrl + `/user/${url}changeip`, {
          selectedUser,
          newIp: this.state.newIp,
        })
        .then((res) => {
          this.setState({
            modalShow: false,
            ip: this.state.newIp,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  editIpAction = () => {
    return (
      <div>
        <Button
          onClick={() =>
            this.setState({ modalShow: false, newIp: this.state.ip })
          }
          color="warning"
          round
        >
          Cancel
        </Button>
        <Button
          onClick={this.changeIp}
          color="success"
          round
          disabled={
            !this.state.newIp || this.state.newIp === this.state.ip
              ? true
              : false
          }
        >
          Save
        </Button>
      </div>
    );
  };
  editIpBody = () => {
    return (
      <GridContainer justify="center">
        <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Input
            labelText="IP Address"
            id="ip"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "IP Adress",
              value: this.state.newIp,
              onChange: (e) => {
                this.setState({ newIp: e.target.value });
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  this.changeIp(e);
                }
              },
            }}
          />
        </GridItem>
      </GridContainer>
    );
  };
  initEditPartner = (item) => {
    // console.log(item)

    const { selectedUser } = this.props.location;
    this.setState({ currPartnerId: item.partnerId, selectedItem: item });
    axios
      .post(Config.ApiUrl + "/user/partner", {
        productId: item.id,
      })
      .then((res) => {
        const partners = res.data;
        const selectedPartner = partners.filter(
          (p) => p.id === item.partnerId
        )[0];
        // console.log({partners,selectedPartner})
        this.setState({
          partners,
          selectedPartner,
          partnerResetDisabled: item.isDefaultPartner ? true : false,
          modalShow: true,
          modalTitle: `Edit ${item.name} Partner for ${selectedUser.name}`,
          modalBody: "EditPartner",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changePartner = () => {
    const { selectedUser } = this.props.location;

    axios
      .post(Config.ApiUrl + "/user/partner/set", {
        selectedUser,
        partner: this.state.selectedPartner,
      })
      .then((res) => {
        this.setState({
          selectedPartner: "",
          modalShow: false,
          partnerErr: "",
        });
        this.loadAdminFee();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  resetPartner = () => {
    const { selectedUser } = this.props.location;

    axios
      .post(Config.ApiUrl + "/user/partner/reset", {
        selectedUser,
        partner: this.state.selectedPartner,
      })
      .then((res) => {
        this.setState({
          selectedPartner: "",
          modalShow: false,
          partnerErr: "",
        });
        this.loadAdminFee();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  editPartnerAction = () => {
    return (
      <div>
        <Button
          onClick={() => this.resetPartner()}
          color="danger"
          disabled={this.state.partnerResetDisabled}
          round
        >
          <DeleteIcon />
          Reset
        </Button>
        <Button
          onClick={() =>
            this.setState({
              modalShow: false,
              selectedPartner: "",
              partnerErr: "",
            })
          }
          color="warning"
          round
        >
          Cancel
        </Button>
        <Button
          onClick={this.changePartner}
          color="success"
          round
          // disabled={
          //   this.state.currPartnerId === this.state.selectedPartner.id ||
          //   !this.state.enablePartnerButton
          //     ? true
          //     : false
          // }
        >
          Save
        </Button>
      </div>
    );
  };
  editPartnerBody = () => {
    const { classes } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="partner-select"
              className={classes.selectLabel}
            >
              Partner
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{ name: "partner", id: "partner" }}
              value={this.state.selectedPartner}
              onChange={(e) => {
                const selectedPartner = e.target.value;
                if (
                  selectedPartner.ProductSwitchings[0].price >=
                  this.state.selectedItem.sellingPrice
                ) {
                  this.setState({
                    partnerErr: `Partner price ${fnumber(
                      selectedPartner.ProductSwitchings[0].price
                    )} is exceeding the current selling price of ${fnumber(
                      this.state.selectedItem.sellingPrice
                    )}`,
                    // enablePartnerButton: false,
                  });
                } else
                  this.setState({
                    enablePartnerButton: true,
                    partnerErr: false,
                  });

                this.setState({ selectedPartner: e.target.value });
              }}
            >
              <MenuItem
                disabled
                classes={{ root: classes.selectMenuItem }}
                value={""}
              >
                Partner
              </MenuItem>
              {this.state.partners.map((item, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={item}
                >
                  {`${item.name} - ${fnumber(item.ProductSwitchings[0].price)}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p style={{ color: "red" }}>{this.state.partnerErr}</p>
        </GridItem>
      </GridContainer>
    );
  };
  transferAction = () => {
    return (
      <div>
        <Button
          onClick={() => this.setState({ modalShow: false })}
          color="warning"
          round
        >
          Cancel
        </Button>
        <Button
          onClick={this.transferBalance}
          color="success"
          round
          disabled={this.state.buttonTransferDisabled}
          // disabled={this.state.remark === ""}
        >
          {this.state.transferPayload.action}
        </Button>
      </div>
    );
  };
  transferBody = () => {
    return (
      <GridContainer justify="center">
        <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Input
            labelText="Remark"
            id="remark"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "Remark",
              value: this.state.remark,
              onChange: (e) => {
                this.setState({ remark: e.target.value });
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  this.transferBalance(e);
                }
              },
            }}
          />
        </GridItem>
      </GridContainer>
    );
  };
  editRevenueAction = () => {
    const { t } = this.props;
    return (
      <div>
        <Button
          onClick={() => this.handleReset()}
          color="danger"
          disabled={this.state.selectedFeeId ? false : true}
          round
        >
          <DeleteIcon />
          Reset
        </Button>
        <Button
          onClick={() => this.setState({ modalShow: false })}
          color="warning"
          round
        >
          {t("cancel")}
        </Button>
        <Button onClick={() => this.handleSubmit()} color="success" round>
          <Save /> {t("save")}
        </Button>
      </div>
    );
  };
  editRevenueBody = () => {
    const { t } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xl={6} lg={6} md={6} sm={6} xs={12}>
          <Input
            labelText="Admin"
            id="adminShow"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "Admin",
              value: this.state.selectedAdminNominal,
              disabled: true,
            }}
          />
          <Input
            labelText={t("price")}
            id="partnerPriceShow"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "Partner Price",
              value: fnumber(this.state.partnerPrice),
              disabled: true,
            }}
          />
          <Input
            labelText={t("revenue")}
            id="inputRevenue"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "Revenue",
              value: fnumber(this.state.inputRevenue),
              onChange: this.handleInput,
            }}
          />
        </GridItem>
      </GridContainer>
    );
  };
  initAdminModal = async (item) => {
    //item.adminNominal,item.adminNominalId,item.price,item.name,item.revenue,item.feeId,item
    //item.adminNominal,item.adminNominalId,item.price,item.name,item.revenue
    this.setState({
      partnerPrice: item.price,
      modalShow: true,
      selectedFeeId: item.feeId,
      selectedProduct: item.name,
      selectedAdminNominal: item.adminNominal,
      selectedAdminNominalId: item.adminNominalId,
      selectedProductTypeId: item.productTypeId,
      inputRevenue: item.revenue,
      modalTitle: item.name,
      modalBody: "EditRevenue",
    });
  };
  handleInput = (e) => {
    let val = e.target.value.replace(/\./g, "");

    val = isNaN(val) || !val ? 0 : val;
    // console.log(val,this.state.selectedAdminNominal , this.state.partnerPrice , this.state.selectedProductTypeId)
    if (
      parseInt(val) >
        this.state.selectedAdminNominal - this.state.partnerPrice &&
      this.state.selectedProductTypeId === 1
    )
      val = 0;
    this.setState({ [e.target.id]: val });
  };
  handleSubmit = (e) => {
    const user = secureStorage.getItem("user");
    const price =
      user.userTypeId === 1 && this.state.selectedProductTypeId === 1
        ? this.state.partnerPrice
        : undefined;
    axios
      .post(Config.ApiUrl + "/user/fee/set", {
        user: this.state.selectedUser,
        adminNominalId: this.state.selectedAdminNominalId,
        revenue: this.state.inputRevenue,
        price,
      })
      .then((res) => {
        this.loadAdminFee();
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ modalShow: false });
  };
  handleReset = (e) => {
    axios
      .post(Config.ApiUrl + "/user/fee/reset", {
        feeId: this.state.selectedFeeId,
        user: this.state.selectedUser,
        adminNominalId: this.state.selectedAdminNominalId,
      })
      .then((res) => {
        this.loadAdminFee();
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ modalShow: false });
  };
}
User.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(style))(User);
