/* eslint-disable */
import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
// react components for routing our app without refresh
import { Link, Redirect } from "react-router-dom"

import { withTranslation } from "react-i18next"
import { compose } from "redux"

// @material-ui/core components
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import { BillingUsers } from "content/User/libs"

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create"
import Logout from "@material-ui/icons/ExitToApp"
import ListAltIcon from "@material-ui/icons/ListAlt"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import StorageIcon from "@material-ui/icons/Storage"
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import BusinessIcon from "@material-ui/icons/Business"
import ImportExportIcon from "@material-ui/icons/ImportExport"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ShareIcon from "@material-ui/icons/Share"
import ViewListIcon from "@material-ui/icons/ViewList"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import SettingsIcon from "@material-ui/icons/Settings"
import LockIcon from "@material-ui/icons/Lock"
import WalletIcon from "@material-ui/icons/AccountBalanceWallet"
import ReceiptIcon from "@material-ui/icons/Receipt"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import AndroidIcon from "@material-ui/icons/Android"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import DnsIcon from "@material-ui/icons/Dns"
import DescriptionIcon from "@material-ui/icons/Description"
import GroupAdd from "@material-ui/icons/GroupAdd"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js"
import Button from "components/CustomButtons/Button.js"

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js"
import Badge from "components/BalanceBadge/Badge.js"
import Clearfix from "components/Clearfix/Clearfix.js"
import { fnumber } from "components/Helper"

import yuju from "assets/img/faces/profile.png"
import { AddCircleOutline, CreditCard, History, PriorityHigh } from "@material-ui/icons"

import { Dropdown, Icon } from "semantic-ui-react"
import i18next from "i18next"

import secureStorage from "libs/secureStorage"
import { EndUsers, TlUsers } from "content/User/libs"

import Config from "config"
import axios from "axios"

class HeaderLink extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loginType: null,
			loggingOut: false,
			user: secureStorage.getItem("user") ? secureStorage.getItem("user") : "",
			balance: 0,
			billAmount: 0,
			showBill: false,
			selectedLang: localStorage.getItem("i18nextLng") || "id",
		}
	}
	componentDidMount() {
		// this.setState({selectedLang:localStorage.getItem("i18nextLng") || "id"})
		// const lang = localStorage.getItem('i18nextLng')
		// console.log(lang==='en-US')
		// if(lang==='en-US')this.setState({selectedLang:'en'})
		// else if(lang==='id-ID')this.setState({selectedLang:'id'})
		this.setState({ loginType: secureStorage.getItem("loginType") })
		this.initBill()
		this.interval = setInterval(() => this.setBalanceDisplay(), 500)
	}
	componentWillUnmount() {
		clearInterval(this.interval)
	}
	setBalanceDisplay() {
		const balance = secureStorage.getItem("balance")
		const billAmount = secureStorage.getItem("bill")
		if (balance) this.setState({ balance })
		if (billAmount) this.setState({ billAmount })
	}

	initBill() {
		axios
			.post(Config.ApiUrl + "/bill/status")
			.then((res) => {
				const bill = res.data
				this.setState({ showBill: bill ? true : false })
			})
			.catch((err) => {
				//console.log(err)
				this.setState({ showBill: false })
			})
	}

	handleLogout = (e) => {
		this.props.onLogout()
	}
	render() {
		const { dropdownHoverColor, classes, t } = this.props
		const user = secureStorage.getItem("user")
		const lu = user && user.LoginUsers && user.LoginUsers[0]
		return (
			<List className={classes.list + " " + classes.mlAuto}>
				<List className={classes.listItem}>
					<div>
						<img src={yuju} alt="..." className={classes.imgRoundedCircle} />
						<div className={classes.profileContainer}>
							<div className={classes.profileName}>
								{user && user.name}
								{lu && ` - ${lu.name} `}
							</div>
							<div className={classes.balance}>
								<Badge className={classes.balanceBadge} color="tumblr">
									{user.UserType.name}
								</Badge>
								<Badge
									className={classNames(classes.balanceBadge, classes.balance)}
									color={
										user && TlUsers.includes(user.userTypeId) && (!user.isDm || this.state.showBill)
											? "instagram"
											: "rose"
									}
								>
									Saldo {fnumber(this.state.balance)}
								</Badge>
								{/* {user && TlUsers.includes(user.userTypeId) && ( */}
								{user && TlUsers.includes(user.userTypeId) && (!user.isDm || this.state.showBill) && (
									<Badge className={classNames(classes.balanceBadge, classes.balance)} color="rose">
										Setoran {fnumber(this.state.billAmount ? this.state.billAmount : 0)}
									</Badge>
								)}
							</div>
						</div>
					</div>
					<Clearfix />
				</List>

				{user && user.userTypeId === 1 && (
					<ListItem className={classes.listItem}>
						<CustomDropdown
							noLiPadding
							navDropdown
							hoverColor={dropdownHoverColor}
							buttonText="Item"
							buttonProps={{
								className: classes.navLink,
								color: "transparent",
							}}
							buttonIcon={StorageIcon}
							dropdownList={[
								<Link to="/Product" className={classes.dropdownLink}>
									<FormatListNumberedIcon className={classes.dropdownIcons} /> Item List
								</Link>,
								// <Link to="/biller" className={classes.dropdownLink}>
								//   <BusinessIcon className={classes.dropdownIcons} /> Biller
								// </Link>,
								// <Link to="/partner" className={classes.dropdownLink}>
								//   <ImportExportIcon className={classes.dropdownIcons} /> Partner
								// </Link>,
							]}
						/>
					</ListItem>
				)}
				{[1, 2, 3, 6, 9, 10, 12, 14, 15, 16, 17, 19, 20, 21, 22, 24, 26, 27, 28].includes(user.userTypeId) && (
					<ListItem className={classes.listItem}>
						<CustomDropdown
							noLiPadding
							navDropdown
							hoverColor={dropdownHoverColor}
							buttonText={t("user")}
							buttonProps={{
								className: classes.navLink,
								color: "transparent",
							}}
							buttonIcon={SupervisedUserCircleIcon}
							dropdownList={[
								<Link to="/User" className={classes.dropdownLink}>
									<ListAltIcon className={classes.dropdownIcons} /> {t("userList")}
								</Link>,
								user && user.userTypeId === 1 && (
									<Link to="/ViewOtp" className={classes.dropdownLink}>
										<ListAltIcon className={classes.dropdownIcons} /> {t("ViewOtp")}
									</Link>
								),
								<Link to="/CreateUser" className={classes.dropdownLink}>
									<GroupAdd className={classes.dropdownIcons} /> Tambah Akun
								</Link>,
								// user && !EndUsers.includes(user.userTypeId) &&
								// <Link to="/FeeTemplate" className={classes.dropdownLink}>
								//   <DescriptionIcon className={classes.dropdownIcons} /> {t('feeTemplate')}
								// </Link>,
								user && [1, 24].includes(user.userTypeId) && (
									<Link to="/H2hCreate" className={classes.dropdownLink}>
										<CreateIcon className={classes.dropdownIcons} /> H2H Create
									</Link>
								),
								user &&
									([2, 20, 27].includes(user.userTypeId) ||
										([3].includes(user.userTypeId) && user.isAdministrator)) && (
										<Link to="/CaTopUp" className={classes.dropdownLink}>
											<ArrowUpwardIcon className={classes.dropdownIcons} /> TOP UP
										</Link>
									),
								user &&
									([2, 3, 19, 20, 21, 22, 24, 26, 27, 28].includes(user.userTypeId) ||
										([3].includes(user.userTypeId) && user.isAdministrator)) && (
										<CustomDropdown
											data-ref="multi"
											innerDropDown
											key={9658612}
											dropPlacement="right-start"
											noLiPadding
											navDropdown
											hoverColor={dropdownHoverColor}
											buttonText={t("payment")}
											buttonProps={{
												className: classes.navLink,
												color: "transparent",
											}}
											buttonIcon={ReceiptIcon}
											dropdownList={[
												user && [2, 3, 19, 20, 21, 22, 27, 28].includes(user.userTypeId) && (
													<Link to="/Payment" className={classes.dropdownLink}>
														<FormatListNumberedIcon className={classes.dropdownIcons} /> {t("entry")}
													</Link>
												),
												<Link to="/PaymentHistory" className={classes.dropdownLink}>
													<History className={classes.dropdownIcons} /> {t("history")}
												</Link>,
											]}
										/>
									),
								user && user.userTypeId === 1 && (
									<Link to="/Domain" className={classes.dropdownLink}>
										<DnsIcon className={classes.dropdownIcons} /> Domain
									</Link>
								),
							]}
						/>
					</ListItem>
				)}
				<ListItem className={classes.listItem}>
					<CustomDropdown
						noLiPadding
						navDropdown
						hoverColor={dropdownHoverColor}
						buttonText={t("transaction")}
						buttonProps={{
							className: classes.navLink,
							color: "transparent",
						}}
						buttonIcon={TrendingUpIcon}
						dropdownList={[
							user && [5, 8].includes(user.userTypeId) && (
								<Link to="/ListProduct" className={classes.dropdownLink}>
									<FormatListNumberedIcon className={classes.dropdownIcons} /> Item List
								</Link>
							),
							<Link to="/Transaction" className={classes.dropdownLink}>
								<ViewListIcon className={classes.dropdownIcons} /> {t("entry")}
							</Link>,
							<Link to="/TransactionLog" className={classes.dropdownLink}>
								<ViewListIcon className={classes.dropdownIcons} /> Log
							</Link>,
							<Link to="/TransactionByItem" className={classes.dropdownLink}>
								<ListAltIcon className={classes.dropdownIcons} /> {t("byItem")}
							</Link>,
							user.userTypeId === 1 && (
								<Link to="/TransactionPartner" className={classes.dropdownLink}>
									<ListAltIcon className={classes.dropdownIcons} /> {t("Partner")}
								</Link>
							),
							<Link to="/BalanceMutation" className={classes.dropdownLink}>
								<ImportExportIcon className={classes.dropdownIcons} /> {t("balanceMutation")}
							</Link>,
							BillingUsers.includes(user.userTypeId) && (
								<Link to="/BillingUser" className={classes.dropdownLink}>
									<History className={classes.dropdownIcons} /> {t("billing")}
								</Link>
							),
						]}
					/>
				</ListItem>
				{user && user.userTypeId === 1 && (
					<ListItem className={classes.listItem}>
						<CustomDropdown
							noLiPadding
							navDropdown
							hoverColor={dropdownHoverColor}
							buttonText="Finance"
							buttonProps={{
								className: classes.navLink,
								color: "transparent",
							}}
							buttonIcon={MonetizationOnIcon}
							dropdownList={[
								<CustomDropdown
									data-ref="multi"
									innerDropDown
									key={9658665}
									dropPlacement="right-start"
									noLiPadding
									navDropdown
									hoverColor={dropdownHoverColor}
									buttonText="Tickets"
									buttonProps={{
										className: classes.navLink,
										color: "transparent",
									}}
									buttonIcon={AddCircleOutline}
									dropdownList={[
										<Link to="/Ticket" className={classes.dropdownLink}>
											<FormatListNumberedIcon className={classes.dropdownIcons} /> Entry
										</Link>,
										<Link to="/TicketHistory" className={classes.dropdownLink}>
											<History className={classes.dropdownIcons} /> History
										</Link>,
									]}
								/>,
								<CustomDropdown
									data-ref="multi"
									innerDropDown
									key={9658665}
									dropPlacement="right-start"
									noLiPadding
									navDropdown
									hoverColor={dropdownHoverColor}
									buttonText="Bill"
									buttonProps={{
										className: classes.navLink,
										color: "transparent",
									}}
									buttonIcon={ReceiptIcon}
									dropdownList={[
										<Link to="/Payment" className={classes.dropdownLink}>
											<FormatListNumberedIcon className={classes.dropdownIcons} /> Entry
										</Link>,
										<Link to="/PaymentHistory" className={classes.dropdownLink}>
											<History className={classes.dropdownIcons} /> History
										</Link>,
									]}
								/>,
								<Link to="/Bank" className={classes.dropdownLink}>
									<BusinessIcon className={classes.dropdownIcons} /> Bank
								</Link>,
								<Link to="/AdminBalance" className={classes.dropdownLink}>
									<CreditCard className={classes.dropdownIcons} /> Admin Balance
								</Link>,
							]}
						/>
					</ListItem>
				)}
				{[2, 3, 4, 5, 19, 20, 23, 24, 25, 26, 27, 29].includes(user.userTypeId) && !user.isDm && (
					<ListItem className={classes.listItem}>
						<CustomDropdown
							noLiPadding
							navDropdown
							hoverColor={dropdownHoverColor}
							buttonText={t("bill")}
							buttonProps={{
								className: classes.navLink,
								color: "transparent",
							}}
							buttonIcon={ReceiptIcon}
							dropdownList={[
								<Link to="/Bill" className={classes.dropdownLink}>
									<PriorityHigh className={classes.dropdownIcons} /> {t("due")}
								</Link>,
								<Link to="/BillHistory" className={classes.dropdownLink}>
									<History className={classes.dropdownIcons} /> {t("history")}
								</Link>,
							]}
						/>
					</ListItem>
				)}
				{[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(user.userTypeId) && (
					<List className={classes.listItem}>
						<Button round simple color="transparent">
							<Link to="/TopUp">
								<AccountBalanceWalletIcon />
								Top Up
							</Link>
						</Button>
					</List>
				)}
				<ListItem className={classes.listItem}>
					<CustomDropdown
						noLiPadding
						navDropdown
						hoverColor={dropdownHoverColor}
						buttonText={t("settings")}
						buttonProps={{
							className: classes.navLink,
							color: "transparent",
						}}
						buttonIcon={SettingsIcon}
						dropdownList={[
							[1, 2, 3, 6, 9, 10, 14, 15, 16, 17, 19, 20, 21, 22, 26, 27, 28].includes(user.userTypeId) ? (
								<Link to="/InvitationLink" className={classes.dropdownLink}>
									<ShareIcon className={classes.dropdownIcons} /> Invitation Link
								</Link>
							) : (
								""
							),
							<Link to="/ChangePassword" className={classes.dropdownLink}>
								<LockIcon className={classes.dropdownIcons} /> {t("changePassword")}
							</Link>,
							[5, 8, 13, 25].includes(user.userTypeId) ? (
								<Link to="/GetToken" className={classes.dropdownLink}>
									<VpnKeyIcon className={classes.dropdownIcons} /> Get API Token
								</Link>
							) : (
								""
							),
							[2, 19, 20, 24, 26, 27].includes(user.userTypeId) ? (
								<Link to="/Bank" className={classes.dropdownLink}>
									<BusinessIcon className={classes.dropdownIcons} /> Bank
								</Link>
							) : (
								""
							),
							[1, 2, 4, 19, 20, 23, 24, 26, 27].includes(user.userTypeId) && !lu ? (
								<Link to="/LoginUser" className={classes.dropdownLink}>
									<SupervisedUserCircleIcon className={classes.dropdownIcons} />
									Login Admin
								</Link>
							) : (
								""
							),
							[2, 6, 9, 14, 19, 24, 26].includes(user.userTypeId) && !lu ? (
								<Link to="/Domain" className={classes.dropdownLink}>
									<div className={classes.dropdownIcons}>
										<Icon name="app store" />
									</div>
									{t("Apliccation")}
								</Link>
							) : (
								""
							),
							// user.LoginUsers ?
							// <Link to="/Bot" className={classes.dropdownLink}>
							//   <AndroidIcon className={classes.dropdownIcons} /> Telegram Bot
							// </Link>:'',
						]}
					/>
				</ListItem>
				<List className={classes.listItem}>
					<Button color="danger" round simple onClick={this.handleLogout}>
						<Logout />
						{t("logout")}
					</Button>
				</List>
				<List className={classes.listItem}>
					<Dropdown
						inline
						className={classes.navLink}
						options={[
							{ key: "id", value: "id", flag: "id", text: "ID" },
							{ key: "en", value: "en", flag: "us", text: "EN" },
						]}
						defaultValue={this.state.selectedLang}
						// value={this.state.selectedLang}
						onChange={(e, { value }) => {
							// this.setState({selectedLang:value})
							i18next.changeLanguage(value)
						}}
					/>
				</List>
			</List>
		)
	}
}
HeaderLink.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(styles))(HeaderLink)
