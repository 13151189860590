import { facebookColor } from "assets/jss/material-kit-pro-react";
import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  grayColor,
  whiteColor,

} from "assets/jss/material-kit-pro-react"

export default function BaseOptions(){
  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: grayColor[3],
    // ...theme.typography.subtitle2
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: grayColor[5],
    // ...theme.typography.h3
  };
  return {
    colors: [
        facebookColor,
				warningColor[0],
				grayColor[0],
				primaryColor[0],
				successColor[0],
				dangerColor[0]
			],
	
			// Chart
			chart: {
				toolbar: { show: false },
				zoom: { enabled: false },
				// animations: { enabled: false },
				foreColor: grayColor[1],
				// fontFamily: theme.typography.fontFamily
			},
	
			// States
			states: {
				hover: {
					filter: {
						type: 'lighten',
						value: 0.04
					}
				},
				active: {
					filter: {
						type: 'darken',
						value: 0.88
					}
				}
			},
	
			// Fill
			fill: {
				opacity: 1,
				gradient: {
					type: 'vertical',
					shadeIntensity: 0,
					opacityFrom: 0.4,
					opacityTo: 0,
					stops: [0, 100]
				}
			},
	
			// Datalabels
			dataLabels: { enabled: true },
	
			// Stroke
			stroke: {
				width: 3,
				curve: 'smooth',
				lineCap: 'round'
			},
	
			// Grid
			grid: {
				strokeDashArray: 3,
				// borderColor: grayColor[0]
			},
	
			// Xaxis
			xaxis: {
				axisBorder: { show: false },
				axisTicks: { show: false }
			},
	
			// Markers
			markers: {
				size: 0,
				strokeColors: whiteColor
			},
	
			// Tooltip
			// tooltip: {
			// 	x: {
			// 		show: false
			// 	}
			// },
	
			// Legend
			legend: {
				show: true,
				fontSize: 13,
				position: 'top',
				horizontalAlign: 'right',
				markers: {
					radius: 12
				},
				fontWeight: 500,
				itemMargin: { horizontal: 12 },
				labels: {
					colors: grayColor[1]
				}
			},
	
			// plotOptions
			plotOptions: {
				// Bar
				bar: {
					columnWidth: '28%',
					borderRadius: 4,
				},
				// Pie + Donut
				pie: {
					donut: {
						labels: {
							show: true,
							value: LABEL_VALUE,
							total: LABEL_TOTAL
						}
					}
				},
				// Radialbar
				radialBar: {
					track: {
						strokeWidth: '100%',
						background: grayColor[1]
					},
					dataLabels: {
						value: LABEL_VALUE,
						total: LABEL_TOTAL
					}
				},
				// Radar
				radar: {
					polygons: {
						fill: { colors: ['transparent'] },
						strokeColors: grayColor[1],
						connectorColors: grayColor[5]
					}
				},
				// polarArea
				polarArea: {
					rings: {
						strokeColors: grayColor[1],
					},
					spokes: {
						connectorColors: grayColor[5]
					}
				}
			},
	
			// Responsive
			responsive: [
				{
					// sm
					// breakpoint: theme.breakpoints.values.sm,
					options: {
						plotOptions: { bar: { columnWidth: '40%' } }
					}
				},
				{
					// md
					// breakpoint: theme.breakpoints.values.md,
					options: {
						plotOptions: { bar: { columnWidth: '32%' } }
					}
				}
			],
  }
}