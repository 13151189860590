import React,{ Suspense } from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from "notistack";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import 'semantic-ui-css/semantic.min.css'

// pages for this product
import App from "App"
import "i18nextInit"

ReactDOM.render(
  <Suspense fallback="loading...">
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </Suspense>,
  document.getElementById("root")
);
// serviceWorker.register();
