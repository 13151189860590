/*eslint-disable*/
import React from "react"

// core components
import Header from "components/Header/Header.js"
import HeaderLinks from "components/Header/HeaderLinks.js"
import Footer from "components/Footer/Footer.js"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

// Pages
import Dashboard from "content/Dashboard"
import UserDetail from "content/User/UserDetail"
import Billing from "content/User/Billing"
import User from "content/User"
import BalanceMutation from "content/User/BalanceMutation"
import Transaction from "content/User/Transaction"
import TransactionLog from "content/User/TransactionLog"
import TransactionByProduct from "content/User/TransactionByProduct"
import InvitationLink from "content/User/InvitationLink"
import ChangePassword from "content/User/ChangePassword"
import TopUp from "content/User/TopUp"
import Bill from "content/Bill"
import BillHistory from "content/Bill/BillHistory"
import Payment from "content/Payment"
import GetToken from "content/User/GetToken"
import CaTopUp from "content/User/CaTopUp"
import CreateUser from "./User/CreateUser"
import ViewOTP from "content/User/ViewOtp"

import AdminBalance from "content/User/AdminBalance"

import H2hCreate from "content/H2h/Create"

import Product from "content/Product"
import ProductUser from "content/Product/ProductUser"
import ProductSwitching from "content/Product/ProductSwitching"
import AdminNominal from "content/Product/AdminNominal"
import ProductCreate from "content/Product/ProductCreate"
import Bot from "content/Bot"

import Bank from "content/Topup/Bank"
import Ticket from "content/Topup/Ticket"
import TicketHistory from "content/Topup/TicketHistory"

import FeeTemplate from "content/FeeTemplate"

import Partner from "content/Partner/Partner"
import RekonLog from "content/User/RekonLog"
import TransactionPartner from "content/User/TransactionPartner"

import LoginUser from "content/LoginUser"

// libs
import moment from "moment"
import { fnumber } from "components/Helper"
import Config from "config"
import axios from "axios"

import secureStorage from "libs/secureStorage"
import Resource from "libs/resource"
// jss
import styles from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"
import Domain from "./User/Domain"
import AppSetting from "./User/AppSetting"

import textLogo from "assets/img/ginlogo.png"

const style = {
	...styles,
	headerLogoDefault: {
		borderRadius: 10,
		height: 40,
		width: "auto",
	},
	imageLogoDefault: {
		borderRadius: "40% !important",
		height: 50,
		marginRight: 10,
		width: "auto",
	},
	headerLogo: {
		borderRadius: 10,
		height: 40,
		width: "auto",
	},
	imageLogo: {
		borderRadius: "40% !important",
		height: 50,
		marginRight: 10,
		width: "auto",
	},
}

class Content extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: true,
			currentPage: "Dashboard Accounting",
			params: [],
			loggingOut: false,
			balance: "",
			bill: "",
			appName: "",
			logo: "/android-icon-512x512.png",
			info: "",
		}
	}
	UNSAFE_componentWillMount() {
		if (secureStorage.getItem("token")) {
			axios.defaults.headers.Authorization = "Bearer " + secureStorage.getItem("token")
		}
		this.sessionCheck()
	}
	componentDidMount() {
		this.setState({ currentPage: "Monitoring" })
		this.loadBalance()
		this.loadBill()
		window.location.hostname.indexOf("payfren") === -1 && this.initResources()
	}
	initResources() {
		Resource.checkUpdate()
		Resource.getInfo()
			.then((info) => {
				this.setState({ appName: info.appName, info })
			})
			.catch((err) => {
				console.log(err)
			})
	}

	sessionCheck() {
		axios.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				if (error.response && error.response.data == "Forbidden Access") {
					secureStorage.removeItem("token")
					secureStorage.removeItem("user")
					this.setState({ loggingOut: true })
				}
				return Promise.reject(error)
			}
		)
	}
	loadBalance() {
		const user = secureStorage.getItem("user")
		axios
			.post(Config.ApiUrl + "/user/balance")
			.then((res) => {
				this.setState({ balance: fnumber(res.data.balance) })
				secureStorage.setItem("balance", res.data.balance)
			})
			.catch((error) => {})
	}
	loadBill() {
		const user = secureStorage.getItem("user")
		axios
			.post(Config.ApiUrl + "/bill")
			.then((res) => {
				this.setState({ bill: fnumber(res.data.amount) })
				secureStorage.setItem("bill", res.data.amount)
			})
			.catch((error) => {})
	}
	logout() {
		axios
			.post(Config.ApiUrl + "/user/logout")
			.then((res) => {
				secureStorage.removeItem("token")
				secureStorage.removeItem("user")
				this.setState({ loggingOut: true })
			})
			.catch((err) => {})
	}
	handleBalanceChange = () => {
		this.loadBalance()
	}
	handleLogout = (e) => {
		this.logout()
	}
	handleDrawerOpen = () => {
		this.setState({ open: true })
	}
	handleDrawerClose = () => {
		this.setState({ open: false })
	}
	isDefaultHostname(hostname) {
		if (hostname.indexOf("payfren") !== -1) return true
		else if (hostname.indexOf("gateway-in") !== -1) return true
		else if (hostname.indexOf("localhost") !== -1) return true
		else return false
	}
	render() {
		const hostname = window.location.hostname
		const { classes } = this.props
		const user = secureStorage.getItem("user") ? secureStorage.getItem("user") : null
		if (this.state.loggingOut) return <Redirect to="/Dashboard" />
		if (this.props.location.pathname === "/") {
			return <Redirect to="/Dashboard" />
		}

		return (
			<Router>
				<div>
					<Header
						brand={
							// window.location.hostname.indexOf('payfren')===-1?this.state.appName+' Dashboard':'Gateway-IN Dashboard'
							<span
								// style={}
								className={!this.isDefaultHostname(hostname) ? classes.headerLogo : classes.headerLogoDefault}
							>
								{!this.isDefaultHostname(hostname) ? (
									<img src={this.state.logo} className={classes.imageLogo} alt={this.state.appName} />
								) : (
									<img src={textLogo} className={classes.imageLogoDefault} alt={"Gateway-IN"} />
								)}
								{!this.isDefaultHostname(hostname) ? this.state.appName + " Dashboard" : "Gateway-IN Dashboard"}
							</span>
						}
						links={<HeaderLinks dropdownHoverColor="dark" onLogout={this.handleLogout} />}
						fixed
						color="dark"
					/>
					<Route path="/Dashboard" component={Dashboard} />
					{user && user.userTypeId === 1 && (
						<div>
							<Route path="/AdminNominal" component={AdminNominal} />
							<Route path="/Ticket" component={Ticket} />
							<Route path="/TicketHistory" component={TicketHistory} />
							{/* <Route path="/Payment" component={Payment} /> */}
							<Route path="/ProductCreate" component={ProductCreate} />
							<Route
								path="/AdminBalance"
								render={(props) => (
									<AdminBalance {...props} isAuthed={true} onBalanceChange={this.handleBalanceChange} />
								)}
							/>
							<Route path="/ViewOtp" component={ViewOTP} />
						</div>
					)}
					{user && [1, 2, 6, 9, 14, 19, 24, 26].includes(user.userTypeId) && (
						<div>
							<Route path="/DomainSetting" component={AppSetting} />
							<Route path="/Domain" component={Domain} />
						</div>
					)}
					{user && [1, 2, 3, 4, 19, 20, 21, 22, 23, 24, 26, 27, 29].includes(user.userTypeId) && (
						<div>
							<Route path="/Bank" component={Bank} />
							<Route path="/Payment" component={Payment} />
							<Route path="/LoginUser" component={LoginUser} />
						</div>
					)}

					{user &&
						([1, 2, 3, 19, 20, 21, 22, 24, 26, 27, 28].includes(user.userTypeId) ||
							([3].includes(user.userTypeId) && user.isAdministrator)) && (
							<div>
								<Route path="/PaymentHistory" render={(props) => <BillHistory {...props} isCharger={true} />} />
							</div>
						)}
					<Route path="/Bot" component={Bot} />
					<Route path="/Product" component={Product} />
					{user && [5, 8].includes(user.userTypeId) && <Route path="/ListProduct" component={ProductUser} />}
					{/* {user&&[5,8].includes(user.userTypeId) && (
             <Route path="/Partner" component={Partner} />
          )} */}
					<Route path="/Partner" component={Partner} />
					{user && [1].includes(user.userTypeId) && (
						<div>
							<Route path="/Rekon" component={RekonLog} />
							<Route path="/TransactionPartner" component={TransactionPartner} />
						</div>
					)}
					<Route path="/ProductSwitching" component={ProductSwitching} />
					<Route
						path="/UserDetail"
						render={(props) => <UserDetail {...props} isAuthed={true} onBalanceChange={this.handleBalanceChange} />}
					/>

					{[1, 2, 3, 6, 9, 10, 14, 15, 16, 17, 19, 20, 21, 22, 26, 27, 28].includes(user.userTypeId) && (
						<Route path="/InvitationLink" component={InvitationLink} />
					)}
					{[1, 2, 3, 6, 9, 10, 12, 14, 15, 16, 17, 19, 20, 21, 21, 22, 24, 26, 27, 28].includes(user.userTypeId) && (
						<div>
							<Route path="/User" component={User} />
							<Route path="/FeeTemplate" component={FeeTemplate} />
						</div>
					)}
					<Route path="/BillingUser/:userId?" render={(props) => <Billing {...props} isAuthed={true} />} />
					<Route path="/BalanceMutation/:userId?" component={BalanceMutation} />
					<Route path="/Transaction" component={Transaction} />
					<Route path="/TransactionByItem" component={TransactionByProduct} />
					<Route path="/TransactionLog" component={TransactionLog} />
					{/* <Route path="/transaction-by-product" component={TransactionByProduct} /> */}
					{[1, 24].includes(user.userTypeId) && <Route path="/H2hCreate" component={H2hCreate} />}
					<Route path="/CreateUser" component={CreateUser} />
					<Route path="/ChangePassword" component={ChangePassword} />
					{[2, 3, 4, 5, 19, 20, 23, 24, 25, 26, 27, 29].includes(user.userTypeId) && !user.isDm && (
						<Route path="/Bill" component={Bill} />
					)}
					{[1, 2, 3, 4, 5, 19, 20, 23, 24, 25, 26, 27, 29].includes(user.userTypeId) && !user.isDm && (
						<Route path="/BillHistory" component={BillHistory} />
					)}
					{[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(user.userTypeId) && (
						<Route path="/TopUp" component={TopUp} />
					)}
					{([2, 20, 27].includes(user.userTypeId) || ([3].includes(user.userTypeId) && user.isAdministrator)) && (
						<Route path="/CaTopUp" component={CaTopUp} />
					)}
					{[5, 8, 13, 25].includes(user.userTypeId) && <Route path="/GetToken" component={GetToken} />}
					<Footer
						className={classes.footer}
						content={
							<div>
								<div className={classes.left}>
									<List className={classes.list}>
										<ListItem className={classes.inlineBlock}>
											<a className={classes.block}>GIN Team</a>
										</ListItem>
									</List>
								</div>
								<div className={classes.right}>
									&copy; Copyright {moment().year()}, Powered by{" "}
									{this.state.info && this.state.info.aliasName ? this.state.info.aliasName : "GIN"}.
								</div>
							</div>
						}
					/>
				</div>
			</Router>
		)
	}
}
Content.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Content)
