import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import Config from "config";
import axios from "axios";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

import Copy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import QRCode from "react-qr-code";
import { WhatsappShareButton } from "react-share";
import { SocialIcon } from "react-social-icons";

import secureStorage from "libs/secureStorage";
import { Label } from "semantic-ui-react";

const styles = (theme) => ({
  ...style,
  btnCopy: {
    marginTop: 20,
  },
  grid: {
    textAlign: "center",
  },
});

class InvitationLink extends React.Component {
  constructor(props) {
    super();
    this.state = {
      invitationLink: "",

      invitationLinkDm: "",
      invitationLinkDp: "",
      invitationLinkTl: "",
      invitationLinkFd: "",
      invitationLinkFt: "",
      invitationLinkGt: "",

      open: false,
      openDm: false,
      openDp: false,
      openTl: false,
      openFd: false,
      openFt: false,
      openGt: false,
    };
    this.input = React.createRef();
    this.inputDm = React.createRef();
    this.inputDp = React.createRef();
    this.inputTl = React.createRef();
    this.inputFd = React.createRef();
    this.inputFt = React.createRef();
    this.inputGt = React.createRef();
  }
  componentWillMount() {
    // const user = secureStorage.getItem('user')
    //console.log(user)
    this.initInvitation();
  }

  initInvitation = () => {
    const user = secureStorage.getItem("user");
    axios
      .post(Config.ApiUrl + "/user/invitation")
      .then((res) => {
        const splitted = window.location.hostname.split(".");
        ["dashboard", "monitoring"].includes(splitted[0]) && splitted.shift();
        // splitted[0]==='monitoring'&&splitted.shift()
        const hostname = splitted.join(".");

        if (user.id === 1) {
          const path = `https://${hostname}/reg/`;
          // console.log(res.data)
          const { DM, DP, TL, FD, FT, GT } = res.data;
          // const path = Config.PayFren+'/reg/'
          this.setState({
            invitationLinkDm: path + DM,
            invitationLinkDp: path + DP,
            invitationLinkTl: path + TL,
            invitationLinkFd: path + FD,
            invitationLinkFt: path + FT,
            invitationLinkGt: path + GT,
          });
        } else {
          const path = `https://${hostname}/reg/${res.data}`;
          this.setState({ invitationLink: path });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClick = (target) => {
    if (target === "invitationLink") {
      this.input.current.select();
      document.execCommand("copy");
      this.setState({ open: true });
    } else if (target === "invitationLinkDm") {
      this.inputDm.current.select();
      document.execCommand("copy");
      this.setState({ openDm: true });
    } else if (target === "invitationLinkTl") {
      this.inputTl.current.select();
      document.execCommand("copy");
      this.setState({ openTl: true });
    } else if (target === "invitationLinkDp") {
      this.inputDp.current.select();
      document.execCommand("copy");
      this.setState({ openDp: true });
    } else if (target === "invitationLinkFd") {
      this.inputFd.current.select();
      document.execCommand("copy");
      this.setState({ openFd: true });
    } else if (target === "invitationLinkFt") {
      this.inputFt.current.select();
      document.execCommand("copy");
      this.setState({ openFt: true });
    } else if (target === "invitationLinkGt") {
      this.inputGt.current.select();
      document.execCommand("copy");
      this.setState({ openFt: true });
    }
  };
  handleTooltipClose = (target) => {
    switch (target) {
      case "invitationLink":
        this.setState({ open: false });
        break;
      case "invitationLinkDm":
        this.setState({ openDm: false });
        break;
      case "invitationLinkTl":
        this.setState({ openTl: false });
        break;
      case "invitationLinkDp":
        this.setState({ openDp: false });
        break;
      case "invitationLinkFd":
        this.setState({ openFd: false });
        break;
      case "invitationLinkFt":
        this.setState({ openFt: false });
        break;
      case "invitationLinkGt":
        this.setState({ openGt: false });
        break;
      default:
    }
  };

  render() {
    const user = secureStorage.getItem("user");
    const { classes, t } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              {user.id !== 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLink}
                        ref={this.input}
                      />
                      <Input
                        id="InvitationLink"
                        labelText="Invitation Link"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLink,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLink")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLink")
                          }
                          open={this.state.open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) => this.handleClick("invitationLink")}
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLink} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLink}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="blue">
                    DM 2 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkDm}
                        ref={this.inputDm}
                      />
                      <Input
                        id="invitationLinkDm"
                        // labelText= "DM 2 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkDm,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkDm")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkDm")
                          }
                          open={this.state.openDm}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkDm")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkDm} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkDm}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="orange">
                    DP 3 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkDp}
                        ref={this.inputDp}
                      />
                      <Input
                        id="invitationLinkDp"
                        // labelText= "DP 3 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkDp,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkDp")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkDp")
                          }
                          open={this.state.openDp}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkDp")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkDp} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkDp}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="teal">
                    TL 3 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkTl}
                        ref={this.inputTl}
                      />
                      <Input
                        id="invitationLinkTl"
                        // labelText= "TL 3 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkTl,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkTl")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkTl")
                          }
                          open={this.state.openTl}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkTl")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkTl} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkTl}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="purple">
                    FD 5 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkFd}
                        ref={this.inputFd}
                      />
                      <Input
                        id="invitationLinkFd"
                        // labelText= "FD 5 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkFd,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkFd")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkFd")
                          }
                          open={this.state.openFd}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkFd")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkFd} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkFd}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="green">
                    FT 5 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkFt}
                        ref={this.inputFt}
                      />
                      <Input
                        id="invitationLinkFt"
                        // labelText= "FT 5 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkFt,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkFt")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkFt")
                          }
                          open={this.state.openFt}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkFt")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkFt} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkFt}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
              {user.id === 1 && (
                <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Label ribbon size="big" color="red">
                    GT 4 Levels
                  </Label>
                  <GridContainer justify="center">
                    <GridItem
                      xl={10}
                      lg={10}
                      md={10}
                      sm={7}
                      xs={7}
                      className={classes.grid}
                    >
                      <input
                        style={{
                          position: "absolute",
                          left: "-5000px",
                          top: "-5000px",
                        }}
                        visible="false"
                        defaultValue={this.state.invitationLinkFt}
                        ref={this.inputGt}
                      />
                      <Input
                        id="invitationLinkGt"
                        // labelText= "GT 4 Levels"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.invitationLinkGt,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xl={2}
                      lg={2}
                      md={2}
                      sm={5}
                      xs={5}
                      className={classes.grid}
                    >
                      <ClickAwayListener
                        onClickAway={(e) =>
                          this.handleTooltipClose("invitationLinkGt")
                        }
                      >
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={(e) =>
                            this.handleTooltipClose("invitationLinkGt")
                          }
                          open={this.state.openGt}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-end"
                          title="Copied"
                        >
                          <Button
                            color="instagram"
                            onClick={(e) =>
                              this.handleClick("invitationLinkGt")
                            }
                            className={classes.btnCopy}
                          >
                            <Copy /> {t("copy")}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <QRCode value={this.state.invitationLinkGt} />
                    </GridItem>
                    <GridItem
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.grid}
                    >
                      <WhatsappShareButton url={this.state.invitationLinkGt}>
                        <SocialIcon
                          network="whatsapp"
                          className={classes.whatsapp}
                        />
                      </WhatsappShareButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )}
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}
InvitationLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(InvitationLink);
