import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// UI Components
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button"
// import Checkbox from "@material-ui/core/Checkbox"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Tooltip from "@material-ui/core/Tooltip"

import { Dimmer, Loader, Segment,Label } from 'semantic-ui-react'

//Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
// import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";


// Libs
import Config from 'config'
import axios from 'axios';
import { Add, Cancel, Delete,  Save } from '@material-ui/icons';

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

import secureStorage from 'libs/secureStorage'

const style = theme => ({
  ...modalStyle(theme),
	...contactUsStyle,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
	buttonLink : {
		textTransform:'none',
		width:'100%',
		fontSize : 14,
	}
})

const CustomTransition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});


class FeeTemplate extends React.Component{
	constructor(props){
		super(props);
		this.state = {
				
			// paging states >>
			rowsPerPage : 15,
			currentPage : 1,
			totalPages : 0,
			totalData : 0,
			rows : [],
			//<<pagin states
			keyword : '',

			name:'',
			selectedRow:'',

			selectedSalesType: '',

			isLoading:false,
      modalShow:false,
      modalTitle:'',
      modalMode:'',
      
      backPayload:'',
		}
	}
	componentDidMount(){
		this.loadTemplates();
	}

	loadTemplates = (params) =>{
		this.setState({isLoading:true})
		let payload = {
			keyword : params && params.keyword ? params.keyword : this.state.keyword,
			currentPage : params && params.currentPage ? params.currentPage : this.state.currentPage,
			rowsPerPage : params && params.rowsPerPage? params.rowsPerPage:this.state.rowsPerPage
		}
    if(secureStorage.getItem('backPayload')){
			payload = secureStorage.getItem('backPayload')
			this.setState({
				keyword : payload.keyword,
				currentPage : payload.currentPage,
				rowsPerPage : payload.rowsPerPage,
			})
			secureStorage.removeItem('backPayload')
		}
		axios.post(Config.ApiUrl + '/user/feetemplate',payload)
		.then(res => {
				// console.log(res.data);
				this.setState({
					rows:res.data.templates,
					totalPages : res.data.totalPages,
					totalData : res.data.totalData,
					isLoading:false,
					keyword : '',
				})

		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}
	handleClear = (e) =>{
		const params = {
			keyword : '',
			currentPage:1
		}
		this.setState(params)
		this.loadTemplates(params)
	}

  render(){
		const {classes,t} = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='huge'>{t('Fee Templates')}</Label>
									</GridItem>

									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											
											<GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
												<Input
													labelText={t('search')}
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder : `Template ID / Template Name`,
														value : this.state.keyword,
														onChange : (e)=>{this.setState({keyword:e.target.value})},
														onKeyPress : (e)=>{if(e.key === 'Enter'){
															const params = {
																currentPage:1,
																keyword:e.target.value
															}
															this.setState(params)
															this.loadTemplates(params)
														}}
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
												<span>
												<div className={classNames(classes.buttonGroup,classes.buttons)}>
													<Tooltip id="tooltip-search" title="Search" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="twitter" size="sm" round onClick={()=>this.loadTemplates()} className={classes.firstButton} ><SearchIcon /></Button>
													</Tooltip>
													<Tooltip id="tooltip-clear" title="Clear" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="google" size="sm" round onClick={()=>this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
													</Tooltip>
												</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                {user.userTypeId===1 && <Button color="instagram" round onClick={()=>{this.setState({modalShow:true,modalMode:'add',modalTitle:`${t('add')} Template`})}} ><Add />{`${t('add')} Template`}</Button>}
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>
									{this.state.rows.length>0 ? 
									<Table
									 	sortable
										stickyHead
										stickyFoot
										striped
										tableHead={["#", "ID", t('name'),t('delete')]}
										tableData={
											this.state.rows.length?this.state.rows.map((row,index)=>(
											Array.isArray(row)?row:[	
												(index+1)+ (isNaN(this.state.rowsPerPage)? 0 :((this.state.currentPage-1)* this.state.rowsPerPage)), 
                        <Link to={{pathname : '/FeeTemplateDt', selectedTemplate : row,backPayload:this.state.backPayload}}>
                          <Button color="facebook" simple className={classes.buttonLink}>{row.id.toString().padStart(2,0)}</Button>
                        </Link>,
                        <Link to={{pathname : '/FeeTemplateDt', selectedTemplate : row,backPayload:this.state.backPayload}}>
                          <Button color="facebook" simple className={classes.buttonLink}>{row.name}</Button>
                        </Link>,
                        <Button justIcon size="sm" round color="instagram" onClick={()=>this.setState({selectedRow:row,modalShow:true,modalMode:'delete',modalTitle:`${t('delete')} Template`})} >
                          <Delete />
                        </Button>,
											])):[]
										}
										textAlignCells={['center','center','center']}
										textAlignForCells={[0,1,2]}
										textAlignHeaders={['center','center','center']}
										textAlignForHeaders={[0,1,2]}
										rawData={this.state.rows}
										onSort={(data)=>this.setState({rows:data})}
									/>:<Label size='huge' color='blue'>{t('No available templates')}</Label>}
									
								</Segment>
							</GridItem>
							{this.state.rows.length>0 &&
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage)=>{
											if(currentPage !== this.state.currentPage){
												this.setState({currentPage})
												this.loadUsers({currentPage})
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5,15,25,'All']}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value)=>{
											this.setState({rowsPerPage:value,currentPage:1})
											this.loadUsers({rowsPerPage:value,currentPage:1})
										}}
									/>
								</div>
							</GridItem>}
            </GridContainer>
            {/* Modal >> */}
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={CustomTransition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
                {this.state.modalMode==='add'?
								<GridContainer justify="center">
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<Input labelText={t(`Template Name`)} id="name" formControlProps={{ fullWidth: true }}
											inputProps={{
												placeholder : t(`Template Name`),
												value : this.state.name,
												onChange : (e)=>{this.setState({name:e.target.value})},
											}}
										/>
									</GridItem>
									{user.userTypeId===1 &&
									<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
										<FormControl fullWidth className={classes.selectFormControl}>
											<InputLabel htmlFor="salesType-select" className={classes.selectLabel}>Sales Type</InputLabel>
											<Select MenuProps={{className: classes.selectMenu}} classes={{select: classes.select}}
												inputProps={{ name: "salesType", id: "salesType"}}
												value={this.state.selectedSalesType}
												onChange={(e)=>{
													
													this.setState({selectedSalesType:e.target.value})}
												}
											>
												<MenuItem disabled classes={{root: classes.selectMenuItem}} value={''}>Sales Type</MenuItem>
												{SalesTypes.map((item,index)=>(
													<MenuItem key={index} classes={{ root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value={item.value}>
														{item.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</GridItem>}
								</GridContainer>
								:`${t('Are you sure want to')} ${t('delete')} ${this.state.selectedRow.name}`}
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
              <div>
                <Button onClick={() => this.setState({ modalShow: false,selectedDomain:'',partnerErr:''})} color="warning" round><Cancel />{t('cancel')}</Button>
                {this.state.modalMode==='add'?
                  <Button onClick={()=>{
                    axios.post(Config.ApiUrl + '/user/feetemplate/add',{
                      name : this.state.name,
											salesTypeId : this.state.selectedSalesType.value
                    })
                    .then(res=>{
                      this.setState({
                        modalShow:false,
                        name : '',
                      })
                      this.loadTemplates()
                    })
                    .catch(err=>{
                      console.log(err)
                    })
                  }} color="facebook" round ><Save />{t('add')}</Button>:
                  <Button onClick={()=>{
                    axios.post(Config.ApiUrl + '/user/feetemplate/delete',{
                      id:this.state.selectedRow.id,
                    })
                    .then(res=>{
                      this.setState({
                        modalShow:false,
                        selectedRow : '',
                      })
                      this.loadTemplates()
                    })
                    .catch(err=>{
                      console.log(err)
                    })
                  }} color="danger" round ><Delete />{t('delete')}</Button>
                }
              </div>
							</DialogActions>
						</Dialog>
						{/* << Modal */}
          </div>
        </div>
      </div>
		)
	}
}
FeeTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withStyles(style),withTranslation())(FeeTemplate);

const SalesTypes =[{name:'DM',value:1},{name:'TL',value:2}] 