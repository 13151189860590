// cores
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
// icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudDownload from "@material-ui/icons/CloudDownload";

// UI components
import Table from "components/Table/Table";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Pagination from "components/Pagination/Pagination.js";
import Button from "components/CustomButtons/Button";
import Badge from "components/Badge/Badge.js";
import Input from "components/CustomInput/CustomInput.js";

import { Dimmer, Loader, Segment, Label, Dropdown } from "semantic-ui-react";

// libs
import Config from "config";
import axios from "axios";
import { fnumber, formatDateTime } from "components/Helper";
import GSearchActions from "components/GSearchActions.js";
import moment from "moment";
import secureStorage from "libs/secureStorage";

// styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const styles = (theme) => ({
  ...style,
});

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});
class BalanceMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      // paging states >>
      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      selectedTypes: [],
      selectedProducts: [],
      products: [],
      types: [
        { id: 0, name: "ALL" },
        { id: 1, name: "TRANSAKSI" },
        { id: 0, name: "DEPOSIT" },
      ],

      //   dialog
      showModal: false,
      modalTitle: "",
      modalMessage: "",

      isLoading: false,
      submitDownloadDisabled: false,

      keyword: "",
      sum: null,
    };
  }
  componentDidMount() {
    this.loadProduct();

    this.loadMutation();
  }

  loadMutation = (params) => {
    const userId = this.props.match.params.userId;
    console.log(this.props.match.params);
    this.setState({ isLoading: true });
    const payload = {
      userId,
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      // paging payload >>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
      // << paging payload
      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      types: this.state.selectedTypes,
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
    };
    axios
      .post(Config.ApiUrl + "/user/balance/mutation", payload)
      .then((res) => {
        //console.log(res.data)
        this.setState({
          name: res.data.name,
          rows: res.data.balances,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          sum: res.data.sum,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  loadProduct() {
    axios
      .post(Config.ApiUrl + "/product/dropdown")
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCloseDialog = (e) => {
    this.setState({ showModal: false });
  };

  handleDownload = async () => {
    this.setState({ submitDownloadDisabled: true });
    try {
      const userId = this.props.match.params.userId;
      const endpoint = `${Config.ApiUrl}/user/balance/download`;
      const payload = {
        userId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        products:
          this.state.selectedProducts &&
          this.state.selectedProducts.length > 0 &&
          this.state.selectedProducts,
        types: this.state.selectedTypes,
        name: this.state.name,
        keyword: this.state.keyword,
      };

      const response = await axios.post(endpoint, payload, {
        responseType: "blob", // Untuk memastikan response adalah blob
        timeout: 10000, // 10seconds
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const a = document.createElement("a");
        a.href = url;

        a.setAttribute(
          "download",
          `Mutasi ${this.state.name} ${moment(this.state.startDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")}-${moment(this.state.endDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")}`
        );
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ submitDownloadDisabled: false, showModal: false });
      } else {
        this.setState({
          submitDownloadDisabled: false,
          showModal: true,
          modalTitle: "Failed",
          modalMessage: "Network failed",
        });
      }
    } catch (err) {
      console.log(err.message);
      this.setState({
        submitDownloadDisabled: false,
        showModal: true,
        modalTitle: "Failed",
        modalMessage: "Network failed",
      });
    }
  };

  render() {
    const userId =
      this.props.match.params.userId &&
      parseInt(this.props.match.params.userId);

    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");
    // console.log({ userId, user });
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            {/* Search Action Component>> */}

            <GridContainer justify="center">
              {/* FIlter */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                {/* Date & Button Submit */}
                <GridContainer justify="center">
                  <GridItem
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Button
                      onClick={(e) => {
                        if (
                          this.props.location &&
                          this.props.location.backPayload
                        ) {
                          secureStorage.setItem(
                            "backPayload",
                            this.props.location.backPayload
                          );
                        }
                        this.props.history.goBack();
                      }}
                      color="danger"
                      round
                    >
                      <ArrowBackIosIcon />
                      {t("back")}
                    </Button>
                    <br />
                    <Label size="big">{t("Balance Mutation")}</Label>
                    {this.state.name && (
                      <Label size="medium" color="orange">
                        {this.state.name}
                      </Label>
                    )}
                  </GridItem>
                  <GridItem xl={8} lg={8} md={8} sm={12} xs={12}>
                    <GSearchActions
                      onChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      onSubmit={this.loadMutation}
                    />
                  </GridItem>
                </GridContainer>
                {/* Select */}
                <GridContainer justify="center">
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="ITEM"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedProducts}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedProducts: value,
                          selectedCategories: [],
                        });
                      }}
                      options={this.state.products.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="salesType-select"
                        className={classes.selectLabel}
                      >
                        Type
                      </InputLabel>
                      <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={this.state.selectedTypes}
                        onChange={(e) => {
                          const val = e.target.value;
                          this.setState({ selectedTypes: val });
                        }}
                        inputProps={{ name: "type", id: "type" }}
                      >
                        <MenuItem
                          disabled
                          classes={{ root: classes.selectMenuItem }}
                          value={""}
                        >
                          Type
                        </MenuItem>
                        {this.state.types.map((item, index) => (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={index}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {/* Keyword */}
                <GridContainer justify="center">
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Input
                      labelText={t("search")}
                      id="keyword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "IDPEL / TRID / REFID / REMARK",
                        value: this.state.keyword,
                        onChange: (e) => {
                          this.setState({ keyword: e.target.value });
                        },
                        onKeyPress: (e) => {
                          if (e.key === "Enter") {
                            this.setState({ keyword: e.target.value });
                            this.loadMutation({ keyword: e.target.value });
                          }
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              {/* Button Download */}
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "right" }}
                justify="center"
              >
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<CloudDownload />}
                  onClick={this.handleDownload}
                  disabled={this.state.submitDownloadDisabled}
                >
                  Download File
                </Button>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <Table
                    sortable
                    stickyHead
                    stickyFoot
                    striped
                    tableHead={[
                      "#",
                      "REFID",
                      "IDPEL",
                      t("Remark"),
                      t("IN/OUT"),
                      t("FROM/TO"),
                      t("Date"),
                      t("Amount"),
                      t("balance"),
                      user.serTypeId === 1 && "PIC",
                    ]}
                    tableData={this.state.rows.map((row, index) => [
                      //   index +
                      //     1 +
                      //     (isNaN(this.state.rowsPerPage)
                      //       ? 0
                      //       : (this.state.currentPage - 1) *
                      //         this.state.rowsPerPage),
                      row.TrMutation &&
                      row.TrMutation.TrSale &&
                      row.TrMutation.TrSale.id
                        ? row.TrMutation.TrSale.id.toString().padStart(8, 0)
                        : "-",
                      row.TrMutation &&
                      row.TrMutation.TrSale &&
                      row.TrMutation.TrSale.refId
                        ? row.TrMutation.TrSale.refId
                        : "-",
                      row.TrMutation &&
                      row.TrMutation.TrSale &&
                      row.TrMutation.TrSale.customerCode
                        ? row.TrMutation.TrSale.customerCode
                        : "-",
                      row.TrMutation.remark ? row.TrMutation.remark : "-",
                      userId ? (
                        row.TrMutation.senderId === userId ? (
                          <Badge color="danger">{t("out")}</Badge>
                        ) : (
                          <Badge color="success">{t("in")}</Badge>
                        )
                      ) : row.TrMutation.senderId === user.id ? (
                        <Badge color="danger">{t("out")}</Badge>
                      ) : (
                        <Badge color="success">{t("in")}</Badge>
                      ),
                      userId
                        ? row.TrMutation.senderId === userId
                          ? row.TrMutation.Receiver.name
                          : row.TrMutation.Sender.name
                        : row.TrMutation.senderId !== user.id
                        ? row.TrMutation.Sender
                          ? row.TrMutation.Sender.name
                          : "ADDITION"
                        : row.TrMutation.Receiver
                        ? row.TrMutation.Receiver.name
                        : "DEDUCTION",
                      formatDateTime(row.TrMutation.createdAt),
                      fnumber(row.TrMutation.amount),
                      fnumber(row.amount),
                      user.serTypeId === 1
                        ? row.TrMutation.LoginUser
                          ? row.TrMutation.LoginUser.name
                          : "-"
                        : "",
                    ])}
                    tableFoot={[
                      "-",
                      "-",
                      "-",
                      "-",
                      "-",
                      "TOTAL",
                      this.state.sum ? fnumber(this.state.sum.balanceIn) : 0,
                      this.state.sum ? fnumber(this.state.sum.balanceOut) : 0,
                      this.state.sum ? fnumber(this.state.sum.result) : 0,
                    ]}
                    textAlignCells={[
                      "center",
                      "center",
                      "center",
                      "right",
                      "right",
                    ]}
                    textAlignForCells={[4, 5, 6, 7, 8]}
                    textAlignHeaders={[
                      "center",
                      "center",
                      "center",
                      "right",
                      "right",
                    ]}
                    textAlignForHeaders={[4, 5, 6, 7, 8]}
                    textAlignFooters={[
                      "center",
                      "left",
                      "right",
                      "right",
                      "right",
                    ]}
                    textAlignForFooters={[4, 5, 6, 7, 8]}
                    rawData={this.state.rows}
                    onSort={(data) => this.setState({ rows: data })}
                  />
                </Segment>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadMutation({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 10, 15, 25, 50, 100, "All"]}
                    defaultRowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={(value) => {
                      console.log(value);
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadMutation({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
            {/* Dialog */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.showModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({showModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <p>{this.state.modalMessage}</p>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button onClick={this.handleCloseDialog} color="success">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
BalanceMutation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(BalanceMutation);
