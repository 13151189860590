import React from "react";
import moment from "moment";
import { fnumber } from "helpers/Helper";
import receiptToArray from "helpers/receiptToArray";

const modalReceipt = (modalBody) => {
  modalBody = !Array.isArray(modalBody) ? receiptToArray(modalBody) : modalBody;
  return (
    <pre style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
      {modalBody
        ? modalBody.map((row, index) =>
            Array.isArray(row) ? (
              row.length > 1 ? (
                <p key={index}>
                  {row[0].toString().padEnd(15, " ") +
                    ": " +
                    (typeof row[1] !== "number"
                      ? row[0] === "TGL TRANSAKSI"
                        ? moment(row[1]).format("DD/MM/YYYY HH:mm:ss")
                        : row[1]
                      : "Rp." + fnumber(row[1]).padStart(9, " "))}
                </p>
              ) : (
                <p key={index}>{row[0]}</p>
              )
            ) : (
              <p key={index}>{row}</p>
            )
          )
        : null}
    </pre>
  );
};
export default modalReceipt;
