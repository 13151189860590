import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// UI Components
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button"
import Checkbox from "@material-ui/core/Checkbox"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Tooltip from "@material-ui/core/Tooltip"

import { Dimmer, Loader, Segment,Label } from 'semantic-ui-react'

//Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Check from "@material-ui/icons/Check";

//styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";


// Libs
import Config from 'config'
import axios from 'axios';
import { Add, Cancel, Edit, Save } from '@material-ui/icons';

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

import secureStorage from 'libs/secureStorage'

const style = theme => ({
  ...modalStyle(theme),
	...contactUsStyle,
	...tooltipsStyle,
	buttons:{
		marginTop:'25px'
	},
	buttonLink : {
		textTransform:'none',
		width:'100%',
		fontSize : 14,
	}
})

const CustomTransition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});


class Domain extends React.Component{
	constructor(props){
		super(props);
		this.state = {
				
			// paging states >>
			rowsPerPage : 15,
			currentPage : 1,
			totalPages : 0,
			totalData : 0,
			rows : [],
			//<<pagin states
			keyword : '',

			name:'',
      appName:'',
			// captchaKey : '',
			// captchaSecret : '',
			selectedDomain:'',

			isLoading:false,
      modalShow:false,
      modalTitle:'',
      
      backPayload:'',
		}
	}
	componentDidMount(){
		this.loadDomains();
	}

	loadDomains = (params) =>{
		this.setState({isLoading:true})
		let payload = {
			keyword : params && params.keyword ? params.keyword : this.state.keyword,
			currentPage : params && params.currentPage ? params.currentPage : this.state.currentPage,
			rowsPerPage : params && params.rowsPerPage? params.rowsPerPage:this.state.rowsPerPage
		}
    if(secureStorage.getItem('backPayload')){
			payload = secureStorage.getItem('backPayload')
			this.setState({
				keyword : payload.keyword,
				currentPage : payload.currentPage,
				rowsPerPage : payload.rowsPerPage,
			})
			secureStorage.removeItem('backPayload')
		}
		axios.post(Config.ApiUrl + '/user/group/domain',payload)
		.then(res => {
				// console.log(res.data);
				this.setState({
					rows:res.data.domains,
					totalPages : res.data.totalPages,
					totalData : res.data.totalData,
					isLoading:false,
					keyword : '',
				})

		})
		.catch(error => {
			this.setState({isLoading:false})
			console.log(error);
		});
	}
	handleClear = (e) =>{
		const params = {
			keyword : '',
			currentPage:1
		}
		this.setState(params)
		this.loadUsers(params)
	}
	// 
	handleCheckBox = (id,isActive) => {
		axios.post(Config.ApiUrl + '/user/group/domain/setactive',{
			id,
			isActive
		})
		.then(res => {
			
		})
		.catch(error => {
			console.log(error);
		});
	}

  render(){
		const {classes,t} = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size='huge'>{t('Applications')}</Label>
									</GridItem>
									{this.state.rows.length>0 &&
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GridContainer justify="center">
											
											<GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
												<Input
													labelText={t('search')}
													id="keyword"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														placeholder : `Domain ID / Domain Name / App Name`,
														value : this.state.keyword,
														onChange : (e)=>{this.setState({keyword:e.target.value})},
														onKeyPress : (e)=>{if(e.key === 'Enter'){
															const params = {
																currentPage:1,
																keyword:e.target.value
															}
															this.setState(params)
															this.loadUsers(params)
														}}
													}}
												/>
											</GridItem>
											<GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
												<span>
												<div className={classNames(classes.buttonGroup,classes.buttons)}>
													<Tooltip id="tooltip-search" title="Search" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="twitter" size="sm" round onClick={()=>this.loadUsers()} className={classes.firstButton} ><SearchIcon /></Button>
													</Tooltip>
													<Tooltip id="tooltip-clear" title="Clear" placement="top"classes={{ tooltip: classes.tooltip }}>
														<Button color="google" size="sm" round onClick={()=>this.handleClear()} className={classes.lastButton} ><ClearAllIcon /></Button>
													</Tooltip>
												</div>
												</span>
											</GridItem>
										</GridContainer>
									</GridItem>}
								</GridContainer>
								
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                {user.userTypeId===1 && <Button color="instagram" round onClick={()=>{this.setState({modalShow:true,modalTitle:'Add Domain'})}} ><Add />Add domain</Button>}
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>
									{this.state.rows.length>0 ? 
									<Table
									 	sortable
										stickyHead
										stickyFoot
										striped
										tableHead={["#", "ID", 'Domain',"App Name",user.userTypeId===1&&'Active',user.userTypeId===1&&'Action']}
										tableData={
											this.state.rows.length?this.state.rows.map((row,index)=>(
											Array.isArray(row)?row:[	
												(index+1)+ (isNaN(this.state.rowsPerPage)? 0 :((this.state.currentPage-1)* this.state.rowsPerPage)), 
												row.id.toString().padStart(2,0),
                        <Link to={{pathname : '/DomainSetting', selectedDomain : row,backPayload:this.state.backPayload}}>
                          <Button color="facebook" simple className={classes.buttonLink}>{row.name}</Button>
                        </Link>,
                        <Link to={{pathname : '/DomainSetting', selectedDomain : row,backPayload:this.state.backPayload}}>
                          <Button color="facebook" simple className={classes.buttonLink}>{row.appName??'-'}</Button>
                        </Link>,
												user.userTypeId===1 && <Checkbox
													id={'c'+row.id}
													checked={this.state['c'+row.id] !== undefined ? this.state['c'+row.id] : row.isActive}
													tabIndex={-1}
													onClick={(e) => {
														console.log('c'+row.id)
														if(row.isActive && this.state['c'+row.id] === undefined){
															//console.log(this.state['c'+product.id] !== undefined)
															this.setState({['c'+row.id]: !row.isActive });
														} 
														else
															this.setState({['c'+row.id]: e.target.checked }); 
														
														this.handleCheckBox(row.id,e.target.checked) 
													}}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
												/>,
                        user.userTypeId===1 && <Button color="warning" size='sm' justIcon round onClick={()=>{
                          this.setState({
                            modalShow : true,
                            modalTitle : 'Edit '+row.name,
                            name : row.name,
                            appName : row.appName??'',
														// captchaKey : row.captchaKey??'',
														// captchaSecret : row.captchaSecret??'',
                            selectedDomain:row,
                          })
                        }} ><Edit /></Button>
											])):[]
										}
										textAlignCells={['center','center','center']}
										textAlignForCells={[0, 2, 3,]}
										textAlignHeaders={['center','center','center']}
										textAlignForHeaders={[0, 2, 3,]}
										rawData={this.state.rows}
										onSort={(data)=>this.setState({rows:data})}
									/>:<Label size='huge' color='blue'>{t('No available apps')}</Label>}
									
								</Segment>
							</GridItem>
							{this.state.rows.length>0 &&
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter} >
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage)=>{
											if(currentPage !== this.state.currentPage){
												this.setState({currentPage})
												this.loadUsers({currentPage})
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5,15,25,'All']}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value)=>{
											this.setState({rowsPerPage:value,currentPage:1})
											this.loadUsers({rowsPerPage:value,currentPage:1})
										}}
									/>
								</div>
							</GridItem>}
            </GridContainer>
            {/* Modal >> */}
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal
							}}
							open={this.state.modalShow}
							TransitionComponent={CustomTransition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								<Input labelText='Name' id="name" formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder : `Domain Name`,
                    value : this.state.name,
                    onChange : (e)=>{this.setState({name:e.target.value})},
                  }}
                />
                <Input labelText='AppName' id="appName" formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder : `App Name`,
                    value : this.state.appName,
                    onChange : (e)=>{this.setState({appName:e.target.value})},
                  }}
                />
								{/* {user.userTypeId===1 &&
								<Input labelText='Captcha Key' id="captchaKey" formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder : `App Name`,
                    value : this.state.captchaKey,
                    onChange : (e)=>{this.setState({captchaKey:e.target.value})},
                  }}
                />}
								{user.userTypeId===1 &&
								<Input labelText='Captcha Secret' id="captchaSecret" formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder : `App Name`,
                    value : this.state.captchaSecret,
                    onChange : (e)=>{this.setState({captchaSecret:e.target.value})},
                  }}
                />} */}
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
              <div>
                <Button onClick={() => this.setState({ modalShow: false,selectedDomain:'',partnerErr:''})} color="warning" round><Cancel /> Cancel</Button>
                <Button onClick={()=>{
                  let url = this.state.modalTitle==='Add Domain' ? 'create':'set'
                  axios.post(Config.ApiUrl + '/user/group/domain/' + url,{
                    domain : this.state.selectedDomain??undefined,
                    name : this.state.name,
                    appName : this.state.appName??undefined,
										// captchaKey : this.state.captchaKey??undefined,
										// captchaSecret : this.state.captchaSecret??undefined,
                  })
                  .then(res=>{
                    this.setState({
                      modalShow:false,
                      name : '',
                      appName:'',
											// captchaKey : '',
											// captchaSecret : '',
                      selectedDomain:'',
                    })
                    this.loadDomains()
                  })
                  .catch(err=>{
                    console.log(err)
                  })
                }} color="facebook" round ><Save /> Save</Button>
              </div>
							</DialogActions>
						</Dialog>
						{/* << Modal */}
          </div>
        </div>
      </div>
		)
	}
}
Domain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withStyles(style),withTranslation())(Domain);
