import React from "react";
// import ReactExport from 'react-export-excel'
import ReactExport from "react-data-export";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import Button from "components/CustomButtons/Button";

import _ from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataset: [],
      disabled: true,
    };
  }
  componentDidMount() {}
  updateDataset(dataset) {
    if (dataset.length > 0) this.setState({ dataset, disabled: false });
    else this.setState({ disabled: true });
  }

  render() {
    return (
      <ExcelFile
        element={
          <Button
            color="instagram"
            size="sm"
            disabled={this.state.disabled}
            round
          >
            <CloudDownloadIcon />
            {this.props.title ? this.props.title : "download"}
          </Button>
        }
        filename={
          this.props.filename ? this.props.filename : "PayFren Price List"
        }
      >
        {this.state.dataset.length > 0 && (
          <ExcelSheet
            data={this.state.dataset}
            name={this.props.title ? this.props.title : "Sheet 1"}
          >
            {Object.keys(this.state.dataset[0]).map((item) => (
              <ExcelColumn key={item} label={_.startCase(item)} value={item} />
            ))}
          </ExcelSheet>
        )}
      </ExcelFile>
    );
  }
}
export default ExportExcel;
