import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { useTranslation } from 'react-i18next'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import paginationStyle from "assets/jss/material-kit-pro-react/components/paginationStyle.js";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';

const styles = {
  ...paginationStyle,
  ...contactUsStyle,
  rowsPerPage:{
    width : '50px'
  }
}

const useStyles = makeStyles(styles);

export default function Pagination(props) {
  const {  
    color, 
    className,
    
    totalButton,
    currentPage,
    totalPages,
    totalData,

    onChangePage,

  } = props;
  
  const { t } = useTranslation()
  const [rowsPerPage,setRowsPerpage] = React.useState(props.defaultRowsPerPage?props.defaultRowsPerPage:props.rowsPerPage?props.rowsPerPage[0]:'')
  const classes = useStyles();
  const paginationClasses = classNames(classes.pagination, className);
  // useEffect(()=>{
  //   props.defaultRowsPerPage && setRowsPerpage(props.defaultRowsPerpage)
  // })
  function handleRowsPerpage(e){
    setRowsPerpage(e.target.value)
    if(props.onChangeRowsPerPage) props.onChangeRowsPerPage(e.target.value)
    else alert('Rows per page '+e.target.value)
  }
  const paginationButton = () =>{

		let i = currentPage-parseInt(totalButton/2) < 1 ? 1 : currentPage-parseInt(totalButton/2)
		let n = currentPage+parseInt(totalButton/2) > totalPages ? totalPages : currentPage+parseInt(totalButton/2)
		
		if(totalPages<totalButton)
			n = totalPages
			//n+=parseInt(totalButton/2)
		let buttonPages = [{ disabled: currentPage===1?true:false ,text: <FirstPageIcon /> , onClick:()=>onChangePage(1) }]
		buttonPages.push({ disabled: currentPage===1?true:false, text: <NavigateBeforeIcon />, onClick:()=>onChangePage(currentPage-1)})
		
		if(currentPage>parseInt(totalButton/2)+1)
			buttonPages.push({ disabled: true , text: '...'})
		for(i ; i<=n ; i++){
			//console.log(i)
			buttonPages.push({active: currentPage===i ? true : false, text: i, onClick:(value)=>onChangePage(value) })
		}

		if(currentPage<totalPages-parseInt(totalButton/2))
			buttonPages.push({ disabled: true , text: '...'})
		
		buttonPages.push({ disabled: currentPage === totalPages || totalPages=== 0? true : false ,text: <NavigateNextIcon />, onClick:()=>onChangePage(currentPage+1) })
		buttonPages.push({ disabled: currentPage === totalPages || totalPages=== 0? true : false ,text: <LastPageIcon />, onClick:()=>onChangePage(totalPages) })
		
		buttonPages.push({ disabled: true , text: t('of')+' '+ totalData })
		
		//console.log(buttonPages)
		return buttonPages
	}
  const pages = props.pages?props.pages:paginationButton()
  return (
    <GridContainer>
      <GridItem xs={12} sm={8}>
        <ul className={paginationClasses}>
          {pages.map((prop, key) => {
            const paginationLink = classNames({
              [classes.paginationLink]: true,
              [classes[color]]: prop.active,
              [classes.disabled]: prop.disabled
            });
            // console.log({paginationClasses})
            return (
              <li className={classes.paginationItem} key={key}>
                {prop.onClick !== undefined ? (
                  <Button
                    onClick={()=>prop.onClick(prop.text)}
                    className={paginationLink}
                    disabled={prop.disabled}
                  >
                    {prop.text}
                  </Button>
                ) : (
                  <Button
                    onClick={() => alert("you've clicked " + prop.text)}
                    className={paginationLink}
                    disabled={prop.disabled}
                  >
                    {prop.text}
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
      </GridItem>
      {props.rowsPerPage &&
      <GridItem xs={12} sm={4}>
        <ul className={paginationClasses}>
          <li className={classes.paginationItem} key={321}>
            <Button disabled={true} className={classes.paginationLink}>{t('Rows per page')}</Button>
          </li>
          <li className={classes.paginationItem} key={123} style={{marginTop:'-18px'}}>
            <FormControl fullWidth className={classes.selectFormControl}>
              
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={rowsPerPage}
                onChange={handleRowsPerpage}
                inputProps={{
                  name: "perPage",
                  id: "perPage"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={''}
                >
                  {t('Rows per page')}
                </MenuItem>
                {props.rowsPerPage.map((item,index)=>(
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item}
                  >
                    {t(item)+''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </li>
        </ul>
      </GridItem>}
    </GridContainer>
  );
}

Pagination.defaultProps = {
  color: "primary",
  totalButton:7,
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalData: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string,PropTypes.element])
        .isRequired,
      onClick: PropTypes.func
    })
  ),
  className: PropTypes.string,
  rowsPerPage: PropTypes.array,
  onChange: PropTypes.func,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "instagram",
    "transparent"
  ]),
};
