import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

// UI Components
import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
// import Badge from "components/Badge/Badge.js";
// import Checkbox from "@material-ui/core/Checkbox";
import PageView from "@material-ui/icons/Pageview";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Breadcrumbs } from "@material-ui/core";

import { Dimmer, Loader, Segment, Label } from "semantic-ui-react";

//Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Check from "@material-ui/icons/Check";
import ReceiptIcon from "@material-ui/icons/Receipt";
// import MonetizationOnIcon from '@material-ui/icons-material/MonetizationOn';

//styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

// Libs
import Config from "config";
import axios from "axios";
import { fnumber } from "components/Helper";
import secureStorage from "libs/secureStorage";
import { EndUsers, BillingUsers } from "content/User/libs";
import _ from "lodash";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import { Person } from "@material-ui/icons";

import { isManagable } from "content/User/libs";

const style = (theme) => ({
  ...modalStyle(theme),
  ...contactUsStyle,
  ...tooltipsStyle,
  buttons: {
    marginTop: "25px",
  },
});

const CustomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // paging states >>
      rowsPerPage: 3,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states
      keyword: "",

      backPayload: "",

      parentTree: [],
      parent: "",

      isLoading: false,

      modalShow: false,
      selectedRow: "",

      info: "",
    };
  }
  componentDidMount() {
    const info = secureStorage.getItem("domainInfo");

    const user = secureStorage.getItem("user");
    this.setState({
      parentTree: [user],
      parent: user,
      info,
    });

    const rowsPerPage =
      info && ["saminara.com"].includes(info.name) ? { rowsPerPage: 5 } : {};
    this.loadUsers({ parent: user, ...rowsPerPage });
  }

  loadUsers = (params) => {
    this.setState({ isLoading: true });
    let payload = {
      // parentTree : params && params.parentTree ? params.parentTree : this.state.parentTree,
      // parent :  params && params.parentTree ? params.parentTree[params.parentTree.length-1] : this.state.parentTree[this.state.parentTree.length-1],
      parent: params && params.parent ? params.parent : this.state.parent,
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage:
        params && params.currentPage
          ? params.currentPage
          : this.state.currentPage,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
    };
    // console.log(payload.parentTree)
    // console.log({ backPayload: secureStorage.getItem("backPayload") });
    if (secureStorage.getItem("backPayload")) {
      payload = secureStorage.getItem("backPayload");
      //   console.log({ payload });
      this.setState({
        // parentTree : payload.parentTree,
        parent: payload.parent,
        keyword: payload.keyword,
        currentPage: payload.currentPage,
        rowsPerPage: payload.rowsPerPage,
      });
      secureStorage.removeItem("backPayload");
    }
    // const url = !EndUsers.includes(payload.parent.userTypeId)?'/user':'/user/parenttree'
    axios
      .post(Config.ApiUrl + "/user", payload)
      .then((res) => {
        // console.log(res.data);
        this.setState({
          rows: res.data.users,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          backPayload: payload,
          parentTree: res.data.parentTree,
          isLoading: false,
          // keyword: "",
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  getParentTree(parent) {
    // console.log(this.state.parentTree,parent)
    // console.log(_.findIndex(this.state.parentTree,parent))
    if (_.findIndex(this.state.parentTree, parent) === -1)
      axios
        .post(Config.ApiUrl + "/user/parenttree", {
          parent,
        })
        .then((res) => {
          this.setState({ parentTree: res.data.parentTree });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
  }
  handleClear = (e) => {
    const user = secureStorage.getItem("user");
    const params = {
      keyword: "",
      parentTree: [user],
      parent: user,
      currentPage: 1,
    };
    this.setState(params);
    this.loadUsers(params);
  };

  handleCheckBox = (userId, isBlocked) => {
    const user = secureStorage.getItem("user");
    const url = user.userTypeId === 1 ? "" : "ca/";
    axios
      .post(Config.ApiUrl + "/user/" + url + "block", {
        userId,
        isBlocked,
      })
      .then((res) => {
        //console.log(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes, t } = this.props;
    const info = this.state.info;
    const user = secureStorage.getItem("user");

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">{t("userList")}</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GridContainer justify="center">
                      <GridItem xl={5} lg={5} md={5} sm={7} xs={12}>
                        <Input
                          labelText={t("search")}
                          id="keyword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: `UID / ${t("name")} / ${t("phone")}`,
                            value: this.state.keyword,
                            onChange: (e) => {
                              this.setState({ keyword: e.target.value });
                            },
                            onKeyPress: (e) => {
                              if (e.key === "Enter") {
                                const params = {
                                  currentPage: 1,
                                  keyword: e.target.value,
                                };
                                this.setState(params);
                                this.loadUsers(params);
                              }
                            },
                          }}
                        />
                      </GridItem>
                      <GridItem xl={2} lg={2} md={5} sm={5} xs={12}>
                        <span>
                          <div
                            className={classNames(
                              classes.buttonGroup,
                              classes.buttons
                            )}
                          >
                            <Tooltip
                              id="tooltip-search"
                              title="Search"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="twitter"
                                size="sm"
                                round
                                onClick={() => this.loadUsers()}
                                className={classes.firstButton}
                              >
                                <SearchIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-clear"
                              title="Clear"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="google"
                                size="sm"
                                round
                                onClick={() => this.handleClear()}
                                className={classes.lastButton}
                              >
                                <ClearAllIcon />
                              </Button>
                            </Tooltip>
                          </div>
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Breadcrumbs style={{ marginTop: 20 }}>
                  {this.state.parentTree.map((row, index) => (
                    <Button
                      color="google"
                      simple
                      key={index}
                      onClick={(e) => {
                        // const parentTree = this.state.parentTree.slice(0,index+1)
                        if (!EndUsers.includes(row.userTypeId)) {
                          const params = {
                            currentPage: 1,
                            // parentTree
                            parent: row,
                          };
                          this.setState(params);
                          this.loadUsers(params);
                        }
                      }}
                    >{`${row.name} (${row.userType})`}</Button>
                  ))}
                </Breadcrumbs>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <Table
                    sortable
                    stickyHead
                    stickyFoot
                    striped
                    tableHead={[
                      "#",
                      "UID",
                      t("name"),
                      "Detail",
                      t("userType"),
                      t("balance"),
                      t("billing"),
                      ([1, 2, 19, 20, 24, 26, 27].includes(user.userTypeId) ||
                        user.isAdministrator) &&
                        "Block",
                      "Data",
                      ([1, 2, 19, 20, 24, 26, 27].includes(user.userTypeId) ||
                        user.isAdministrator) &&
                        t("dispensation"),
                    ]}
                    tableData={
                      this.state.rows.length
                        ? this.state.rows.map((row, index) =>
                            Array.isArray(row)
                              ? row
                              : [
                                  index +
                                    1 +
                                    (isNaN(this.state.rowsPerPage)
                                      ? 0
                                      : (this.state.currentPage - 1) *
                                        this.state.rowsPerPage),
                                  row.id.toString().padStart(6, 0),
                                  <Button
                                    color="instagram"
                                    simple
                                    onClick={(e) => {
                                      const params = {
                                        currentPage: 1,
                                        parent: row,
                                      };
                                      this.setState({ keyword: "" });
                                      if (!EndUsers.includes(row.userTypeId)) {
                                        this.setState(params);
                                        this.loadUsers(params);
                                      } else this.getParentTree(row);
                                    }}
                                  >
                                    {row.name}
                                  </Button>,
                                  <Button
                                    justIcon
                                    size="sm"
                                    round
                                    color="instagram"
                                    component={Link}
                                    to={{
                                      pathname: "/UserDetail",
                                      selectedUser: row,
                                      backPayload: this.state.backPayload,
                                    }}
                                  >
                                    <PageView />
                                  </Button>,
                                  row.userType,
                                  <Button
                                    color="instagram"
                                    simple
                                    component={Link}
                                    to={{
                                      pathname: `/BalanceMutation/${row.id}`,
                                      selectedUser: row,
                                      backPayload: this.state.backPayload,
                                    }}
                                  >
                                    {fnumber(row.balance)}
                                  </Button>,
                                  <Button
                                    color="instagram"
                                    simple
                                    onClick={(e) => {
                                      if (
                                        BillingUsers.includes(row.userTypeId)
                                      ) {
                                        this.props.history.push(
                                          `/BillingUser/${row.id}`,
                                          {
                                            selectedUser: row,
                                            backPayload: this.state.backPayload,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <ReceiptIcon />
                                  </Button>,
                                  // <Button justIcon size="sm" round color="instagram" component={Link} to={{pathname : `/BillingUser/${row.id}`, selectedUser : row,backPayload: this.state.backPayload}}>
                                  // 	<ReceiptIcon />
                                  // </Button>,
                                  ([1, 2, 19, 20, 24, 26, 27].includes(
                                    user.userTypeId
                                  ) ||
                                    user.isAdministrator) && (
                                    <Checkbox
                                      id={"c" + row.id}
                                      checked={
                                        this.state["c" + row.id] !== undefined
                                          ? this.state["c" + row.id]
                                          : row.isBlocked
                                      }
                                      tabIndex={-1}
                                      onClick={(e) => {
                                        if (
                                          row.isBlocked &&
                                          this.state["c" + row.id] === undefined
                                        ) {
                                          this.setState({
                                            ["c" + row.id]: !row.isBlocked,
                                          });
                                        } else
                                          this.setState({
                                            ["c" + row.id]: e.target.checked,
                                          });

                                        this.handleCheckBox(
                                          row.id,
                                          e.target.checked
                                        );
                                      }}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                      }}
                                    />
                                  ),
                                  <Button
                                    justIcon
                                    size="sm"
                                    round
                                    color="instagram"
                                    onClick={() => {
                                      this.setState({
                                        selectedRow: row,
                                        modalShow: true,
                                      });
                                    }}
                                  >
                                    <Person />
                                  </Button>,
                                  ([1, 2, 19, 20, 24, 26, 27].includes(
                                    user.userTypeId
                                  ) ||
                                    user.isAdministrator) && (
                                    <Checkbox
                                      disabled={!isManagable(user, row)}
                                      id={"d" + row.id}
                                      checked={
                                        this.state["d" + row.id] !== undefined
                                          ? this.state["d" + row.id]
                                          : row.dispensation
                                      }
                                      tabIndex={-1}
                                      onClick={(e) => {
                                        if (
                                          row.dispensation &&
                                          this.state["d" + row.id] === undefined
                                        ) {
                                          this.setState({
                                            ["d" + row.id]: !row.dispensation,
                                          });
                                        } else
                                          this.setState({
                                            ["d" + row.id]: e.target.checked,
                                          });
                                        const url =
                                          user.userTypeId === 1 ? "" : "ca/";
                                        axios
                                          .post(
                                            Config.ApiUrl +
                                              "/user/" +
                                              url +
                                              "dispensation",
                                            {
                                              userId: row.id,
                                              dispensation: e.target.checked,
                                            }
                                          )
                                          .then((res) => {})
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      }}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                      }}
                                    />
                                  ),
                                ]
                          )
                        : []
                    }
                    textAlignCells={[
                      "center",
                      "center",
                      "center",
                      "center",
                      "right",
                    ]}
                    textAlignForCells={[0, 1, 5, 6, 7]}
                    textAlignHeaders={[
                      "center",
                      "center",
                      "center",
                      "center",
                      "right",
                    ]}
                    textAlignForHeaders={[0, 1, 5, 6, 7]}
                    rawData={this.state.rows}
                    onSort={(data) => this.setState({ rows: data })}
                  />
                </Segment>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadUsers({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[3, 5, 10, 15, 25, 50, 100, "All"]}
                    defaultRowsPerPage={
                      info && ["saminara.com"].includes(info.name)
                        ? 5
                        : this.state.rowsPerPage
                    }
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadUsers({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
            {/* Modal >> */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={CustomTransition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>
                  {this.state.selectedRow && this.state.selectedRow.name}
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.selectedRow && (
                  <div>
                    <Input
                      labelText={t("name")}
                      id="name"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        placeholder: t("name"),
                        value: this.state.selectedRow.name,
                      }}
                    />
                    <Input
                      labelText={t("username")}
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        placeholder: t("usernmae"),
                        value: this.state.selectedRow.username,
                      }}
                    />
                    <Input
                      labelText={t("email")}
                      id="email"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        placeholder: t("email"),
                        value: this.state.selectedRow.email,
                      }}
                    />
                    <Input
                      labelText={t("phone")}
                      id="phone"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        placeholder: t("phone"),
                        value: this.state.selectedRow.phone,
                      }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.setState({ modalShow: false })}
                  color="warning"
                  round
                >
                  {t("close")}
                </Button>
              </DialogActions>
            </Dialog>
            {/* << Modal */}
          </div>
        </div>
      </div>
    );
  }
}
User.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(style), withTranslation())(User);
