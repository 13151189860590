import React from 'react';
import classNames from "classnames";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Config from 'config'
import axios from 'axios';
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import { 
	Label,
	Table,
	Button,
	Input,
	Dropdown,
	Confirm,
	Segment,
	Dimmer,
	Loader
} from 'semantic-ui-react'

// import Table from "components/Table/Table";

const styles = theme => ({
	...style,
    
});

class Partner extends React.Component{
    constructor(props){
        super(props);
        this.state = {
					
					partners : [],
					partnerTypes : [],

					isAdding : false,
					isDeleting : false,
					isLoading : false,
        }
    }
    componentDidMount(){
        this.loadData();
        this.loadPartnerType()
    }

    loadData(){
			this.setState({isLoading:true})
			axios.post(Config.ApiUrl + '/partner')
			.then(res => {
				this.setState({
					partners:res.data,
					isLoading:false,
				})
			})
			.catch(err => {
				console.log(err)
			});
		}
		loadPartnerType(){
			axios.post(Config.ApiUrl + '/partner/type',{					          
			})
			.then(res => {
					this.setState({partnerTypes:res.data})
			})
			.catch(error => {
				this.setState({log : error+""});
			});
		}

    render(){
			const {classes} = this.props;
			return (
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.contactContent}>
						<div className={classes.container}>
							<GridContainer justify='center'>
								<GridItem xl={4} lg={4} md={4} sm={12} xs={12} className={classes.grid}>
									<Label size='huge'>Partner</Label>
								</GridItem>
								<GridItem xl={8} lg={8} md={8} sm={12} xs={12} className={classes.grid}>
									<Button 
										onClick={(e)=>this.setState({isAdding:true})}
										color='facebook'
										content='ADD'
										icon='add'
										labelPosition='left'
									/>
								</GridItem>
								<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
									<Segment vertical>
										<Dimmer active={this.state.isLoading} inverted>
											<Loader />
										</Dimmer>
										<Table singleLine>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell>ID</Table.HeaderCell>
													<Table.HeaderCell>Name</Table.HeaderCell>
													<Table.HeaderCell>Type</Table.HeaderCell>
													<Table.HeaderCell>End Point</Table.HeaderCell>
													<Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{
													this.state.isAdding?
														<Table.Row>
															<Table.Cell>---</Table.Cell>
															<Table.Cell><Input fluid size='small' placeholder='Name' onChange={e=>this.setState({addName:e.target.value})} /></Table.Cell>
															<Table.Cell>
																<Dropdown
																	fluid
																	placeholder='Type'
																	search
																	selection
																	options={this.state.partnerTypes}
																	onChange={(e,{value})=>this.setState({addType:value})}
																/>
															</Table.Cell>
															<Table.Cell><Input fluid size='small' placeholder='End Point' onChange={e=>this.setState({addEndPoint:e.target.value})} /></Table.Cell>
															<Table.Cell textAlign='center'>
																<Button.Group>
																	<Button labelPosition='left' icon='cancel' content='CANCEL' onClick={(e)=>this.setState({isAdding:false})} />
																	<Button.Or />
																	<Button positive labelPosition='right' icon='save' content='SAVE'
																		onClick={()=>{
																			const payload = {
																				name : this.state.addName,
																				partnerTypeId : this.state.addType,
																				endPoint : this.state.addEndPoint,
																			}
																			if(payload.name && payload.partnerTypeId && payload.endPoint){
																				axios.post(Config.ApiUrl + '/partner/add',payload)
																				.then(res => {
																					this.setState({
																						isAdding:false,
																						addName : '',
																						addType : '',
																						addEndPoint : '',
																					})
																					this.loadData()
																				}).catch(err=>{
																					this.setState({isAdding:false})
																				})
																			}
																		}}
																	/>
																</Button.Group>
															</Table.Cell>
														</Table.Row>
													:null
												}
												{this.state.partners.map((partner,index)=>(
													<Table.Row key={`partner${index}`}>
														<Table.Cell>{partner.id.toString().padStart(3,0)}</Table.Cell>
														<Table.Cell>{!this.state[`cell${index}`] ?partner.name:
															<Input fluid size='small' placeholder='Name' defaultValue={partner.name}
																onChange={(e)=>{
																	this.setState({[`cellName${index}`]:e.target.value})
																}} 
															/>}
														</Table.Cell>
														<Table.Cell>{!this.state[`cell${index}`] ?partner.PartnerType.name:
															<Dropdown
																fluid
																placeholder='Type'
																search
																selection
																options={this.state.partnerTypes}
																defaultValue={partner.PartnerType.id}
																onChange={(e,{value})=>{
																	this.setState({[`cellType${index}`]:value})
																}} 
															/>
														}</Table.Cell>
														<Table.Cell>{!this.state[`cell${index}`] ?partner.endPoint:
															<Input fluid size='small' placeholder='End Point' defaultValue={partner.endPoint} 
																onChange={(e)=>{
																	this.setState({[`cellEndPoint${index}`]:e.target.value})
																}} 
															/>}
														</Table.Cell>
														<Table.Cell textAlign='center'>
															{!this.state[`cell${index}`] ?
															<Button.Group>
																<Button color='orange' labelPosition='left' icon='edit' content='EDIT' onClick={(e)=>{this.setState({[`cell${index}`]:true})}}/>
																<Button.Or />
																<Button negative labelPosition='right' icon='delete' content='DELETE' 
																	onClick={(e)=>{
																		this.setState({
																			isDeleting:true,
																			partnerDeleting : partner,
																		})
																	}} 
																/>
															</Button.Group>
															:
															<Button.Group>
																<Button labelPosition='left' icon='cancel' content='CANCEL' onClick={(e)=>this.setState({[`cell${index}`]:false})} />
																<Button.Or />
																<Button positive labelPosition='right' icon='save' content='SAVE'
																	onClick={()=>{
																		const payload = {
																			id:partner.id,
																			name : this.state[`cellName${index}`] && this.state.partners[index].name !== this.state[`cellName${index}`] && this.state[`cellName${index}`],
																			partnerTypeId : this.state[`cellType${index}`] && this.state.partners[index].PartnerType.id !== this.state[`cellType${index}`] && this.state[`cellType${index}`],
																			endPoint : this.state[`cellEndPoint${index}`] && this.state.partners[index].endPoint !== this.state[`cellEndPoint${index}`] && this.state[`cellEndPoint${index}`],
																		}
																		if(payload.name || payload.partnerTypeId || payload.endPoint){
																			axios.post(Config.ApiUrl + '/partner/save',payload)
																			.then(res => {
																				this.setState({
																					[`cell${index}`] : false,
																				})
																				this.loadData()
																			}).catch(err=>{
																				this.setState({[`cell${index}`]:false})
																			})
																		}
																		else this.setState({[`cell${index}`]:false})
																	}}
																/>
															</Button.Group>
															}
														</Table.Cell>
													</Table.Row>
												))}
											</Table.Body>
										</Table>
									</Segment>
								</GridItem>
							</GridContainer>
							<Confirm 
								open={this.state.isDeleting} 
								content={`Are you sure want to delete ${this.state.partnerDeleting && this.state.partnerDeleting.name}`} 
								onCancel={()=>this.setState({isDeleting:false})} 
								onConfirm={()=>this.setState({isDeleting:false})}
							/>
					</div>
				</div>
			</div>
        )
    }
}
Partner.propTypes = {
    classes: PropTypes.object.isRequired,
    //theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(Partner);