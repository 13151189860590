/*eslint-disable*/
import React from "react";
// @material-ui/core components

import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Phone from '@material-ui/icons/PhoneAndroid'
import Address from "@material-ui/icons/ContactMail"
import ProvinceIcon from "@material-ui/icons/Map"
import DnsIcon from '@material-ui/icons/Dns';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Input from "components/CustomInput/CustomInput.js";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import {withRouter} from "react-router-dom"
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
/**
 * Alert Snackbar
 */
import SnackbarContent from "components/Snackbar/SnackbarContent.js";




import { Redirect } from 'react-router-dom'
import axios from 'axios'
import Config from 'config'

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import {
	Dropdown
} from 'semantic-ui-react'
import { secureStorage } from "libs";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


class Create extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			checked : [0],
			
			emailSender : '',
			domains:[],

			fullName : '',
			username : '',
			email : '',
			ip : '',
			phone : '',
			address : '',
			userType:'',
			selectedReferrer : '',

			referrers : [],

			alert : false,
			error:[],
			success :[],
			isInviationValid : null,

			redirect : false,
			
			showModal : false,
			modalTitle : '',
			modalMessage :'',

		}
	}
  componentDidMount() {

		
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

		this.loadDomain()
	}
	loadDomain(){
		const user = secureStorage.getItem('user')
		if(user.userTypeId===1){
			this.setState({domains:Domain,emailSender:Domain[0]})
		}
		else{
			axios.get(Config.ApiUrl+ '/h2h/domain')
			.then(res => {
				console.log(res.data)
				this.setState({domains:res.data,emailSender:res.data[0],userType:{value:25}})
			}).catch(err => {
				console.log(err)
			})
		}
	}

	create(){
		const user = secureStorage.getItem('user')
		const payload = {
			emailSender : this.state.emailSender,
			fullName : this.state.fullName,
			username : this.state.username,
			email : this.state.email,
			ip : this.state.ip,
			phone : this.state.phone,
			address : this.state.address,
			userTypeId : this.state.userType.value,
			referrerId : user.userTypeId===1?this.state.selectedReferrer:user.id,
		}

		axios.post(Config.ApiUrl+ '/h2h/create',payload)
		.then(res => {
				console.log(res.data)
				
			this.setState({
				showModal : true,
				modalTitle : 'Success',
				modalMessage : res.data,
				fullName : '',
				username : '',
				email : '',
				ip : '',
				phone : '',
				address : '',
				userType:'',
			})
		}).catch(err => {
			if(err.response){
				this.setState({
					showModal : true,
					modalTitle : 'Failed',
					modalMessage : err.response.data
				})
			}
			else{
				this.setState({
					showModal : true,
					modalTitle : 'Failed',
					modalMessage : 'Network failed'
				})
			}
		});
	}

	//#region Event Handler

	handleRedirect = (e) =>{
		
		if(this.state.modalTitle === 'Success')this.setState({showModal:false})
			//this.setState({redirect:true})
		else
			this.setState({showModal:false})
	}
	
	handleInput = (e) => {
		//alert(e.id)
		//console.log(e.target.id,e.target.value)
		this.setState({[e.target.id]:e.target.value})

	}
	handleBlur = (e) =>{
		
		if(e.target.id !== 'phone' && e.target.id !=='address'){

			if(e.target.value){
				this.setState({success:{...this.state.success,[e.target.id]: true}})
				this.setState({error:{...this.state.error,[e.target.id]: false}})
			}
			else{
				this.setState({success:{...this.state.success,[e.target.id]: false}})
				this.setState({error:{...this.state.error,[e.target.id]: true}})
			}
		}
		//console.log(this.state.error)
	}
	handleSignUp = (e) =>{
		//this.setState({showModal:true})
		let error = []
		if(!this.state.fullName)
			error.fullName =  true
		if(!this.state.username)
			error.username = true
		if(!this.state.email)
			error.email = true
		if(!this.state.ip)
			error.ip = true

		this.setState({error:{...error}})
		
		if(this.state.fullName && this.state.email && this.state.username && this.state.ip && this.state.userType){
			this.create();
		}
		else{
			this.setState({alert:true})
		}
	}

	
	handleAlertExit = () =>{
		this.setState({alert:false})
	}
	
  render(){
		
		if(this.state.redirect){
			return <Redirect to="/" />
		}
		const {classes} = this.props;
		const { ...rest } = this.props;
		const user = secureStorage.getItem('user')
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xl={2} lg={2} md={2} sm={12} xs={12}>
								<h4 className={classes.title}>Create H2H</h4>
							</GridItem>
							<GridItem xs={12} sm={10} md={10}>
								<Card className={classes.cardSignup}>
									<CardBody>
										<GridContainer justify="center">
											<GridItem xs={12} sm={12} md={12}>
												<form className={classes.form}>
													<FormControl fullWidth className={classes.selectFormControl}>
														<InputLabel htmlFor="sender-select" className={classes.selectLabel}>Email Domain Sender *</InputLabel>
														<Select classes={{select: classes.select}} MenuProps={{className: classes.selectMenu}}
															endAdornment= {<InputAdornment position="end" className={classes.inputAdornment}><Email className={classes.inputAdornmentIcon} /></InputAdornment>}
															value={this.state.emailSender}
															onChange={(e)=>{
																this.setState({emailSender:e.target.value})
															}}
															inputProps={{name: "emailSender",id: "emailSender"}}
														>
															<MenuItem disabled classes={{root: classes.selectMenuItem}} value={-1}>Email Sender</MenuItem>
															{this.state.domains.map((item,index)=>(
																<MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}}	
																	key={index} value={item}
																>{item}</MenuItem>
															))}
														</Select>
													</FormControl>
													<Input
														error={this.state.error?this.state.error.fullName:false}
														success={this.state.success?this.state.success.fullName:false}
														id="fullName"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Face className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "e.g : Gateway Indo Network",
															value:this.state.fullName,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Name *"
													/>			
													<Input
														error={this.state.error?this.state.error.username:false}
														success={this.state.success?this.state.success.username:false}
														id="username"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Face className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "e.g : gatewayindonetwork",
															value:this.state.username,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Username *"
													/>
													<Input
														error={this.state.error?this.state.error.email:false}
														success={this.state.success?this.state.success.email:false}
														id="email"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Email className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "e.g : gateway@gateway-in.id ",
															value:this.state.email,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Email *"
													/>
													<Input
														error={this.state.error?this.state.error.ip:false}
														success={this.state.success?this.state.success.ip:false}
														id="ip"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<DnsIcon className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "e.g : 109.120.102.31"+(user.userTypeId===1?", 3.3.3.3 for GET API, 0.0.0.0 for BRF & MIVT":""),
															value:this.state.ip,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "IP *"
													/>
													<Input
														error={this.state.error?this.state.error.phone:false}
														success={this.state.success?this.state.success.phone:false}
														id="phone"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Phone className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "contoh : 081123321456",
															value:this.state.phone,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Phone"
													/>										
													<Input
														error={this.state.error?this.state.error.address:false}
														success={this.state.success?this.state.success.address:false}
														id="address"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<Address className={classes.inputAdornmentIcon} />
																</InputAdornment>
															),
															placeholder: "e.g : Jl. Jenderal Sudirman No. 99 Jagakarsa",
															value:this.state.address,
															onChange:this.handleInput,
															onBlur:this.handleBlur
														}}
														labelText = "Address"
													/>
													{[1].includes(user.userTypeId) &&
													<FormControl fullWidth className={classes.selectFormControl}>
														<InputLabel htmlFor="province-select" className={classes.selectLabel}>Type *</InputLabel>
														<Select MenuProps={{className: classes.selectMenu}} classes={{select: classes.select}}
															inputProps={{name: "userType",id: "userType"}}
															endAdornment= {
																<InputAdornment
																	position="end"
																	className={classes.inputAdornment}
																>
																	<ProvinceIcon className={classes.inputAdornmentIcon} />
																</InputAdornment>
															}
															value={this.state.userType}
															onChange={(e)=>{
																this.setState({userType:e.target.value})
																if(e.target.value && e.target.value.value === 13){
																	axios.post(Config.ApiUrl+ '/h2h/referrer/get')
																	.then(res => {
																		// console.log(res.data)
																		this.setState({referrers:res.data})
																	}).catch(err => {
																		console.log(err)
																	})
																}
																else{
																	this.setState({
																		userType:e.target.value,
																		selectedReferrer : ''
																	})
																}
															}}
															
														>
															<MenuItem disabled classes={{root: classes.selectMenuItem}} value={-1}>Type</MenuItem>
															{UserTypes.map((userType,index)=>(
																<MenuItem key={index} classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}}
																	value={userType}
																>{userType.name}</MenuItem>
															))}
														</Select>
													</FormControl>}
													{ this.state.userType && this.state.userType.value === 13 &&
													<Dropdown
														style={{marginTop:20,marginBottom:20}}
														placeholder='REFERRER'
														fluid
														search
														selection
														value={this.state.selectedReferrer}
														onChange={(e,{value})=>this.setState({selectedReferrer:value})} 
														options={this.state.referrers}
													/>
													}
													{
														this.state.alert?
															<SnackbarContent
															message={
																<span>
																	All ( * ) field must be filled
																</span>
															}
															onExited = {this.handleAlertExit}
															close
															color="danger"
															icon="info_outline"
														/>:null
													}
													<div>
														<div className={classes.textCenter}>
															<Button round color="instagram" onClick={this.handleSignUp}>
																create
															</Button>
														</div>
														<Dialog
															classes={{
																root: classes.modalRoot,
																paper: classes.modal
															}}
															open={this.state.showModal}
															TransitionComponent={Transition}
															keepMounted
															//onClose={() => this.setState({showModal: false})}
															aria-labelledby="classic-modal-slide-title"
															aria-describedby="classic-modal-slide-description"
														>
															<DialogTitle
																id="classic-modal-slide-title"
																disableTypography
																className={classes.modalHeader}
															>
																<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
															</DialogTitle>
															<DialogContent
																id="classic-modal-slide-description"
																className={classes.modalBody}
															>
																<p>{this.state.modalMessage}</p>
															</DialogContent>
															<DialogActions className={classes.modalFooter}>
																	<Button onClick={this.handleRedirect} color="success">OK</Button>
															</DialogActions>
														</Dialog>
													</div>
												</form>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		);
	}
}
Create.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(Create));

const UserTypes = [
	{name:'HTDM',value:8},
	{name:'HTTL',value:5},
	{name:'BORF',value:12},
	{name:'HTRF',value:13},
	{name:'MIVT',value:24},
]
const Domain = ['payfren.id','gateway-in.id']