import React from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
import { withStyles,styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// core components
import {Card,CardHeader} from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomButtons/Button"

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import {fnumber} from 'components/Helper'
import axios from 'axios'
import Config from 'config'

import moment from 'moment'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'

import BaseOptions from 'content/Dashboard/BaseOptions';
import { grayColor } from 'assets/jss/material-kit-pro-react';
import { warningColor } from 'assets/jss/material-kit-pro-react';
import { primaryColor } from 'assets/jss/material-kit-pro-react';
import { successColor } from 'assets/jss/material-kit-pro-react';
import { twitterColor } from 'assets/jss/material-kit-pro-react';
import { googleColor } from 'assets/jss/material-kit-pro-react';
import { facebookColor } from 'assets/jss/material-kit-pro-react';
import { Autorenew } from '@material-ui/icons';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
// import CardBody from 'components/Card/CardBody';

const styles = {
	...style,
}

class Transaction extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			startDate:'',
      endDate:'',
			chartData:[],
			categories:[],
		}
		this.source = axios.CancelToken.source()
	}
	componentDidMount(){
		
	}
	componentWillUnmount(){
		this.source.cancel('Operation cancelled')
	}
  loadTransaction = (params) =>{
		
		this.setState({isLoading:true})

		const payload = {
			startDate : params && params.startDate?params.startDate:this.state.startDate,
			endDate : params && params.endDate?params.endDate:this.state.endDate,
			type:'pie',
		}
		// console.log(payload)
		axios.post(Config.ApiUrl + '/transaction/dasboard',payload,{cancelToken:this.source.token})
		.then(res => {
				// console.log(res.data)
				const {t} = this.props
				this.setState({
					startDate : payload.startDate,
					endDate : payload.endDate,
					categories:res.data.categories.map(item=>t(item)),
					chartData:res.data.sales,
					isLoading:false
				})

		})
		.catch(err => {
			if (!axios.isCancel(err))
				this.setState({isLoading:false})
			console.log(err);
		});
	}
	getChartOptions(){
		
		return _.merge(BaseOptions(),{
			
			colors: [
        twitterColor,
				googleColor,
				facebookColor,
				primaryColor[0],
				successColor[0],
				warningColor[0]
			],
			labels: this.state.categories,
			stroke: { colors: ['#fff'],width:'0.5' },
			legend: { floating: true, horizontalAlign: 'center' },
			dataLabels: { enabled: true, dropShadow: { enabled: true } },
			tooltip: {
				fillSeriesColor: false,
				y: {
					formatter: (seriesName) => fnumber(seriesName),
					title: {
						formatter: (seriesName) => `#${seriesName}`
					}
				}
			},
			plotOptions: {
				pie: { donut: { labels: { show: true } } },
			},
		})
	}
	
	render(){
		const {t,classes} = this.props;
		return (
			<Card>
				<CardHeader title={t('transaction')} 
					subheader={this.state.startDate && t(this.state.startDate.format('MMMM'))} 
					action={
						<IconButton aria-label="settings" onClick={()=>{
							const startDate = moment().startOf('month')
							const endDate = moment()
							this.loadTransaction({startDate,endDate})
						}}>
							<Autorenew />{t('show')}
						</IconButton>
					} 
				/>
				<Segment vertical>
					<Dimmer active={this.state.isLoading} inverted>
						<Loader />
					</Dimmer>
					<ChartWrapperStyle dir="ltr">
						{this.state.chartData.length>0 && 
						<ReactApexChart type="pie" series={this.state.chartData} options={this.getChartOptions()} height={364} />}
					</ChartWrapperStyle>
				</Segment>
			</Card>
		)
	}
  
}
const CHART_HEIGHT = 550;
const LEGEND_HEIGHT = 200;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: 0,
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    // alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${grayColor[0]}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));
Transaction.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withTranslation(), withStyles(styles))(Transaction);