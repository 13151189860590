import React, { Component } from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import { Table, Segment, Label, Dropdown, Grid, Button, Input, Icon } from 'semantic-ui-react'

import Config from 'config'
import axios from 'axios';
import _ from 'lodash'



import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";


import Spreadsheet from "react-spreadsheet";
import secureStorage from 'libs/secureStorage';

const styles = theme => ({
  ...style,
  grid: {
    marginTop: 20
  }
});

class ProductCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,

      payload: '',

      modalShow: false,
      modalTitle: '',
      modalPrice: '',
      modalPsId: '',

      // synchronize spreadsheet
      data: [[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' },]],
      selectedCell: null,

      //left dataset
      rows: [],
      unfiltered: [],
      column: null,
      direction: null,
      selectedRows: [],

      //dropdown
      selectedCategories: [],
      categories: [],

      selectedBillers: [],
      billers: [],

      selectedModel: '',

      selectedPartner: '',
      partners: [],

      //Static Request Payload Spreadsheet
      inquiryRequestData: [[{ value: '' }, { value: '' }]],
      purchaseRequestData: [[{ value: '' }, { value: '' }]],
    };

    // this.spreadsheetRef = React.createRef()
  }

  componentDidMount() {
    this.loadCategory()
    this.loadBiller()
    this.loadParner()

  }

  loadParner = () => {
    axios.post(Config.ApiUrl + '/product/partner/dropdown')
      .then(res => {
        this.setState({ partners: res.data })
      })
      .catch(error => {
        console.log(error);
      });
  }
  loadCategory = () => {
    axios.post(Config.ApiUrl + '/product/category/dropdown')
      .then(res => {
        this.setState({ categories: res.data })
      })
      .catch(error => {
        console.log(error);
      });
  }
  loadBiller = () => {
    axios.post(Config.ApiUrl + '/product/biller/dropdown')
      .then(res => {
        this.setState({ billers: res.data })
      })
      .catch(error => {
        console.log(error);
      });
  }
  sort = (column) => {
    if (column === this.state.column) {
      this.setState({
        rows: this.state.rows.reverse(),
        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending',
      })
    }
    else {
      this.setState({
        column,
        rows: _.sortBy(this.state.rows, [column]),
        direction: 'ascending'
      })
    }
  }

  handleShowModal = () => {
    this.setState({ modalShow: true })
  }
  handleModal = (target) => {

    this.setState({ modalShow: false })
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xl={4} lg={4} md={4} sm={12} xs={12} className={classes.grid}>
                <Label size='huge'>Product Create</Label>
              </GridItem>
              <GridItem xl={8} lg={8} md={8} sm={12} xs={12} className={classes.grid}>
                <Segment raised>
                  <Dropdown
                    placeholder='MODEL'
                    fluid
                    selection
                    // value={this.state.selectedCategories}
                    onChange={(e, { value }) => {
                      let rows = []
                      switch (value) {
                        case 'Product':
                          break
                        case 'ProductCategory':
                          rows = this.state.categories
                          break
                        case 'Biller':
                          rows = this.state.billers
                          break
                        default:
                      }
                      this.setState({
                        selectedModel: value,
                        rows,
                        column: null,
                        direction: null,
                        selectedRows: [],
                        unfiltered: []
                      })
                    }}
                    options={[
                      { key: 'Product', text: 'Product', value: 'Product' },
                      { key: 'ProductCategory', text: 'ProductCategory', value: 'ProductCategory' },
                      { key: 'Biller', text: 'Biller', value: 'Biller' },
                    ]}
                  />
                </Segment>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
                <Segment raised>
                  <Label as='a' color='blue' ribbon>Product Filter</Label>
                  <Button size='tiny' compact color='teal'
                    onClick={() => {
                      if (this.state.selectedModel === 'Product') {
                        const payload = {
                          billers: this.state.selectedBillers.length > 0 && this.state.selectedBillers,
                          categories: this.state.selectedCategories.length && this.state.selectedCategories,
                          includeAll: true,
                        }
                        axios.post(Config.ApiUrl + '/product/dropdown', payload)
                          .then(res => {
                            this.setState({ rows: res.data, unfiltered: [] })
                          })
                          .catch(error => {
                            console.log(error);
                          })
                      }
                    }}
                  >APPLY</Button>
                  <Grid columns={2}>
                    <Grid.Column className={classes.grid}>
                      <Dropdown
                        placeholder='CATEGORY'
                        fluid
                        multiple
                        search
                        selection
                        value={this.state.selectedCategories}
                        onChange={(e, { value }) => this.setState({ selectedCategories: value })}
                        options={this.state.categories.map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid.Column>
                    <Grid.Column className={classes.grid}>
                      <Dropdown
                        placeholder='BILLER'
                        fluid
                        multiple
                        search
                        selection
                        value={this.state.selectedBillers}
                        onChange={(e, { value }) => this.setState({ selectedBillers: value })}
                        options={this.state.billers.map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
                <Segment raised>
                  <Label as='a' color='orange' ribbon>Synchronize</Label>
                  <GridContainer justify='center'>
                    <GridItem xl={3} lg={3} md={3} sm={12} xs={12} className={classes.grid}>
                      <Label as='a' color='grey'>{this.state.selectedModel ? this.state.selectedModel : 'Selected Model'}</Label>
                      <Input
                        icon={{
                          name: 'filter',
                          circular: true,
                          link: true,
                        }}
                        onChange={(e) => {
                          const value = e.target.value
                          const unfiltered = this.state.unfiltered.length > 0 ? this.state.unfiltered : this.state.rows
                          if (this.state.unfiltered.length === 0)
                            this.setState({ unfiltered })
                          if (value)
                            this.setState({
                              column: null,
                              direction: null,
                              rows: unfiltered.filter(item => item.name.toString().toUpperCase().indexOf(value.toString().toUpperCase()) !== -1),
                            })
                          else
                            this.setState({ rows: unfiltered, unfiltered: [] })
                        }}
                        placeholder='Filter...'
                        style={{ marginTop: 10 }}
                      />
                      <Table sortable celled compact selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              sorted={this.state.column === 'id' ? this.state.direction : null}
                              onClick={() => this.sort('id')}
                            >id</Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={this.state.column === 'name' ? this.state.direction : null}
                              onClick={() => this.sort('name')}
                            >name</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.rows.map(({ id, name }) => (
                            <Table.Row key={id}
                              active={this.state.selectedRows.indexOf(id) !== -1 ? true : false}
                              onClick={(e) => {
                                // console.log({ selectedCell: this.state.selectedCell, data: this.state.data })
                                if (this.state.selectedCell && [0].indexOf(this.state.selectedCell.column) !== -1) {
                                  const { row, column } = this.state.selectedCell
                                  let data = this.state.data.map(row => row.map(cell => ({ ...cell })));
                                  let cellId = data[row][column].value.toString()
                                  let cellModel = data[row][column + 2].value.toString()
                                  switch (this.state.selectedModel) {
                                    case 'Product':
                                      cellId = id
                                      cellModel = 'P'
                                      break
                                    case 'Biller':
                                    case 'ProductCategory':
                                      if (!cellId) {
                                        cellId = id
                                        cellModel = this.state.selectedModel === 'Biller' ? 'B' : 'PC'
                                      }
                                      else {
                                        if (cellId.split(',').length < 2) {
                                          cellId = _.join(cellId.split(',').concat(id), ',')
                                          cellModel = _.join(cellModel.split(',').concat(this.state.selectedModel === 'Biller' ? 'B' : 'PC'), ',')
                                        }
                                      }

                                      break
                                    default:
                                  }
                                  data[row][column] = { value: cellId }
                                  data[row][column + 2] = { value: cellModel }
                                  this.setState({
                                    data,
                                    selectedRows: this.state.selectedRows.indexOf(id) !== -1 ? this.state.selectedRows.filter(item => item !== id) : [...this.state.selectedRows, id]
                                  })
                                }
                              }}>
                              <Table.Cell selectable collapsing><a href="/#" onClick={e => e.preventDefault()}>{id}</a></Table.Cell>
                              <Table.Cell selectable><a href="/#" onClick={e => e.preventDefault()}>{name}</a></Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </GridItem>
                    <GridItem xl={9} lg={9} md={9} sm={12} xs={12} className={classes.grid}>
                      <Grid columns={1}>
                        <Grid.Column width={12}>
                          <Grid columns={4}>
                            <Grid.Column>
                              <Dropdown
                                placeholder='PARTNER'
                                fluid
                                selection
                                value={this.state.selectedPartner}
                                onChange={(e, { value }) => this.setState({ selectedPartner: value })}
                                options={this.state.partners.map((item, index) => ({
                                  key: item.id,
                                  text: item.name,
                                  value: item.id,
                                }))}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Button.Group icon>
                                <Button onClick={() => {
                                  secureStorage.setItem('syncData', this.state.data)
                                }}>
                                  <Icon name='save' />
                                </Button>
                                <Button onClick={() => {
                                  if (secureStorage.getItem('syncData')) {
                                    this.setState({ data: secureStorage.getItem('syncData') })
                                  }
                                }}>
                                  <Icon name='file' />
                                </Button>
                                <Button onClick={() => {
                                  secureStorage.removeItem('syncData')
                                }}>
                                  <Icon name='delete' />
                                </Button>
                              </Button.Group>
                            </Grid.Column>
                            <Grid.Column>
                              <Button.Group size='mini' compact>
                                <Button icon='plus' positive onClick={() => {
                                  this.setState({ data: [...this.state.data, [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' },]] })
                                }} />
                                <Button icon='minus' negative onClick={() => {
                                  if (this.state.data.length > 1)
                                    this.setState({ data: _.dropRight(this.state.data) })
                                }} />
                              </Button.Group>
                            </Grid.Column>
                            <Grid.Column>
                              <Button color='blue' onClick={() => {
                                const payload = {
                                  partnerId: this.state.selectedPartner,
                                  data: this.state.data,
                                  inquiryRequestData: this.state.inquiryRequestData,
                                  purchaseRequestData: this.state.purchaseRequestData,
                                }
                                axios.post(Config.ApiUrl + '/product/bulkcreate', payload)
                                  .then(res => {

                                  }).catch(err => {

                                  })
                              }}>
                                Submit
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column>
                          <Spreadsheet
                            data={this.state.data}
                            columnLabels={['id', 'name/code', 'model', 'denom', 'Price', 'Remark', 'code']}
                            onSelect={(selected) => {
                              if (selected.length > 0) {
                                this.setState({ selectedCell: selected[0] })
                              }
                            }}
                            onChange={(data) => {
                              this.setState({ data })
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </Segment>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
                <Segment raised>
                  <Label as='a' color='purple' ribbon>Inquiry Request Payload</Label>
                  <Button.Group size='mini' compact>
                    <Button icon='plus' positive onClick={() => {
                      this.setState({ inquiryRequestData: [...this.state.inquiryRequestData, [{ value: '' }, { value: '' }]] })
                    }} />
                    <Button icon='minus' negative onClick={() => {
                      if (this.state.inquiryRequestData.length > 1)
                        this.setState({ inquiryRequestData: _.dropRight(this.state.inquiryRequestData) })
                    }} />
                  </Button.Group>
                  <Button.Group icon size='mini' compact style={{ marginLeft: 5 }}>
                    <Button onClick={() => {
                      secureStorage.setItem('syncInquiryData', this.state.inquiryRequestData)
                    }}>
                      <Icon name='save' />
                    </Button>
                    <Button onClick={() => {
                      if (secureStorage.getItem('syncInquiryData')) {
                        this.setState({ inquiryRequestData: secureStorage.getItem('syncInquiryData') })
                      }
                    }}>
                      <Icon name='file' />
                    </Button>
                    <Button onClick={() => {
                      secureStorage.removeItem('syncInquiryData')
                    }}>
                      <Icon name='delete' />
                    </Button>
                  </Button.Group>
                  <Grid columns={1} style={{ marginBottom: 20 }}>
                    <Grid.Column className={classes.grid}>
                      <Spreadsheet
                        data={this.state.inquiryRequestData}
                        columnLabels={['Column Name', 'Value']}
                        onChange={(inquiryRequestData) => {
                          this.setState({ inquiryRequestData })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <Label as='a' color='purple' ribbon>Purchase Request Payload</Label>
                  <Button.Group size='mini' compact>
                    <Button icon='plus' positive onClick={() => {
                      this.setState({ purchaseRequestData: [...this.state.purchaseRequestData, [{ value: '' }, { value: '' }]] })
                    }} />
                    <Button icon='minus' negative onClick={() => {
                      if (this.state.purchaseRequestData.length > 1)
                        this.setState({ purchaseRequestData: _.dropRight(this.state.purchaseRequestData) })
                    }} />
                  </Button.Group>
                  <Button.Group icon size='mini' compact style={{ marginLeft: 5 }}>
                    <Button onClick={() => {
                      secureStorage.setItem('syncPurchaseData', this.state.purchaseRequestData)
                    }}>
                      <Icon name='save' />
                    </Button>
                    <Button onClick={() => {
                      if (secureStorage.getItem('syncPurchaseData')) {
                        this.setState({ purchaseRequestData: secureStorage.getItem('syncPurchaseData') })
                      }
                    }}>
                      <Icon name='file' />
                    </Button>
                    <Button onClick={() => {
                      secureStorage.removeItem('syncPurchaseData')
                    }}>
                      <Icon name='delete' />
                    </Button>
                  </Button.Group>
                  <Grid columns={1}>
                    <Grid.Column className={classes.grid}>
                      <Spreadsheet
                        data={this.state.purchaseRequestData}
                        columnLabels={['Column Name', 'Value']}
                        onChange={(purchaseRequestData) => {
                          this.setState({ purchaseRequestData })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}
ProductCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProductCreate);
