import React from "react";
import {BrowserRouter as Router,Route,Redirect,Switch } from "react-router-dom";
import "./App.css";
import Content from "content";
import Login from "content/Login"
import { createBrowserHistory } from "history";

import * as serviceWorker from 'serviceWorker';
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { secureStorage } from "libs";
import DashboardLink from "content/Dashboard/DashboardLink";
import Verification from "content/Login/Verification";
import ResetPassword from "content/Login/ResetPassword";


class App extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      history : createBrowserHistory(),
      newVersionAvailable: false,
      waitingWorker: {},
    }
  }

  componentDidMount(){
    // const lang = localStorage.getItem("i18nextLng")
    // console.log({lang})
    // if(lang.indexOf('-')!==-1){
    //   localStorage.setItem("i18nextLng",lang.split('-')[0])
    // }

    this.setState({history:createBrowserHistory()})
    
    // localStorage.removeItem('syncData')
    // localStorage.removeItem('loginType')
    // localStorage.removeItem('balance')
    // localStorage.removeItem('user')
    // localStorage.removeItem('dotmatrix')
    // localStorage.removeItem('device')
    // localStorage.removeItem('printer')
    // localStorage.removeItem('syncPurchaseData')
    // localStorage.removeItem('syncInquiryData')
    // localStorage.removeItem('token')
    // localStorage.removeItem('backPayload')
    // localStorage.removeItem('employee')
  }
  UNSAFE_componentWillMount(){
    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate })
    // serviceWorker.register()
  }
  onServiceWorkerUpdate = (waiting) => {
    console.log("update available")
    const { enqueueSnackbar } = this.props;
    // const { newVersionAvailable } = this.state;

    const waitingWorker = waiting
    this.setState({
      waitingWorker,
      newVersionAvailable: true,
    });
    //if (newVersionAvailable){ //show snackbar with refresh button
      enqueueSnackbar("A new version was released", {
        persist: true,
        variant: "success",
        action: this.refreshAction(),
      });
    //}
  }
  updateServiceWorker = () => {
    const { waitingWorker } = this.state
    console.log({waitingWorker})
    waitingWorker && waitingWorker.postMessage('forceUpdate')
    this.setState({ newVersionAvailable: false })
    // window.location.reload()
  }

  refreshAction = (key) => { //render the snackbar button
    return (
      <React.Fragment>
        <Button
          className="snackbar-button"
          size="small"
          onClick={this.updateServiceWorker}
        >
          {"update"}
        </Button>
      </React.Fragment>
    )
  }
  render(){
    return (
      <Router history={this.state.history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/link/:hash" component={DashboardLink} />
          <Route path="/verification" component={Verification} />
          <Route path="/reset/:confirmCode" component={ResetPassword} />
          <PrivateRoute path="/" component={Content} />
        </Switch>
      </Router>
    );
   }
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        secureStorage.getItem('token') ? 
          <Component {...props} />
          : 
          <Redirect to='/login' />
      }
    />
  );
}

export default withSnackbar(App);
