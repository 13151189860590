import React from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next'
import {compose} from 'redux'

// core components

import {Card,Box,CardHeader, IconButton} from "@material-ui/core"

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";


import {fnumber} from 'components/Helper'
import axios from 'axios'
import Config from 'config'


import moment from 'moment'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'

import BaseOptions from 'content/Dashboard/BaseOptions'

import {Dimmer,Segment,Loader} from 'semantic-ui-react'
import { Autorenew } from '@material-ui/icons';

const styles = {
	...style,
}

class Transaction extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			startDate:'',
      endDate:'',
			chartData:[],
			categories:[],
			isLoading : false,
		}
		this.source = axios.CancelToken.source()
	}
	componentDidMount(){
		
	}
	componentWillUnmount(){
		this.source.cancel('Operation cancelled')
	}
  loadTransaction = (params) =>{

		this.setState({isLoading:true})

		const payload = {
			startDate : params && params.startDate?params.startDate:this.state.startDate,
			endDate : params && params.endDate?params.endDate:this.state.endDate,
			type:'linear',
		}
		// console.log(payload)
		axios.post(Config.ApiUrl + '/transaction/dasboard',payload,{cancelToken:this.source.token})
		.then(res => {
				// console.log(res.data)
				const {t} = this.props
				this.setState({
					startDate : payload.startDate,
					endDate : payload.endDate,
					categories:res.data.categories.map(item=>t(item)),
					chartData:[{name:'Qty',data:res.data.sales}],
					isLoading:false
				})

		})
		.catch(err => {
			if (!axios.isCancel(err))
				this.setState({isLoading:false})
			console.log(err);
		});
	}
	getChartOptions(){
		
		return _.merge(BaseOptions(),{
			
			tooltip: {
				marker: { show: false },
				y: {
					formatter: (seriesName) => fnumber(seriesName),
					title: {
						formatter: (seriesName) => `${seriesName} : `
					}
				}
			},
			plotOptions: {
				bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
			},
			xaxis: {
				categories: this.state.categories
			}
		})
	}
	
	render(){
		const {t} = this.props;
		return (
			<Card>
				<CardHeader title={t('transaction')} 
					subheader={this.state.startDate && t(this.state.startDate.format('MMMM'))} 
					action={
						<IconButton aria-label="settings" onClick={()=>{
							const startDate = moment().startOf('month')
							const endDate = moment()
							this.loadTransaction({startDate,endDate})
						}}>
							<Autorenew />{t('show')}
						</IconButton>
					} 
				/>
				<Box sx={{ mx: 3 }} dir="ltr">
				<Segment vertical>
					<Dimmer active={this.state.isLoading} inverted>
						<Loader />
					</Dimmer>
					<ReactApexChart type="bar" series={this.state.chartData} options={this.getChartOptions()} height={364} />
				</Segment>
				</Box>
			</Card>
		)
	}
  
}

Transaction.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withTranslation(), withStyles(styles))(Transaction);